import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  annotations: []
};

const annotationsSlice = createSlice({
  name: "annotations",
  initialState,
  reducers: {
    fetchAnnotations: (state, action) => {
      state.annotations = action.payload.annotations;
    },
    addAnnotation: (state, action) => {
      state.annotations.push(action.payload.data);
    },
    updateAnnotation: (state, action) => {
      const comment = { ...action.payload.data };
      const annotationIndex = state.annotations.findIndex((data) => data.id === comment.id);
      state.annotations[annotationIndex]?.comments?.push?.(comment);
    },
    addLocation: (state, action) => {
      const annotationIndex = state.annotations.findIndex((data) => data.id === action.payload.data.id);
      state.annotations[annotationIndex] = {
        ...state.annotations[annotationIndex],
        ...action.payload.data
      }
    },
    setActiveAnnotation: (state, action) => {
      state.activeAnnotation = action.payload.id;
    }
  },
});

export const annotationsActions = annotationsSlice.actions;

export default annotationsSlice.reducer;
