import React, { useEffect } from "react";
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ImagePreview from './modules/ImagePreview/ImagePreview';
import Editor from './modules/Editor/Editor';
import Home from "./modules/Home/Home";
import { annotationsActions } from "./modules/AnnotationsSlice";
import { projectsActions } from "./modules/Projects/ProjectsSlice";
import { fillAnnotationUsers } from "./modules/AnnotationsActions";
import { getAllNotifications } from "./modules/Notifications/NotificationsActions";
import { notificationsActions } from "./modules/Notifications/NotificationsSlice";
import UserProfile from "./components/UserProfile/UserProfile";
import { fetchProfile } from "./modules/Users/UsersActions";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import { usersActions } from "./modules/Users/UsersSlice";

function SocketConnection(opt) {
    for (let pr in opt) {
        this[pr] = opt[pr];
    }
    if (!this.maxTries) {
        this.maxTries = 5;
    }
}

SocketConnection.prototype = {
    timeInterval: 8000,
    connect: function () {
        delete this.disconnect;
        let loc = window.location;
        let ws = new WebSocket(`${loc.protocol === "http:" ? "ws" : "wss"}://${loc.host}/sepstudio/chat`);
        this.ws = ws;
        ws.onmessage = this.onmessage;
        ws.onclose = () => {
            if (!this.disconnect && this.maxTries-- > 0) {
                setTimeout(this.connect.bind(this), this.timeInterval);
            }
        }
    },
    close: function () {
        this.disconnect = true;
        this.ws?.close();
    }
}

export default function Dashboard({ match: { url } }) {
    const dispatch = useDispatch();
    useEffect(() => {
        let ws = new SocketConnection({
            onmessage: function (e) {
                let data = JSON.parse(e.data);
                switch (data.type) {
                    case 'addComment':
                        fillAnnotationUsers([data.data]).then(d => {
                            dispatch(annotationsActions.updateAnnotation(data));
                        });
                        break;
                    case 'updateAnnotation':
                        dispatch(annotationsActions.addLocation(data));
                        break;
                    case 'createAnnotation':
                        let annotation = data.data;
                        fillAnnotationUsers([annotation, ...annotation.comments]).then(d => {
                            dispatch(annotationsActions.addAnnotation(data))
                        });
                        break;
                    case 'sendForReview':
                    case 'addProject':
                        dispatch(projectsActions.addProject(data));
                        break;
                    case 'deleteProject':
                        dispatch(projectsActions.deleteProject(data.data));
                        break;
                    case 'updateProject':
                        dispatch(projectsActions.updateProject(data));
                        break;
                    case 'profileUpdated':
                        dispatch(usersActions.fetchProfile({user: data.data}));
                        break;
                    default:
                        break;
                }
                if (data.notification)
                    dispatch(notificationsActions.addNotification({ notification: data.notification }))
            }
        });

        ws.connect();

        fetchProfile(dispatch);
        getAllNotifications(dispatch);
        return () => {
            ws.close();
        }
    }, [])
    return <Switch>
        <Route exact path={url} component={Home} />
        <Route exact path={`${url}/review/:projectId`} component={ImagePreview} />
        <Route exact path={`${url}/edit/:projectId`} component={Editor} />
        <Route exact path="/sepstudio-ui/profile" component={UserProfile} />
        <Route exact path="/sepstudio-ui/change-password" component={ChangePassword} />
    </Switch>;
}