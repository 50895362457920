import React from "react";
import classes from './TextButton.module.css';

function TextButton(props) {
    const textBtn = `btn btn-md ${classes.textBtn}`;
    return (
        <button type="button" onClick={props.onClick} disabled={props.disabled}
            style={props.style} className={textBtn}>
            {props.label}
        </button>
    );
}

export default TextButton;