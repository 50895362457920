import React from 'react'
import IconButton from '../../components/Buttons/IconButton';
import editOutlineImage from '../../assets/edit-outline.svg';
import reviewImage from '../../assets/review.svg';
import sendReview from '../../assets/sendReview.svg';
import classes from './ProjectItem.module.css';
import Consts from '../../Consts';
import Utils from '../../Utils';

function getStatus(status, isOwner) {
    switch (status) {
        case Consts.APPROVE:
            return "Approved";
        case Consts.SENT_FOR_REVEIW:
            return isOwner ? "Sent for review" : "In review";
        default:
            return "In progress";
    }
}

function getTimeSince(time) {
    return time ? Utils.getTimeSince(parseDate(time)) : "";
}
function parseDate(time) {
    return new Date(time?.["$date"] || time);
}

function ProjectItem(props) {
    const project = props.project;
    const userId = Utils.getUserId();
    const isOwner = userId === project.user;

    function projectClickHandler() {
        if (isOwner) {
            props.onEdit(project.id)
        } else {
            props.onReview(project.id);
        }
    }

    return (
        <tr className={classes.main} onDoubleClick={projectClickHandler}>
            <td>{props.index + 1}</td>
            <td className={classes.nameField} style={{ "textAlign": "left" }}>
                <div className={classes.projectNameContainer}>
                    <div className={classes.imageContainer} style={{ backgroundImage: `url(${project.smallThumbPath || project.designThumbPath})` }}>
                    </div>
                    <div className={classes.projectName} title={project.name}>
                        {project.name}
                    </div>
                </div>
            </td>
            <td>{getStatus(project.status, isOwner)}</td>
            <td className={classes.nameField} title={project.reviewers}>{project.reviewers?.join(', ')}</td>
            <td>{getTimeSince(project.updatedTime)}</td>
            <td>{getTimeSince(project.commentedTime)}</td>
            <td >
                <div className={classes.actions}>
                    {
                        isOwner ?
                            <IconButton
                                onClick={() => props.onEdit(project.id)}
                                iconImage={editOutlineImage}
                                tooltip="Open"
                                altText="Open Project" /> : ''
                    }
                    {
                        [Consts.APPROVE, Consts.SENT_FOR_REVEIW].includes(project?.status) &&
                        <IconButton
                            onClick={() => props.onReview(project.id)}
                            iconImage={reviewImage}
                            iconStyle={{ width: 16 }}
                            tooltip="Review"
                            altText="Review" />
                    }
                    {
                        isOwner ?
                            <IconButton
                                onClick={() => props.onSend(project.id, project.reviewers || "")}
                                iconImage={sendReview}
                                tooltip="Send for Review"
                                altText="Send for review" /> : ''
                    }
                </div>
            </td>
        </tr>
    )
}

export default ProjectItem;