
import React, { useState, useEffect } from "react";
import { useFabricCanvasHandler } from "../../../FabricCanvas";
import { BiEndSlider } from "../../../../../components/Sliders/Sliders";
import ColorSelector from "./ColorSelector";
import { useSelector } from 'react-redux';
import TextField from "@material-ui/core/TextField";
import ToolPanelButton from "../../../../../components/Buttons/ToolPanelButton";
import StrokeSlider from "./StrokeSlider";
import Select from 'react-select';
import action from "./utils";
import styles from '../TextView.module.css';
import Utils from "../../../../../Utils";
import { CategorySelect } from "../../CategoryDialog";
import SliderButton from "../../../../../components/Buttons/SliderButton";

export default function Basic({ separationColors, openSepDialog, backColor }) {
    const fontData = useSelector(s => s.fonts);
    const fonts = fontData.libraries.map(lib =>
        lib.fonts.map((f, idx) => ({
            label: f.familyName,
            value: idx,
            style: {
                fontFamily: f.familyName
            },
            font: f
        }))
    );
    const [text, setText] = useState({ value: '' });
    const [font, setFont] = useState(null);
    const [tracking, setTracking] = useState('empty');
    const [fill, setFill] = useState('empty');
    const [fillSepName, setFillSepName] = useState('empty');
    const [extraStrokes, setExtraStrokes] = useState('empty');
    const [roundLinejoin, setRoundLinejoin] = useState(false);
    const [fontWeight, setFontWeight] = useState("normal");
    const [fontStyle, setFontStyle] = useState("normal");
    const [underline, setUnderline] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);

    const [colors, setColors] = useState(separationColors);

    useEffect(() => {
        setColors([...separationColors, backColor]);
    }, [separationColors, backColor])

    const fontOpts = fonts[selectedValue] || [];

    function updatePanel() {
        let textObjects = action.getSelectedTextObjects();
        setText(action.getTextAreaState(textObjects));
        const fData = action.getFontDropdownState(textObjects, fonts, selectedValue);
        setSelectedValue(fData.selected || (fonts.length - 1));
        setFont(fData);
        setFontWeight(action.getTextAttribute('fontWeight', textObjects));
        setFontStyle(action.getTextAttribute('fontStyle', textObjects));
        setUnderline(action.getTextAttribute('underline', textObjects));
        setTracking(action.getTextAttribute('tracking', textObjects) || 0);
        setRoundLinejoin(action.getTextAttribute('strokeLineJoin', textObjects) === "round");
        updatedColors(textObjects);
    }

    function updatedColors(textObjects) {
        setFillSepName(action.getTextAttribute('sepName', textObjects) || '');
        setFill(action.getTextAttribute('fill', textObjects) || '#000');
        let extraStrokes = [];
        if (textObjects.length) {
            let lengths = textObjects.map(i => i.extraStrokes?.length || 0),
                isSame = true;
            for (let i = 1; i < lengths.length; ++i) {
                if (lengths[i - 1] !== lengths[i]) {
                    isSame = false;
                    break;
                }
            }
            if (isSame) {
                extraStrokes = JSON.parse(JSON.stringify(textObjects[0].extraStrokes || []));
                extraStrokes.forEach((st, i) => {
                    st.stroke = textObjects.reduce((prev, cur) => {
                        if (prev !== cur.extraStrokes[i].stroke) {
                            return 'mixed';
                        } else {
                            return prev;
                        }
                    }, st.stroke)
                });
            } else {
                extraStrokes = 'mixed';
            }
        } else {
            extraStrokes = "empty";
        }

        setExtraStrokes(extraStrokes);
    }

    const [canvas] = useFabricCanvasHandler({
        'selection:created': updatePanel,
        'selection:updated': updatePanel,
        'selection:cleared': updatePanel,
        'object:modified': () => updatedColors(action.getSelectedTextObjects()),
        'background:changed': function (color) {
            Utils.replaceColorInFabricObjects(canvas, backColor, backColor);
            updatePanel();
        },
        'canvas:x-loaded': function () {
            delete window.Ext.canvasController.isTextDirty;
        }
    }, [selectedValue]);

    useEffect(() => {
        canvas && updatePanel();
    }, [canvas]);

    const btnsState = action.getFontStyleButtonsState(fontWeight, fontStyle, underline, font?.value?.font);
    const isFillDisabled = fill === 'empty';
    return <>
        <div>
            <label className="editor-text" style={{ fontSize: "17px" }}>Text</label>
            <TextField fullWidth multiline maxRows={1} placeholder="Type your text here"
                variant="outlined" style={{ backgroundColor: "rgb(230, 231, 232)", marginBottom: 'var(--label-margin-bottom)' }}
                maxLength={256}
                value={text.value}
                onKeyDown={e => {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                    }
                }}
                onChange={e => {
                    const val = e.currentTarget.value;
                    const color = colors[0];
                    setText(t => ({ ...t, value: val }));
                    action.onTextChange(val, {
                        fontFamily: (font?.value || fontOpts[0]).label,
                        fontWeight: fontWeight === 'bold' ? 'bold' : 'normal',
                        fontStyle: fontStyle === 'italic' ? 'italic' : 'normal',
                        underline: underline === true,
                        textAlignment: 'center',
                        fill: color?.getColor(),
                        sepName: color.name
                    });
                }}
                className={text.disabled ? 'x-item-disabled' : ''}
            />
        </div>
        <CategorySelect
            onChange={(e, idx) => setSelectedValue(idx)}
            style={{ marginBottom: "0.6rem" }}
            value={selectedValue}
            categories={fontData.libraries.map((f, idx) => ({
                label: f.name,
                value: idx
            }))}
            label="Category"
            categoryKey="name"
        />
        <div>
            <label className="editor-text" style={{ fontSize: "17px" }}>Font</label>
            <div style={{ color: 'black' }}>
                <Select
                    closeMenuOnSelect={false}
                    options={fontOpts}
                    onChange={e => {
                        const selected = fontOpts.find(f => e.value === f.value);
                        action.onFontComboChange(selected?.font);
                        setFont(f => ({ ...f, value: selected }))
                    }}
                    value={font?.value}
                    isOptionDisabled={(option) => option.disabled}
                    placeholder={'Select Font...'}
                    clearable={true}
                    styles={{
                        control: styles => ({
                            ...styles,
                            borderRadius: "0px",
                            backgroundColor: "rgb(230, 231, 232)",
                            fontFamily: font?.value?.label,
                            marginBottom: 'var(--label-margin-bottom)',
                        }),
                        option: (base, state) => ({
                            ...base,
                            ...state.data.style
                        })
                    }}
                />
            </div>
        </div>
        <div>
            <label className="editor-text" style={{ fontSize: "17px" }}>Style</label>
            <div style={{ color: '#c0c0c0' }}>
                <ToolPanelButton state={btnsState.bold} className="fa-bold text-view-btn-style1" onClick={e => {
                    setFontWeight(fontWeight === 'bold' ? 'normal' : 'bold');
                    action.onFontStyleBoldButtonClick(fontWeight !== 'bold');
                }} />
                <ToolPanelButton state={btnsState.italic} style={{ marginLeft: 10 }} className="fa-italic text-view-btn-style1" onClick={e => {
                    setFontStyle(fontStyle === 'italic' ? 'normal' : 'italic');
                    action.onFontStyleItalicButtonClick(fontStyle !== 'italic')
                }} />
            </div>
        </div>
        <div>
            <label className="editor-text" style={{ fontSize: "17px", marginTop: '10px' }}>Letter Spacing</label>
            <div className={tracking === "empty" ? 'x-item-disabled' : ''} style={{ margin: '0 10px' }}>
                <BiEndSlider min={-100}
                    max={200}
                    value={Number(tracking) || 0}
                    style={{ marginBottom: 'var(--label-margin-bottom)' }}
                    onChange={(ev, val) => {
                        action.updateTracking(val);
                    }}
                    onChangeCommitted={(ev, val) => {
                        setTracking(val);
                        action.notifyUndoRedo();
                    }}
                />
            </div>
        </div>
        <div>
            <label className="editor-text" style={{ fontSize: "17px" }}>Fill</label>
            <span className={isFillDisabled ? 'x-item-disabled' : ''}>
                <ColorSelector color={fill === 'mixed' ? '' : fill} separationColors={colors} openSepDialog={openSepDialog} className={styles.fillColorSelector}
                    sepName={fillSepName === 'mixed' ? '' : fillSepName}
                    onColorChange={color => {
                        let textObjects = action.getSelectedTextObjects();
                        textObjects.forEach(textObject => {
                            textObject.fill = color?.getColor();
                            textObject.sepName = color?.name;
                            textObject.dirty = true;
                        });
                        setFill(color?.getColor());
                        setFillSepName(color?.name);
                        canvas.renderAll();
                        action.notifyUndoRedo(textObjects);
                    }}
                    showMenu={!isFillDisabled}
                />
            </span>
        </div>
        <div style={{ marginTop: 10 }}>
            <label className="editor-text" style={{ fontSize: "17px" }}>Strokes </label>
            <span className={Utils.getClasses("fa fa-plus clipart-category-btn", Array.isArray(extraStrokes) ? '' : 'x-item-disabled')} style={{ paddingLeft: '15px' }}
                onClick={e => {
                    let color = colors?.[extraStrokes.length % colors.length];
                    let st = {
                        stroke: color?.getColor() || '#000',
                        strokeWidth: 0.1,
                        sepName: color?.name
                    };
                    let ess = [...extraStrokes, st];
                    setExtraStrokes(ess);
                    let targets = action.getSelectedTextObjects();
                    targets.forEach(textObject => {
                        if (!textObject.extraStrokes) {
                            textObject.extraStrokes = [];
                        }
                        textObject.extraStrokes.push({ ...st });
                        textObject.dirty = true;
                        delete textObject.usedColors;
                    });
                    action.notifyUndoRedo(targets);
                    canvas.renderAll();
                }}
            />
            {
                Array.isArray(extraStrokes) && Boolean(extraStrokes.length) && <>
                    <div style={{ marginTop: 5 }}>
                        <label className="editor-text">Round line join</label>
                        <div className={isFillDisabled ? 'x-item-disabled' : ''}>
                            <SliderButton checkedLabel="On" uncheckedLabel="Off"
                                onChange={val => {
                                    setRoundLinejoin(val);
                                    let strokeLineJoin = val ? "round" : "miter",
                                        textObjects = action.getSelectedTextObjects();
                                    textObjects.forEach(obj => {
                                        obj.strokeLineJoin = strokeLineJoin;
                                        obj.dirty = true;
                                    });
                                    canvas.renderAll();
                                    action.notifyUndoRedo(textObjects);
                                }}
                                checked={roundLinejoin}
                            />
                        </div>
                    </div>
                    {extraStrokes.map((stroke, index) =>
                        <StrokeSlider className={styles.strokeSlider} stroke={stroke} key={index} separationColors={colors} openSepDialog={openSepDialog}
                            onWidthChange={(ev, val) => {
                                const textObjects = action.getSelectedTextObjects();
                                extraStrokes[index].strokeWidth = val * 0.01;
                                textObjects.forEach(textObject => {
                                    textObject.extraStrokes[index].strokeWidth = val * 0.01;
                                    textObject.dirty = true;
                                });
                                canvas.renderAll();
                            }}
                            notifyUndoRedo={action.notifyUndoRedo}
                            onDelete={e => {
                                const textObjects = action.getSelectedTextObjects();
                                const ess = extraStrokes.slice();
                                ess.splice(index, 1);
                                setExtraStrokes(ess);
                                textObjects.forEach(textObject => {
                                    textObject.extraStrokes.splice(index, 1);
                                    textObject.dirty = true;
                                });
                                canvas.renderAll();
                                action.notifyUndoRedo(textObjects);
                            }}
                            onColorChange={color => {
                                const textObjects = action.getSelectedTextObjects();
                                const ess = extraStrokes.slice();
                                ess[index].stroke = color?.getColor();
                                ess[index].sepName = color?.name;
                                setExtraStrokes(ess);
                                textObjects.forEach(textObject => {
                                    textObject.extraStrokes[index].stroke = ess[index].stroke;
                                    textObject.extraStrokes[index].sepName = ess[index].sepName;
                                    textObject.dirty = true;
                                });
                                canvas.renderAll();
                                action.notifyUndoRedo(textObjects);
                            }}
                        />
                    )}
                </>
            }
        </div>
    </>
}