import React, { useState } from "react";
import { useHistory } from "react-router";
import PrimaryButton from "../Buttons/PrimaryButton";
import Password from "../Password/Password";
import Utils from "../../Utils";
import Card from "../Card/Card";
import { signInUser } from "../../modules/Users/UsersActions";
import classes from "./SignIn.module.css";
import MessageDialog from "../MessageDialog/MessageDialog";
import Consts from "../../Consts";
import LinkButton from "../Buttons/LinkButton";
import NeedHelp from "../NeedHelp/NeedHelp";

function SignIn(props) {
	const history = useHistory();
	const [userDetails, setUserDetails] = useState(props.userDetails || {});
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [isPasswordValid, setIsPasswordValid] = useState(true);
	const [showMessageDialog, setShowMessageDialog] = useState(false);
	const [dialogContent, setDialogContent] = useState({ title: "", message: "" });

	function signInHandler() {
		if (Utils.validateEmail(userDetails.userId).valid) {
			let data = { ...userDetails };
			if (userDetails.password) {
				signInUser(data).then((response) => {
					if (response && response.data && response.data.success) {
						localStorage.setItem("isLoggedIn", "true");
						history.push(history.location?.state?.from || "/sepstudio-ui");
					} else if (response.data?.errorCode) {
						setDialogContent(() => {
							return {
								title: "Failed",
								message: "Invalid email or password.",
							};
						});
						setShowMessageDialog(true);
					}
				});
				setIsPasswordValid(true);
			} else {
				setIsPasswordValid(false);
			}
			setIsEmailValid(true);
		} else {
			setIsEmailValid(false);
		}
	}

	function createHandler() {
		history.push({
			pathname: "/sepstudio-ui/sign-up",
			state: { ...history.location?.state },
		});
	}

	function forgetPasswordHandler() {
		history.push({
			pathname: "/sepstudio-ui/forgot-password",
			state: { ...history.location?.state },
		});
	}

	return (
		<div className={classes.mainBox}>
			{showMessageDialog && (
				<MessageDialog
					{...dialogContent}
					onCancel={() => setShowMessageDialog(false)}
					isCancel
					closeText="Close"
					width={Consts.MESSAAGE_DIALOG_WIDTH}
				/>
			)}
			<Card>
				<div className={classes.main}>
					<div className={classes.heading}>
						<h2>Sign in</h2>
					</div>
					<div className={classes.container}>
						<label htmlFor="userId">Email</label>
						<input
							type="email"
							id="userId"
							autoFocus={true}
							onChange={(e) =>
								setUserDetails((prevState) => {
									return {
										...prevState,
										userId: e.target.value,
									};
								})
							}
							value={userDetails.userId || ""}
							autoComplete="off"
						/>
						{!isEmailValid && (
							<p style={{ color: "red", marginTop: "1rem", marginBottom: "0" }}>
								Email is not valid{" "}
							</p>
						)}
						<label htmlFor="password">Password</label>
						<Password
							id="password"
							togglePasswordVisibility={true}
							width="17rem"
							height="2rem"
							onChange={(e) =>
								setUserDetails((prevState) => {
									return {
										...prevState,
										password: e.target.value,
									};
								})
							}
							autoComplete="off"
							onCommit={signInHandler}
						/>
						{!isPasswordValid && (
							<p style={{ color: "red", marginTop: "1rem", marginBottom: "0" }}>
								Please enter the password{" "}
							</p>
						)}
						<div className={classes.forgetPassword}>
							<LinkButton
								onClick={forgetPasswordHandler}
								label="Forgot Password?"
							/>
						</div>
						<div className={classes.actions}>
							<PrimaryButton onClick={signInHandler} style={{ width: "40%" }}>
								Sign in
							</PrimaryButton>
						</div>
						<div className={classes.signup}>
							<span>Don't have an account? </span>
							<LinkButton onClick={createHandler} label="Signup" />
						</div>
						<div className={classes.needHelp}>
							<NeedHelp />
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
}

export default SignIn;
