import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import ModalDialog from "../Dialog/Dialog";
import classes from './MessageDialog.module.css'

function MessageDialog({ title, message, isCancel, onCancel, onAccept, closeText = 'Cancel' }) {
    return <ModalDialog
        title={title}
        content={
            <div className={classes.message}>
                <p>{message}</p>
            </div>
        }
        footer={
            <>
                {
                    isCancel &&
                    <PrimaryButton onClick={onCancel} negative>{closeText}</PrimaryButton>
                }
                {
                    onAccept &&
                    <PrimaryButton onClick={onAccept} positive>OK</PrimaryButton>
                }
            </>
        }
    />;
}

export default MessageDialog;