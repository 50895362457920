import React from 'react';
import classes from './FloatMenu.module.css';
import Utils from '../../Utils';
import ReactDOM from 'react-dom';

const container = document.querySelector('#root');
function FloatMenu({position, className, children, onClick, style, menuRef}) {
    return ReactDOM.createPortal(
    <div className={Utils.getClasses(classes["float-menu"], className)} 
        style={{ backgroundColor: "rgb(14, 19, 24)", ...position, ...style }} 
        ref={menuRef}
        onClick={e => {
            onClick?.(e);
            e.stopPropagation();
        }}>
        {children}
    </div>, container);
}

export default FloatMenu;