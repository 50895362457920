import React, { useEffect, useState } from "react";
import PanelButton from "../../../components/Buttons/PanelButton";
import { useFabricCanvasHandler } from "../FabricCanvas.js";
import classes from "./GroupPanel.module.css";

let Designer,
    Ext,
    fabric,
    undoRedoActions;
const groupAction = {
    events: {
        'selection:created': function () {
            groupAction.updatePanel();
        },
        'selection:updated': function () {
            groupAction.updatePanel();
        },
        'selection:cleared': function () {
            groupAction.updatePanel();
        }
    },

    updatePanel: function () {
        let canGroup = false,
            canUngroup = false;

        let canvas = Designer.AppData.getCanvas();
        let activeObject = canvas.getActiveObject();

        if (activeObject && canvas.getActiveObjects().every(o => o.id !== 'proofPositive')) {
            canGroup = (!activeObject.layer &&
                !activeObject.isLocked && activeObject.get('type') === 'activeSelection') ? true : false;

            canUngroup = (!activeObject.layer &&
                !activeObject.isLocked && activeObject.get('type') === 'group') ? true : false;
        }
        // Enable/disable the buttons.
        this.updateState({
            group: {
                disabled: !canGroup
            },
            ungroup: {
                disabled: !canUngroup
            }
        });
    },


    onGroupButtonClick: function (group) {
        let canvas = Designer.AppData.getCanvas();
        let activeObject = canvas.getActiveObject();

        if (group) {
            // If we have a selection group, remove its objects from canvas, and add
            // those to a new group. Set the new group as the active object.
            if (activeObject && !activeObject.isLocked && activeObject.get('type') === 'activeSelection') {
                undoRedoActions.mute(); // Mute undo/redo handling. We notify once at end.

                let objectsInGroup = activeObject.getObjects(),
                    parent = objectsInGroup[0].getParent();
                canvas.discardActiveObject();

                let parentObjs = parent.getObjects();
                objectsInGroup = objectsInGroup.map(o => ({
                    obj: o,
                    idx: parentObjs.indexOf(o)
                }));
                objectsInGroup.sort((a, b) => a.idx < b.idx ? -1 : 1);

                let firstIndex = objectsInGroup[0].idx;
                objectsInGroup = objectsInGroup.map(o => {
                    parent.remove(o.obj);
                    return o.obj;
                });

                let newGroup = new fabric.Group(objectsInGroup);
                if (objectsInGroup[0].clipTo !== null) {
                    objectsInGroup.forEach(function (o) {
                        o.clipTo = null;
                    });
                }
                parent.insertAt(newGroup, firstIndex);

                // Set the group object as active.
                canvas.setActiveObject(newGroup);

                undoRedoActions.unmute();
                canvas.fire('object:added', { target: newGroup });

                canvas.renderAll();
            }
        } else {
            // If the selected object is a group, add its objects back to canvas.
            // Create a new group from these objects and set as active.

            if (activeObject && !activeObject.isLocked && activeObject.get('type') === 'group') {
                undoRedoActions.mute(); // Mute undo/redo handling. We notify once at end.

                let objectsInGroup = activeObject.getObjects(),
                    parent = activeObject.getParent(),
                    groupIndex = parent._objects.indexOf(activeObject);
                activeObject._restoreObjectsState();
                parent.remove(activeObject);

                canvas.discardActiveObject();

                for (let i = objectsInGroup.length - 1; i >= 0; --i) {
                    parent.insertAt(objectsInGroup[i], groupIndex);
                }

                let selectionGroup = new fabric.ActiveSelection(objectsInGroup, {
                    canvas: canvas
                });
                canvas.setActiveObject(selectionGroup);

                undoRedoActions.unmute();
                canvas.fire('object:removed');

                canvas.renderAll();
            }
        }
    },

    init: function (stateInterface) {
        this.updateState = stateInterface.updateState;
    }
};

export default function GroupPanel() {
    const [canvas] = useFabricCanvasHandler(groupAction.events);
    const [groupState, setGroupState] = useState({});
    const [ungroupState, setUngroupState] = useState({});

    const classNames = ungroupState.disabled ? "fa fa-object-group" : "fa fa-object-ungroup";
    const title = ungroupState.disabled ? "Group" : "Ungroup";

    useEffect(() => {
        Designer = window.global.Designer;
        Ext = window.global.Ext;
        undoRedoActions = Ext.undoRedoContoller;
        fabric = window.fabric;
        groupAction.init({
            updateState: function (obj) {
                if (obj.group)
                    setGroupState(obj.group);
                if (obj.ungroup)
                    setUngroupState(obj.ungroup);
            }
        });
    }, []);

    useEffect(() => {
        canvas && groupAction.updatePanel();
    }, [canvas])

    return (<div className={classes.panelButtonWrapper}>
        <PanelButton state={{
            disabled: groupState.disabled && ungroupState.disabled
        }} onClick={() => {
            groupAction.onGroupButtonClick(!groupState.disabled);
        }} className={classNames} title={title} />
    </div>);
}