import React from 'react';
import classes from './ChatHeader.module.css';
import BackButton from '../../components/Buttons/BackButton';
import NumberIcon from '../../components/NumberIcon/NumberIcon';
import ToggleIcon from '../../components/ToggleIcon/ToggleIcon';
import Utils from '../../Utils';

function ChatHeader({ onBack, onThreadClose, annotation, isReadOnly, enabled }) {
    const pinNumber = annotation.pinNumber;
    return <div className={classes.main}>
        <div className={classes.leftContainer}>
            <BackButton onBack={onBack} />
            {Number.isFinite(pinNumber) &&
                <NumberIcon number={pinNumber + 1} fill={annotation.closed ? "green" : undefined} />
            }
        </div>
        <div className={classes.middleContainer}>{Utils.getFullNameByUser(annotation?.user)}</div>
        {
            !isReadOnly && !annotation.system &&
            <div className={classes.rightContainer}>
                <ToggleIcon image="fa fa-flag" onClick={onThreadClose} enabled={enabled} style={{ fontSize: '1.1rem' }} tooltip={{ normal: 'Mark as complete', down: 'Reopen' }} colorNormal={'#ef6c00'} />
            </div>
        }
    </div>
}

export default ChatHeader;