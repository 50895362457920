import React, { useState, useEffect } from "react";
import "../components.css";
import ToolPanelButton from "../../../components/Buttons/ToolPanelButton";

let Designer,
    Ext;
const action = {
    updatePanel: function () {
        var canvasController = Ext.canvasController;
        var currentZoom = 1;
        canvasController.setZoom(1);

        this.setData(
            currentZoom === Designer.app.constants.MAX_ZOOM_LEVEL, // No zoom-in
            currentZoom === Designer.app.constants.MIN_ZOOM_LEVEL, // No zoom-out
            currentZoom <= 1 // No navigator
        );
    },

    getNextZoomLevel: function (zoomLevel) {
        var zoomLevels = Designer.app.constants.ZOOM_LEVELS;
        var indexOfZoom = zoomLevels.indexOf(zoomLevel);

        if (indexOfZoom < zoomLevels.length - 1) {
            zoomLevel = zoomLevels[indexOfZoom + 1];
        }
        return zoomLevel;
    },

    getPrevZoomLevel: function (zoomLevel) {
        var zoomLevels = Designer.app.constants.ZOOM_LEVELS;
        var indexOfZoom = zoomLevels.indexOf(zoomLevel);

        if (indexOfZoom > 0) {
            zoomLevel = zoomLevels[indexOfZoom - 1];
        }
        return zoomLevel;
    },

    setData: function (zoomInDisabled, zoomOutDisabled, navigatorDisabled) {
        var canvasController = Ext.canvasController;
        this.uiContoller.updateState({
            zoomIn: { disabled: zoomInDisabled },
            zoomOut: { disabled: zoomOutDisabled },
            nav: { disabled: navigatorDisabled, pressed: canvasController.isPreviewCanvasShown() }
        });
    },

    onZoomInButtonClick: function (button, e, eOpts) {
        var canvasController = Ext.canvasController;
        var currentZoom = canvasController.getZoom();

        var maxZoomLevel = Designer.app.constants.MAX_ZOOM_LEVEL;
        var newZoomLevel = this.getNextZoomLevel(currentZoom);

        if (newZoomLevel !== currentZoom) {
            // Set the new canvas zoom level.
            canvasController.setZoom(newZoomLevel);

            this.setData(newZoomLevel === maxZoomLevel, false, newZoomLevel <= 1);
        }
    },

    onZoomOutButtonClick: function (button, e, eOpts) {
        var canvasController = Ext.canvasController;
        var currentZoom = canvasController.getZoom();

        var minZoomLevel = Designer.app.constants.MIN_ZOOM_LEVEL;
        var newZoomLevel = this.getPrevZoomLevel(currentZoom);

        if (newZoomLevel !== currentZoom) {
            // Set the new canvas zoom level.
            canvasController.setZoom(newZoomLevel);

            this.setData(false, newZoomLevel === minZoomLevel, newZoomLevel <= 1);
        }
    },

    onShowHideNavigatorChange: function (state) {
        var canvasController = Ext.canvasController,
            newValue = !Boolean(state.pressed);
        canvasController.showHidePreviewCanvas(newValue);

        if (newValue) {
            canvasController.refreshPreviewPanel();
        }
        this.uiContoller.updateState({
            nav: { pressed: newValue }
        });
    },

    onPanelShow: function (component, eOpts) {
        this.updatePanel();
    },

    init: function (controller) {
        this.uiContoller = controller;
    }
};

export default function ZoomWidget({ visible }) {
    const [zoomIn, setZoomIn] = useState({});
    const [zoomOut, setZoomOut] = useState({});
    const [nav, setNav] = useState({});
    useEffect(() => {
        Designer = window.global.Designer;
        Ext = window.global.Ext;
        action.init({
            updateState: function (obj) {
                obj.zoomIn && setZoomIn(obj.zoomIn);
                obj.zoomOut && setZoomOut(obj.zoomOut);
                obj.nav && setNav(obj.nav);
            }
        });
        action.onPanelShow();
    }, []);

    return <div style={{ padding: "10px", display: visible ? 'block' : 'none' }}>
        <ToolPanelButton style={{ height: '40px' }} state={{ disabled: zoomIn.disabled }} className="zoom-view-btn-style" onClick={() => action.onZoomInButtonClick()}>
            <span className="fa fa-search-plus" style={{ fontSize: "20px", marginRight: "20px" }}></span>
            <span className="editor-text">Zoom In</span>
        </ToolPanelButton>
        <ToolPanelButton style={{ height: '40px' }} state={{ disabled: zoomOut.disabled }} className="zoom-view-btn-style" onClick={() => action.onZoomOutButtonClick()}>
            <span className="fa fa-search-minus" style={{ fontSize: "20px", marginRight: "20px" }}></span>
            <span className="editor-text">Zoom Out</span>
        </ToolPanelButton>
        <ToolPanelButton style={{ height: '40px' }} state={nav} className="zoom-view-btn-style" onClick={() => action.onShowHideNavigatorChange(nav)}>
            <span className={nav.pressed ? "fa fa-eye" : "fa fa-eye-slash"} style={{ fontSize: "20px", width: "20px", marginRight: "20px" }}></span>
            <span className="editor-text" >Navigation Panel</span>
        </ToolPanelButton>
    </div>;
};