import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notifications: []
};

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        fetchNotifications: (state, action) => {
            state.notifications = action.payload.notifications;
        },
        addNotification: (state, action) => {
            const notificationIndex = state.notifications.findIndex((notification) => notification.id === action.payload.notification.id)
            if (notificationIndex > -1) {
                state.notifications[notificationIndex] = action.payload.notification
            } else {
                state.notifications = [action.payload.notification, ...state.notifications];
            }
        },
        removeNotification: (state, action) => {
            const notificationIndex = state.notifications.findIndex((data) => data.id === action.payload.id);
            state.notifications.splice(notificationIndex, 1);
        },
        removeAllNotifications: (state, action) => {
            state.notifications = []
        },
    },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice.reducer;
