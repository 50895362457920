import { createSlice } from "@reduxjs/toolkit";
import Utils from "../../Utils";

const usersSlice = createSlice({
    name: "user",
    initialState: {},
    reducers: {
        fetchProfile: (state, action) => {
            Object.assign(state, action.payload);
            Utils.setCurrentUser(action.payload.user);
        }
    },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
