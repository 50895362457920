import ColorSelector from "./ColorSelector";
import { Slider } from "../../../../../components/Sliders/Sliders";
import styles from '../TextView.module.css';
import Utils from "../../../../../Utils";

export default function StrokeSlider({ stroke, separationColors, onWidthChange, onDelete, onColorChange, notifyUndoRedo, openSepDialog, style, className }) {
    return <div className={Utils.getClasses(styles.strokeWidthController, className)} style={style}>
        <ColorSelector color={stroke.stroke === 'mixed' ? '' : stroke.stroke} separationColors={separationColors}
            sepName={stroke.sepName === 'mixed' ? '' : stroke.sepName}
            onColorChange={color => {
                onColorChange?.(color);
            }}
            className={styles.strokeColorSelector} openSepDialog={openSepDialog}
        />
        <Slider min={1} max={100}
            className={styles.strokeSlider}
            value={Math.round(stroke.strokeWidth * 100)}
            onChange={onWidthChange}
            onChangeCommitted={e => notifyUndoRedo?.()}
        />
        {
            onDelete && <span className={Utils.getClasses('fa fa-trash', styles.strokeDelete)}
                onClick={e => {
                    onDelete?.(e);
                }}
            />
        }
    </div>
}
