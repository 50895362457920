import React, { useEffect, useState } from "react";
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import { ColorWrapper } from './ManageColors';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import plusIcon from '../../../assets/plus-icon.svg';
import minusIcon from '../../../assets/minus-icon.svg';
import trashIcon from '../../../assets/trash.svg';
import { useFabricCanvasHandler } from "../FabricCanvas.js";
import "../components.css";
import Utils from "../../../Utils.js";
import ColorGrid from "./ColorGrid";

let Designer,
	Ext,
	fabric;

const action = {
	getSelectedColorableObjects: function () {
		const canvas = Designer.AppData.getCanvas();
		const selectedObjects = [];

		canvas.getActiveObjects().forEach(o => {
			if (this.isColorableObject(o)) {
				selectedObjects.push(o);
			}
		});

		return selectedObjects;
	},

	isColorableObject: function (object) {
		return object.get('id') !== 'background' &&
			object.get('type') !== 'image' && object.get('type') !== 'group';
	},

	getColorWapper: function (obj, prop, idx) {
		let color = obj[prop];
		if (prop === 'extraStrokes') {
			color = color[idx].stroke;
		}
		return new ColorWrapper({
			type: prop,
			color: color,
			colorType: color.type,
			target: obj,
			index: idx
		});
	},
	getObjectColors: function (obj) {
		if (obj.usedColors)
			return obj.usedColors;
		let me = this,
			colors = [];
		if (obj.fill && obj.type !== 'group')
			colors.push(this.getColorWapper(obj, 'fill'));
		if (obj.type !== 'shaped-text' || !(obj.fill instanceof fabric.Pattern)) {
			if (obj.stroke && obj.type !== 'group')
				colors.push(this.getColorWapper(obj, 'stroke'));
		}
		if (obj.type === 'shaped-text' && obj.extraStrokes) {
			obj.extraStrokes.forEach((dec, i) => {
				colors.push(this.getColorWapper(obj, 'extraStrokes', i));
			});
		}
		if (obj._objects) {
			obj._objects.forEach(function (o) {
				colors = colors.concat(me.getObjectColors(o));
			});
		}
		if (obj.type !== 'activeSelection') {
			obj.usedColors = colors;
		}
		return colors;
	},

	clearObjectUsedColors: function (obj) {
		delete obj.usedColors;
	},

	getAllActiveObjectColors: function () {
		let me = this,
			colors = [],
			objects = this.getSelectedColorableObjects();
		objects.forEach(function (o) {
			colors = [...colors, ...me.getObjectColors(o)];
		});
		return {
			colors: colors,
			objects: objects
		};
	}
};

export function ColorSeparations({ colors, setColors, openSepDialog, backColor }) {
	const allColors = [...colors, backColor];
	const project = useSelector(state => state.projects.project);
	const [replaceColor, setReplaceColor] = useState(({
		index: -1,
		show: false,
		color: {},
	}))
	const [canvas] = useFabricCanvasHandler({
		'background:changed': function (color) {
			setReplaceColor(c => ({ ...c }));
		},
		'canvas:x-ready': function (e) {
			const file = project?.files?.[project.files.length - 1];
			const map = {
				[backColor.name]: backColor
			};
			const crs = file?.separations?.map(i => map[i.name] = new ColorWrapper({ locked: true, ...i }));
			if (crs) {
				canvas?.enumerateAllObjects(o => {
					if (o.sepName && !map[o.sepName]) {
						crs.push(map[o.sepName] = new ColorWrapper({ color: o.fill, name: o.sepName, locked: false }));
					}
					o.extraStrokes?.forEach(s => {
						if (s.sepName && !map[s.sepName]) {
							crs.push(map[s.sepName] = new ColorWrapper({ color: s.stroke, name: s.sepName, locked: false }));
						}
					})
					return true;
				}, false, true);
				setColors(crs);
				canvas.fire('palette:created', { target: crs });
			} else if (canvas) {
				setReplaceColor(c => ({ ...c }));
			}
		},
		'canvas:x-loaded': function () {
			setReplaceColor(c => ({ ...c }));
		}
	});

	useEffect(() => {
		Designer = window.global.Designer;
		Ext = window.global.Ext;
		fabric = window.fabric;
		Ext.colorPanelController = action;
	}, [])

	function allowDrop(ev) {
		ev.preventDefault();
	}

	function drag(ev, index) {
		ev.dataTransfer.setData("index", index);
	}

	function isColorUsed(color) {
		let isColorUsed = false;
		canvas.enumerateAllObjects(obj => {
			if (obj.sepName === color.name) {
				isColorUsed = true;
				return false;
			}
			let extraStrokes = obj.extraStrokes;
			if (extraStrokes) {
				for (let s of extraStrokes) {
					if (s.sepName === color.name) {
						isColorUsed = true;
						return false;
					}
				}
			}
			return true;
		}, false, true);
		return isColorUsed;
	}

	function drop(ev) {
		ev.preventDefault();
		const idx = ev.dataTransfer.getData("index");
		if (!idx) {
			return;
		}
		const index = Number(idx);
		const color = allColors[index];
		if (!isColorUsed(color)) {
			let target = colors.slice();
			target.splice(index, 1);
			setColors(target);
			canvas.fire('palette:changed', { target });
		} else {
			setReplaceColor((prevState) => {
				return {
					...prevState,
					index: index,
					show: true,
				};
			});
		}
	}

	function removeColorHandler() {
		const usedColors = new Set();
		canvas.enumerateAllObjects(obj => {
			if (obj.sepName) {
				usedColors.add(obj.sepName);
			}
			obj.extraStrokes?.forEach(s => {
				if (s.sepName) {
					usedColors.add(s.sepName);
				}
			})
			return true
		}, false, true);
		const colorsArr = []
		colors.forEach(color => {
			if (color.locked || usedColors.has(color.name)) {
				colorsArr.push(color);
			}
		});
		setColors(colorsArr);
		if (colors.length !== colorsArr.length) {
			canvas.fire('palette:changed', { target: colorsArr });
		}
	}

	return <>
		<div>
			<ColorGrid
				disableTileSelection
				colorlist={allColors}
				onClick={color => {
					let targetColors = action.getAllActiveObjectColors()?.colors;
					if (!targetColors)
						return;
					targetColors.forEach(targetColor => {
						if (targetColor.type === 'fill') {
							targetColor.setColor(color);
							let target = targetColor.target;
							target.sepName = color.name;
						}
					})
					canvas.renderAll();
					if (targetColors.length) {
						canvas.fire('object:modified', { target: targetColors.map(i => i.target) });
					}
				}}
				onItemDragStart={(e, c, index) => drag(e, index)}
				style={{ padding: 0, overflowX: 'auto', whiteSpace: 'nowrap', display: 'inline-block', maxWidth: '50%' }}
			/>
			<span className="color-item-thumb-wrap" onClick={e => openSepDialog(true)} style={{ marginLeft: "2rem", verticalAlign: 'top' }}>
				<span title="Add Ink to Palette" style={{
					backgroundImage: `url(${plusIcon})`,
					backgroundSize: '50%',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					borderColor: 'var(--text-light-color)'
				}}></span>
			</span>
			<span className="color-item-thumb-wrap" onDragOver={allowDrop} onDrop={drop}
				style={{ verticalAlign: 'top' }}
			>
				<span title="Delete Ink" style={{
					backgroundImage: `url(${trashIcon})`,
					backgroundSize: '80%',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					borderColor: 'var(--text-light-color)'
				}}></span>
			</span>
			<span className="color-item-thumb-wrap"
				onClick={e => {
					window.Ext.Msg.confirm("Remove Unused Inks", `Remove all unused inks from the project ink palette?\n\nRemaining inks will generate separations determining project screen load on press.`, function (answer) {
						if (answer === "ok")
							removeColorHandler();
					});
				}}
				style={{ verticalAlign: 'top' }}
			>
				<span title="Remove unused Inks" style={{
					backgroundImage: `url(${minusIcon})`,
					backgroundSize: '50%',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					borderColor: 'var(--text-light-color)'
				}}></span>
			</span>
		</div>
		<Dialog
			open={replaceColor.show} aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle>
				<span>Replace Ink</span>
			</DialogTitle>
			<DialogContent>
				<div className="editor-text" style={{ marginBottom: '5px' }}>Replace ink color with:</div>
				<div style={{ height: '12rem', width: '22rem', background: 'var(--panel-semi-light-background)' }} className="scroll-vertical">
					<ColorGrid itemDraggable={false}
						colorlist={allColors?.filter((color, index) => index !== replaceColor.index)}
						onClick={e => {
							setReplaceColor((prevState) => {
								return {
									...prevState,
									color: e
								}
							});
						}}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<PrimaryButton negative onClick={() => setReplaceColor((prevState) => {
					return {
						...prevState,
						show: false,
						color: {}
					}
				})}>
					Cancel
				</PrimaryButton>
				<PrimaryButton positive
					onClick={() => {
						const oldColor = allColors[replaceColor.index];
						const colorsArr = colors.slice();
						colorsArr.splice(replaceColor.index, 1);
						setColors(colorsArr);

						Utils.replaceColorInFabricObjects(canvas, oldColor, replaceColor.color);
						setReplaceColor((prevState) => {
							return {
								...prevState,
								show: false,
								color: {}
							}
						});
						canvas.fire('palette:changed', { target: colorsArr, completeState: true });
					}} disabled={Object.keys(replaceColor.color).length === 0}>OK</PrimaryButton>
			</DialogActions>
		</Dialog>
	</>;
}