import React, { useEffect, useState } from "react";
import { CategorySelect } from './CategoryDialog';
import { useSelector } from 'react-redux';
import classes from './ClipartView.module.css';
import Utils from "../../../Utils";

let Designer,
    Ext,
    fabric;

const action = {
    addClipartToCanvas: function (clipartData, color) {
        var appData = Designer.AppData,
            canvas = appData.getCanvas(),
            canvasPanelController = Ext.canvasController;

        return new Promise(function(resolve, reject) {
            fabric.loadSVGFromURL(clipartData.filePath, function (objects, options) {
                if (!objects || objects.length === 0) {
                    reject();
                    return;
                }

                objects.forEach(obj => {
                    obj.set({
                        fill: color?.getColor() || '',
                        sepName: color?.name
                    });
                });

                var group = fabric.util.groupSVGElements(objects, options);
                var bounds = canvasPanelController.getProductBounds();

                var position = {
                    x: bounds.left,
                    y: bounds.top
                };

                var scale = 1.0,
                    scaleX = 1.0,
                    scaleY = 1.0;

                scaleX = (bounds.width / group.width) * 0.3;
                scaleY = (bounds.height / group.height) * 0.3;
                if (scaleX < scaleY)
                    scale = scaleX;
                else
                    scale = scaleY;

                position.y += (bounds.height - scale * group.height) / 2;
                position.x += (bounds.width - scale * group.width) / 2;

                group.scale(scale, scale);

                group.set({
                    left: position.x,
                    top: position.y
                });

                canvas.putBeforeOverlay(group);

                canvas.setActiveObject(group);
                canvas.renderAll();
                resolve();
            });
        });
    },

    onClipartViewItemClick: function (record, color) {
        var me = this;
        window.loading(true);
        setTimeout(function () {
            var promise = me.addClipartToCanvas(record, color);
            promise.then(function () {
                window.loading(false);
            }, function () {
                Ext.Msg.alert('Failed', 'Failed to add clipart.');
                window.loading(false);
            });
        }, 10);
    }
}

export default function ClipartWidget({ visible, backColor, separationColors = [] }) {
    const clipartData = useSelector(st => st.cliparts);
    const [selectedValue, setSelectedValue] = useState(0);
    const colors = separationColors.concat(backColor);
    const categories = clipartData?.libraries.map((i, idx) => ({
        ...i,
        label: i.name,
        value: idx
    })) || [];

    useEffect(() => {
        Designer = window.global.Designer;
        Ext = window.global.Ext;
        fabric = window.global.fabric;
    }, [])

    const selectedOption = categories[selectedValue] || [];

    return <div style={{ display: visible ? 'block' : 'none', height: "100%" }}>
        <CategorySelect categories={categories} value={selectedValue}
            style={{ padding: "0.6rem" }}
            categoryKey="name"
            onChange={(e, idx) => setSelectedValue(idx)}
        />
        <div style={{ height: `calc(100% - 6rem)`, marginBottom: 10, marginLeft: 5 }} className="scroll-vertical">
            <div>
                {
                    selectedOption?.items?.map((item) => {
                        return <div key={item.name} title={item.title}
                            className={classes.clipartItemThumbWrap}
                            onClick={() => action.onClipartViewItemClick(item, colors[0])}
                        >
                            <img className={Utils.getClasses('lozad', classes.clipartImage)} src={item.filePath} style={{ objectFit: 'contain' }} alt="Thumb"></img>
                        </div>;
                    })
                }
            </div>
        </div>
    </div>;
};