import React, { useState, useEffect } from 'react'
import ChatList from './ChatList';
import ChatHeader from './ChatHeader';
import sendMsgIcon from "../../assets/send-message.png";
import './css/ChatRoom.css'
import Utils from '../../Utils';
import { addLocation } from '../AnnotationsActions';
import { useDispatch } from 'react-redux';


export default function ChatRoom({ annotation, onBack, addComment, isReadOnly }) {

    const comments = annotation.comments || [];
    const dispatch = useDispatch();
    const [input, setInput] = useState("");
    const [enabled, setEnabled] = useState(annotation.closed);

    useEffect(() => {
        setEnabled(annotation.closed);
    }, [annotation])

    useEffect(() => {
        const elem = document.getElementById("allChats")
        elem.scrollTop = elem.scrollHeight;
    });


    function writeUserData() {
        if (input) {
            const data = {
                text: input,
                user: Utils.getUserId()
            }
            addComment(data);
            setInput("");
        }
    }
    function enterUserData(e) {
        if (e.keyCode === 13) {
            writeUserData();
        }
    }
    return (
        <div className='chatroom'>
            <div className='chat-header'>
                <ChatHeader onBack={onBack}
                    annotation={annotation}
                    onThreadClose={e => {
                        addLocation(dispatch, {
                            id: annotation.id,
                            closed: !enabled
                        }).then(res => {
                            if (res.data.success) {
                                setEnabled(state => !state);
                            }
                        })
                    }}
                    enabled={enabled}
                    isReadOnly={isReadOnly}
                />
            </div>
            <div className='chatlist'>
                <ChatList db={comments} user={annotation.user} />
            </div>
            {
                !isReadOnly && !annotation.system &&
                <div className='form'>
                    {
                        annotation.closed ? <div className='closed-thread-message'>This thread has been marked as completed. You can mark the thread as open by clicking on the flag icon.</div> :
                            <>
                                <input value={input} onChange={(e) => setInput(e.target.value)} onKeyUp={(e) => { enterUserData(e) }} placeholder='Type your comment...' id='msgBox' type="text" autoComplete='off' />
                                <button className='send-btn' onClick={writeUserData}><img src={sendMsgIcon} alt="Send message"></img></button>
                            </>
                    }
                </div>
            }
        </div>
    )
}
