import React, { useState } from 'react';
import TextButton from '../Buttons/TextButton';
import pinUp from '../../assets/pin-up.svg';
import pinDown from '../../assets/pin-down.svg';
import pinned from '../../assets/pinned.svg';
import classes from './TextBoxEditor.module.css';

function TextBoxEditor(props) {

  const pinObject = props.pinObject;
  const [value, setValue] = useState('');

  function submitHandler() {
    props.onSave(value);
    setValue('');
  }

  function cancelHandler() {
    props.onCancel();
    setValue('');
  }

  function addPinHandler() {
    props.addPinHandler("");
  }

  function isCancelDisabled() {
    if (value !== '' || pinObject.newThread.isPinned === true ||
      Object.keys(pinObject.newThread.location).length > 0) {
      return false;
    }
    return true;
  }

  function isSubmitDisabled() {
    if (value === '') {
      return true;
    }
    return false;
  }

  function checkPin() {
    if (pinObject.newThread.isPinned === false) {
      if (Object.keys(pinObject.newThread.location).length === 0) {
        return pinDown;
      } else {
        return pinUp;
      }
    } else {
      return pinned;
    }
  }

  return (<div className={classes.main} >
    <div className={classes.textArea}>
      <textarea className={classes.commentBox}
        placeholder={'Make a comment'}
        value={value}
        onChange={(e) => setValue(e.target.value)} />
    </div>
    <div className={classes.btn}>
      <div className={classes.leftBtnContainer}>
        <img src={checkPin()} width={20} onClick={addPinHandler} alt="Pin"
          onDragStart={e => e.dataTransfer.setData("droppable", true)}
        />
      </div>
      <div className={classes.rightBtnContainer}>
        <TextButton label={'Cancel'} onClick={cancelHandler} disabled={isCancelDisabled()} />
        <TextButton label={'Submit'} onClick={submitHandler} disabled={isSubmitDisabled()} />
      </div>
    </div>
  </div >);
}

export default TextBoxEditor;