import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getProject, updateProject, closeProject } from "../Projects/ProjectsActions.js";
import { ColorSeparations } from "./ToolPanels/ColorView.js";
import { fetchForegroundColorData, fetchPatternData, fetchBackgroundColorData, fetchFonts, fetchClipartData } from "./EditorAction.js";
import LeftController from "./LeftController.js";
import RightController from "./RightController";
import FabricCanvas, { FabricContainer, useFabricCanvasHandler } from "./FabricCanvas.js";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./components.css";
import { ColorWrapper } from "./ToolPanels/ManageColors.js";
import AddSwatch from "./AddSwatch.js";
import { useHistory } from "react-router";
let Ext;

const INCH_TO_MM = 25.4;
const DPI = 144;

function convertToMM(pixel, dpi = DPI) {
    return pixel * INCH_TO_MM / dpi
}

const backColor = new ColorWrapper({
    name: 'Textile',
    getColor: function () {
        return window.global?.Designer.AppData.productModelData?.backgroundColor || '';
    },
    locked: true,
    background: true
});

const action = {
    onOrderConfirmationClosed: function () {
        Ext?.canvasController?.terminate();
    },

    saveDesign: function (project, separationColors, callback) {
        const canvasController = Ext.canvasController;

        const designJson = canvasController.getJSON({
            includeDefaultValues: true
        });
        const designSvg = canvasController.getSVG({
            emitCanvasClip: true,
            useHiResImages: true,
            suppressBgObjects: true
        });
        const files = project.files;
        const productSizeData = canvasController.productSizeData;
        const file = {
            ...files[files.length - 1],
            separations: separationColors.map(col => ({
                fileName: col.fileName || col.name,
                color: col.getColor(),
                name: col.name,
                locked: col.locked ? undefined : false
            })),
            size: {
                widthMM: productSizeData.widthMM,
                heightMM: productSizeData.heightMM
            }
        };
        project = {
            ...project,
            files: file
        };
        const designThumb = canvasController.getThumbnail();
        return updateProject(designJson, designSvg, designThumb, project).then(e => {
            canvasController.setCurrentAsSaved();
            callback?.('projectSaved', 'Project saved successfully.', 'var(--text-light-color)');
        }).catch(() => {
            callback?.('projectSaved', 'Failed to save project.', 'red');
        });
    }
};

function setProductData(imageWidth, imageHeight, name, thumbnailPath, modelDesign, backgroundColor) {
    if (imageWidth && imageHeight) {
        let productModelData = {};
        productModelData.productWidth = imageWidth;
        productModelData.productHeight = imageHeight;
        productModelData.leftMargin = 0;
        productModelData.rightMargin = 0;
        productModelData.topMargin = 0;
        productModelData.bottomMargin = 0;
        productModelData.cornerRadius = 0;

        if (modelDesign) {
            productModelData.modelDesignPath = modelDesign;
        }
        productModelData.backgroundColor = backgroundColor;
        productModelData.modelDisplayName = name;
        productModelData.modelName = name;
        productModelData.modelThumbPath = thumbnailPath;
        return productModelData;
    }
}

function Footer({ sepDialog, openSepDialog, setSeparationColors, project, separationColors }) {
    const [backDialog, setBackDialog] = useState({ open: false });
    const [showNotificaion, setShowNotificaion] = useState({ place: '', content: '', color: '' });
    const clearMessage = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const [canSave, setCanSave] = useState(false);
    useFabricCanvasHandler(c => {
        function handler(e) {
            setCanSave(Ext.canvasController.isCanvasDirty());
        }
        return {
            'object:added': handler,
            'object:modified': handler,
            'background:changed': handler,
            'product-size:changed': handler,
            'object:removed': handler,
            'order:changed': handler,
            'canvas:x-ready': function () {
                delete window.Ext.canvasController.isTextDirty;
                handler();
            },
            'palette:changed': handler,
            'canvas:x-loaded': handler,
            'text:modified': handler
        }
    });

    useEffect(() => {
        if (user && !user.isActive) {
            moveBack();
        }
    }, [user]);

    function showNotifications(place, message, color) {
        clearTimeout(clearMessage.current)
        setShowNotificaion({ place: place, content: message, color: color });
        clearMessage.current = setTimeout(() => {
            setShowNotificaion({ place: '', content: '', color: '' })
        }, 3000)
    }

    function moveBack() {
        closeProject(dispatch);
        action.onOrderConfirmationClosed();
        // Editor is being closed. We no longer need to listen to the unload event.
        window.onbeforeunload = function () {
        };
        history.push('/sepstudio-ui');
    }

    return <>
        <div className="semi-light-background" style={{ padding: 10, position: 'relative' }}>
            {showNotificaion.place === 'projectSaved' &&
                <div className="project-saved-notification" style={{ color: showNotificaion.color }}>{showNotificaion.content}</div>
            }
            <PrimaryButton style={{ float: "right", marginLeft: '12px' }} positive
                disabled={!canSave}
                onClick={() => {
                    if (Ext.canvasController.isCanvasDirty()) {
                        action.saveDesign(project, separationColors, showNotifications).then(e => {
                            delete Ext.canvasController.isTextDirty;
                            setCanSave(false);
                        });
                    } else {
                        showNotifications('projectSaved', 'Project already saved !', 'var(--text-light-color)')
                    }
                }}>Save</PrimaryButton>
            <PrimaryButton style={{ float: "right" }}
                onClick={() => {
                    if (Ext.canvasController.isCanvasDirty()) {
                        setBackDialog({ open: true });
                    } else {
                        moveBack();
                    }
                }} negative>Close</PrimaryButton>
            <div>
                <div style={{ textAlign: 'center' }}>
                    <ColorSeparations colors={separationColors} backColor={backColor} setColors={setSeparationColors} openSepDialog={openSepDialog} />
                </div>
            </div>
        </div>
        {
            sepDialog &&
            <AddSwatch onClose={() => openSepDialog(false)}
                showNotificaion={showNotificaion}
                colors={separationColors}
                setSeparationColors={setSeparationColors}
            />
        }
        <Dialog
            open={backDialog.open} aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <span>Close Project</span>
            </DialogTitle>
            <DialogContent>
                <div style={{ color: '#c0c0c0' }}>You have unsaved changes.<br />Click Close to discard changes or Save to accept changes.</div>
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    onClick={() => {
                        moveBack();
                    }} negative={true}>Close</PrimaryButton>
                <PrimaryButton
                    onClick={() => {
                        action.saveDesign(project, separationColors).then(e => {
                            moveBack();
                            setCanSave(false);
                        });
                    }} autoFocus>Save</PrimaryButton>
            </DialogActions>
        </Dialog>
    </>
}

export default function Editor({ match, history }) {
    const [separationColors, setSeparationColors] = useState([]);
    const [projectData, setProjectData] = useState(null);
    const [sepDialog, openSepDialog] = useState(false);
    const [canvas, setCanvas] = useState(null);

    const project = useSelector(state => state.projects.project);
    const dispatch = useDispatch();
    const projectId = match.params.projectId;

    useEffect(() => {
        getProject(dispatch, projectId, history);
        fetchForegroundColorData(dispatch);
        fetchPatternData(dispatch);
        fetchClipartData(dispatch)
        fetchBackgroundColorData(dispatch);
        fetchFonts(dispatch);
        Ext = window.global.Ext;
    }, [projectId])

    useEffect(() => {
        const file = project?.files?.[project.files.length - 1];
        if (file) {
            const size = file.size;
            let productModelData = setProductData(
                size.widthMM || convertToMM(size.width, size.dpi),
                size.heightMM || convertToMM(size.height, size.dpi),
                project.name,
                project.proofPositivePath,
                project.designPath,
                file.backgroundColor
            );
            if (productModelData) {
                setProjectData(productModelData);
            }
        }
    }, [project]);

    return <FabricContainer value={canvas}>
        <div style={{ display: 'flex', flexFlow: 'column', height: 'calc(100% - 3rem)' }}>
            <div style={{ display: 'flex', backgroundColor: 'rgb(237,240,242)', height: '100%', overflowY: 'auto' }}>
                <div>
                    <LeftController style={{ width: '361px' }} backColor={backColor} separationColors={separationColors} openSepDialog={openSepDialog} />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <FabricCanvas style={{ height: '100%', overflow: 'hidden' }}
                        project={projectData} onShow={setCanvas}
                    />
                </div>
                <div className="dark-background" style={{ overflow: 'auto' }}>
                    <RightController setSeparationColors={setSeparationColors} separationColors={separationColors} />
                </div>
            </div>
            <Footer sepDialog={sepDialog} openSepDialog={openSepDialog} setSeparationColors={setSeparationColors}
                project={project} separationColors={separationColors}
            />
        </div>
    </FabricContainer>;
}