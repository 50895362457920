import React, { useState, Suspense } from "react";
import { useHistory } from 'react-router';
import PrimaryButton from "../Buttons/PrimaryButton";
import Password from "../Password/Password";
import classes from "./ChangePassword.module.css";
import Card from "../Card/Card";
import { updateUser, verifyUser, resendCode } from '../../modules/Users/UsersActions'
import MessageDialog from "../MessageDialog/MessageDialog";
import Verification from "../Verification/Verification";
import Consts from "../../Consts";
import NeedHelp from "../NeedHelp/NeedHelp";

const PasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'));
function ChangePassword(props) {
    const history = useHistory();

    const [userDetails, setUserDetails] = useState(props.userDetails || {});
    const [passwordScore, setPasswordScore] = useState(0);
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [showVerifyError, setShowVerifyError] = useState(false);
    const [isCodeResent, setIsCodeResent] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: "", message: "" });

    function createHandler() {
        if (passwordScore >= 1) {
            updateUser(userDetails).then((response) => {
                if (response && response.data && response.data.success) {
                    const obj = {
                        title: "Success",
                        message: "Account updated successfully."
                    };
                    setDialogContent(() => {
                        return obj;
                    });
                    setShowMessageDialog(true);
                } else if (response.data?.errorCode === "wrong_password") {
                    setDialogContent(() => {
                        const obj = {
                            title: "Failed",
                            message: "Incorrect password."
                        };
                        return obj;
                    });
                    setShowMessageDialog(true);
                } else if (response.data?.errorCode === "unauthorized") {
                    setDialogContent(() => {
                        const obj = {
                            title: "Failed",
                            message: "Unauthorized user."
                        };
                        return obj;
                    });
                }
            });
        }
    }

    function verificationHandler(verificationCode) {
        const data = { ...userDetails };
        data.otp = verificationCode;
        setIsCodeResent(false);
        verifyUser(data).then((response) => {
            if (response && response?.data?.success) {
                setShowVerification(false);
                if (props.isForgotPassword) {
                    history.push('/sepstudio-ui');
                } else {
                    history.push({
                        pathname: '/sepstudio-ui/profile',
                        state: {
                            from: history.location.pathname
                        }
                    });
                }
            } else {
                setShowVerifyError(true);
            }
        })
    }

    function dialogCloseHandler() {
        setShowMessageDialog(false);
        if (dialogContent.title !== "Failed")
            history.goBack();
    }

    function resendCodeHandler() {
        setIsCodeResent(false);
        setShowVerifyError(false);
        const data = { ...userDetails };
        resendCode(data).then((response) => {
            if (response && response?.data?.success) {
                setIsCodeResent(true);
            }
        });
    }

    function closeHandler() {
        history.goBack();
    }

    return (
        <div className={classes.mainBox}>
            {showMessageDialog && <MessageDialog {...dialogContent} onCancel={dialogCloseHandler} isCancel closeText="Close" width={Consts.MESSAAGE_DIALOG_WIDTH} />}
            {showVerification && <Verification {...dialogContent} isCodeResent={isCodeResent} onVerify={verificationHandler} onResend={resendCodeHandler} showVerifyError={showVerifyError} onCancel={() => setShowVerification(false)} />}
            <Card >
                <div className={classes.main}>
                    <div className={classes.heading}>
                        <h2>Change Password</h2>
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="existingPassword">Existing Password</label>
                        <Password id="existingPassword" togglePasswordVisibility={true} width="17rem" height="2rem"
                            onChange={(e) =>
                                setUserDetails((prevState) => {
                                    return {
                                        ...prevState,
                                        existingPassword: e.target.value
                                    }
                                })} autoComplete="off" onCommit={createHandler} 
                        />
                        <label htmlFor="password">Password</label>
                        <Password id="password" togglePasswordVisibility={true}
                            width="17rem" height="2rem"
                            onChange={(e) =>
                                setUserDetails((prevState) => {
                                    return {
                                        ...prevState,
                                        password: e.target.value
                                    }
                                })
                            } autoComplete="off" onCommit={createHandler} 
                        />
                        <div className={classes.passwordStrengthBarContainer}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <PasswordStrengthBar password={userDetails.password} scoreWords={["weak", "weak", "okay", "good", "strong"]} scoreWordStyle={{ color: "rgb(208 , 208, 208)", fontSize: "0.75rem" }} minLength={4} shortScoreWord='too short'
                                    onChangeScore={(score) => setPasswordScore(score)} />
                            </Suspense>
                        </div>
                        <div className={classes.actions} >
                            <div className={classes.needHelp} style={{ textAlign: 'start' }}>
                                <NeedHelp />
                            </div>
                            <PrimaryButton onClick={closeHandler} negative>
                                Cancel
                            </PrimaryButton>
                            <PrimaryButton onClick={createHandler}>Update</PrimaryButton>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default ChangePassword;