import React from "react";
import classes from './PanelButton.module.css';

function PanelButton(props) {
    const disabled = props.state?.disabled ? classes['x-item-disabled'] : '';
    const menu = props.showMenu ? classes['show-inner-menu'] : '';

    return (
        <div className={`${classes["panel-button"]} ${disabled} ${menu}`} onClick={props.onClick}>
            <React.Fragment>{props.children}</React.Fragment>
            <span className={props.className} />
            <div className={classes["x-btn-inner"]}>{props.title}</div>
            <div className={classes["x-btn-pointer"]}></div>
        </div>
    );
}

export default PanelButton;