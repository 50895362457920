import React from 'react';
import classes from './MenuButton.module.css';

function MenuButton(props) {
    const disabled = props.state?.disabled ? classes['disabled'] : '';
    return (
        <div onClick={props.onClick} className={`${classes['menu-button']} ${disabled}`}>
            <div>
                <span className={props.className} style={props.iconStyle}></span>
            </div>
            <label style={props.labelStyle}>{props.title}</label>
        </div>
    );
}

export default MenuButton;