import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import { store } from './store';
import { Provider } from 'react-redux';
import { Router } from "react-router-dom";
import browserHistory from './history';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={browserHistory}>
        <App />
      </Router >
    </Provider>
  </React.StrictMode>,
  rootElement
);