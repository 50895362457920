import React, { useState } from 'react';
import Dialog from '../Dialog/Dialog';
import Input from '../Input/Input';
import PrimaryButton from '../Buttons/PrimaryButton';
import MessageDialog from '../MessageDialog/MessageDialog';
import classes from './ActivateUser.module.css';
import { activateUser } from '../../modules/Users/UsersActions';
import { useDispatch, useSelector } from 'react-redux';
import Consts from '../../Consts';
import NeedHelp from '../NeedHelp/NeedHelp';


function activationTitle(user) {
  if (!user) {
    return;
  }
  const { isActive, isTrial } = user;
  if (isTrial) {
    return 'Activate License';
  }

  return isActive ? 'Renew License' : 'Activate License';
}

const ActivateUser = (props) => {

  const [serialNumber, setSerialNumber] = useState("");
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.user);
  const userData = userDetails.user || {};
  const [messageContent, setMessageContent] = useState({
    title: "",
    message: ""
  });
  const [showMessageDialog, setShowMessageDialog] = useState(false);

  function activateHandler() {
    activateUser(dispatch, { serialNumber, mode: 'validity', clientId:  Consts.CLIENT_ID }).then((response) => {
      if (response.data?.success) {
        props.setShowDialog(false);
      } else {
        setMessageContent({
          title: "Activation Error",
          message: <>
            <span>Enter Valid Activation Code. </span>
            <NeedHelp />
          </>
        });
        setShowMessageDialog(true);
      }
    })
  }

  return (
    <>
      <Dialog
        showCloseIcon={true}
        title={activationTitle(userData)}
        width="18rem"
        content={
          <div className={classes.activateUserContent}>
            <label htmlFor='input'>
              <span>Enter Activation Code below. </span>
              <NeedHelp />
            </label>
            <Input id="input" type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value.trim())} onKeyDown={(e) => e.key === 'Enter' && activateHandler()} />
            <label className={classes.purchaseLicense}>
              <span>To purchase a license code </span>
              <a href='https://solutionsforscreenprinters.com/nxt-moves' target="_blank">click here.</a>
            </label>
          </div>
        }
        footer={
          <div className={classes.activateUserActions}>
            <PrimaryButton onClick={() => props.setShowDialog(false)} negative>
              Close
            </PrimaryButton>
            <PrimaryButton onClick={activateHandler} positive disabled={!serialNumber}>
              OK
            </PrimaryButton>
          </div>}
      />
      {
        showMessageDialog && <MessageDialog {...messageContent} closeText='Close' isCancel
          onCancel={() => setShowMessageDialog(false)}
          width={Consts.MESSAAGE_DIALOG_WIDTH}
        />
      }
    </>
  )
}

export default ActivateUser
