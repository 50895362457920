import React, { useState } from "react";
import ToolbarButton from "../../components/Buttons/ToolbarButton";
import TextWidget from "./ToolPanels/TextView/TextView";
import SizeWidget from "./ToolPanels/SizeWidget";
import ZoomWidget from "./ToolPanels/ZoomView";
import TextileWidget from "./ToolPanels/BackgroundView";
import textileIcon from '../../assets/textiles_tool.svg';
import clipartIcon from '../../assets/clipart_tool.svg';
import textIcon from '../../assets/text_tool.svg';
import textureIcon from '../../assets/texture_tool.svg';
import zoomIcon from '../../assets/zoom_tool.svg';
import sizeIcon from '../../assets/size_tool.svg'
import "./components.css";
import PatternWidget from "./ToolPanels/PatternView.js";
import ClipartWidget from "./ToolPanels/ClipartView";

export default function LeftController({ style, separationColors, openSepDialog, backColor }) {
    const [active, setActive] = useState('textile');
    return <div style={{ ...style, display: 'flex', height: '100%' }}>
        <div className="dark-background" style={{ overflowY: 'auto', width: 72 }}>
            <div>
                <ToolbarButton state={{ pressed: active === 'textile' }} title="Textile" icon={textileIcon} onClick={e => setActive('textile')} />
                <ToolbarButton state={{ pressed: active === 'text' }}
                    title="Text" icon={textIcon}
                    onClick={e => setActive('text')} />
                <ToolbarButton state={{ pressed: active === 'clipart' }} title="Clipart" icon={clipartIcon} onClick={e => setActive('clipart')} />
                <ToolbarButton state={{ pressed: active === 'pattern' }} title="Pattern" icon={textureIcon} onClick={e => setActive('pattern')} />
                <ToolbarButton state={{ pressed: active === 'size' }} title="size"
                    icon={sizeIcon} onClick={e => setActive('size')} />
                <ToolbarButton state={{ pressed: active === 'zoom' }} title="Zoom"
                    icon={zoomIcon} onClick={e => setActive('zoom')} />
            </div>
        </div>
        <div className="tool-panel-container semi-dark-background" style={{ overflowY: 'auto' }}>
            <TextileWidget visible={active === 'textile'} backColor={backColor} />
            <TextWidget visible={active === 'text'} backColor={backColor} separationColors={separationColors} openSepDialog={openSepDialog} />
            <PatternWidget visible={active === 'pattern'} />
            {
                active === 'size' &&
                <SizeWidget visible={true} />
            }
            <ClipartWidget visible={active === 'clipart'} backColor={backColor} separationColors={separationColors} />
            <ZoomWidget visible={active === 'zoom'} />
        </div>
    </div>;
};