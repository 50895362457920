import React, { useEffect, useState } from 'react';
import Switch from "react-switch";
import classes from './SliderButton.module.css';

function SliderButton(props) {
    const [checked, setChecked] = useState(props.checked || false);

    function handleChange(e) {
        setChecked(e);
        props.onChange && props.onChange(e);
    }

    useEffect(() => {
        setChecked(props.checked || false);
    }, [props.checked]);

    return <div className={classes.main} style={props.style}>
        <div>
            <label className='editor-text' style={props.uncheckedLabelStyle}>{props.uncheckedLabel}</label>
        </div>
        <div className={classes.switch}>
            <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={checked}
                onChange={handleChange}
                offColor="#e6e7e8"
                onColor="#e6e7e8"
                offHandleColor="#0E5FBC"
                onHandleColor="#0E5FBC"
            />
        </div>
        <div>
            <label className='editor-text'>{props.checkedLabel}</label>
        </div>
    </div>
}

export default SliderButton;
