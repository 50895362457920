import React, { useState } from 'react';
import Dialog from '../Dialog/Dialog';
import PrimaryButton from '../Buttons/PrimaryButton';
import classes from './UserProfile.module.css';
import MessageDialog from '../MessageDialog/MessageDialog';
import { updateProfile } from '../../modules/Users/UsersActions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../Input/Input';

function UserProfile() {
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user?.user) || {};
    const [userProfile, setUserProfile] = useState({
        firstName: userData.firstName || "",
        lastName: userData.lastName || ""
    });
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const isfromSignUp = history.location?.state?.from === '/sepstudio-ui/sign-up';

    async function verifyUserProfile() {
        let response = await updateProfile(userProfile, dispatch);
        if (response.data?.success) {
            history.push('/sepstudio-ui/');
        } else {
            setShowMessageDialog(true);
        }
    }

    function userProfileHandler(property) {
        return (e) => {
            setUserProfile((prevState) => {
                const newProfile = { ...prevState };
                newProfile[property] = e.target.value;
                return newProfile;
            });
        }
    }

    function closeHandler() {
        history.goBack();
    }

    return <>
        {showMessageDialog && <MessageDialog title="Failed" message="Failed to update your profile." onCancel={() => setShowMessageDialog(false)} isCancel closeText="Close" />}
        <Dialog
            title="Complete Profile"
            content={
                <div className={classes.userProfileContent}>
                    <label htmlFor='firstName'>First Name</label>
                    <Input id="firstName" type="text" value={userProfile.firstName} onChange={userProfileHandler('firstName')} />
                    <label htmlFor='lastName'>Last Name</label>
                    <Input id="lastName" type="text" value={userProfile.lastName} onChange={userProfileHandler('lastName')} onKeyDown={(e) => e.key === 'Enter' && verifyUserProfile()} />
                </div>
            }
            footer={
                <div className={classes.userProfileActions}>
                    {
                        !isfromSignUp &&
                        <PrimaryButton onClick={closeHandler} negative>Cancel</PrimaryButton>
                    }
                    <PrimaryButton onClick={verifyUserProfile} disabled={!userProfile.firstName}>
                        OK
                    </PrimaryButton>
                </div>}
        />
    </>
}

export default UserProfile;