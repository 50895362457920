const action = {
    getSelectedTextObjects: function () {
        var canvas = window.global.Designer.AppData.getCanvas();
        var textObjects;
        if (canvas && canvas.getActiveObjects()) {
            textObjects = canvas.getActiveObjects().filter(function (o) {
                return (o.get('type') === 'shaped-text' || o.get('type') === 'curved-text');
            });
        }
        return textObjects;
    },
    getTextAreaState: function (textObjects) {
        let text = "";
        if (textObjects.length === 1) {
            let object = textObjects[0];
            text = object.get('text');
            var savedTextValue = object.get('savedTextValue');

            if (savedTextValue === undefined) {
                object.set('savedTextValue', text);
            }
        }

        var isLocked = this.isSelectionLocked();
        return { 
            value: text,
            disabled: textObjects.length > 1 || isLocked 
        };
    },
    isSelectionLocked: function () {
        var canvas = window.global.Designer.AppData.getCanvas();
        var activeObject = canvas?.getActiveObject();
        if (activeObject) {
            return activeObject.isLocked || false;
        }
    },
    getFontDropdownState: function (textObjects, fonts, currentIdx) {
        let fontFamily = this.getTextAttribute('fontFamily', textObjects),
            state = {};
        if (fontFamily !== 'empty' && fontFamily !== 'mixed') {
            let i, j, found;
            const keys = Object.keys(fonts);
            if (fonts[currentIdx]) {
                keys.splice(currentIdx, 1);
                keys.unshift(currentIdx);
            }
            for (let k of keys) {
                let fs = fonts[k];
                i = Number(k);
                for (j = 0; j < fs.length; ++j) {
                    if (fs[j]?.font?.familyName === fontFamily) {
                        found = fs[j];
                        break;
                    }
                }
                if (found)
                    break;
            }
            state.value = found;
            state.selected = i;
        } else {
            state.value = null;
        }
        state.disabled = this.isSelectionLocked();
        return state;
    },
    getTextAttribute: function (attrName, textObjects) {
        // Get the attribute value.
        // For empty selection, returns "empty".
        // If the selection has a single value for this attribute, returns
        // that value. Otherwise returns "mixed".
        var attrValue = 'empty';

        if (!textObjects) {
            textObjects = this.getSelectedTextObjects();
        }

        if (textObjects.length > 0) {
            attrValue = textObjects[0].get(attrName);
            if (attrName === 'fontFamily') {
                attrValue = this.removeFallbackFont(attrValue);
            }
        }

        for (var i = 1; i < textObjects.length; i++) {
            var objectValue = textObjects[i].get(attrName);
            if (attrName === 'fontFamily') {
                objectValue = this.removeFallbackFont(objectValue);
            }
            if (objectValue !== attrValue) {
                attrValue = 'mixed';
                break;
            }
        }
        return attrValue;
    },
    addFallbackFont: function (fontFamily) {
        // Add the notdef font, shows the not defined glyph.
        return '\'' + fontFamily + '\', Notdef';
    },
    removeFallbackFont: function (fontFamily) {
        // Remove the notdef font.
        fontFamily = fontFamily.replace(/, Notdef/i, '');
        return fontFamily.replace(/'/g, '');
    },
    onFontComboChange: function (newValue) {
        var fontStyles = this.getFontStyles(newValue);
        var textObjects = this.getSelectedTextObjects();
        if (textObjects.length !== 0) {
            // Update the font family and allowed styling based on font.
            var me = this;
            textObjects.forEach(function (object) {
                let isBold = object.fontWeight === 'bold',
                    isItalic = object.fontStyle === 'italic',
                    fontWeight = object.fontWeight,
                    fontStyle = object.fontStyle;
                if (isBold && isItalic && !fontStyles.boldItalic) {
                    fontStyle = "normal";
                    fontWeight = "normal";
                } else {
                    if (isBold && !fontStyles.bold) {
                        fontWeight = "normal";
                    }
                    if (isItalic && !fontStyles.italic) {
                        fontStyle = "normal";
                    }
                }
                object.set({
                    fontFamily: me.addFallbackFont(newValue?.familyName),
                    fontWeight: fontWeight,
                    fontStyle: fontStyle
                });
                object.initDimensions();
            });

            this.updateActiveGroup();

            var canvas = window.global.Designer.AppData.getCanvas();
            canvas.renderAll();
            setTimeout(() => canvas.fire('object:modified', {target: textObjects}), 1000);
        }
    },
    onTextChange: function (newValue, attributes, isCurved) {
        var appData = window.global.Designer.AppData,
            canvas = appData.getCanvas(),
            canvasPanelController = window.global.Ext.canvasController;

        // If a text object is selected, update the text.
        // In all other cases, add a new text object.
        var textObjects = this.getSelectedTextObjects();
        if (textObjects.length === 0) {
            var textObject;

            window.global.Ext.undoRedoContoller.mute();

            var fontFamily = attributes.fontFamily;
            if (!fontFamily) {
                fontFamily = this.getDefaultFontFamily();
            }

            // Add the fallback font.
            fontFamily = this.addFallbackFont(fontFamily);

            if (isCurved) {
                textObject = new window.fabric.CurvedText(newValue, {
                    fontFamily: fontFamily,
                    fontSize: 10,
                    fontWeight: attributes.fontWeight,
                    fontStyle: attributes.fontStyle,
                    underline: attributes.underline,
                    fill: attributes.fill || '#000',
                    scaleX: 0.8,
                    scaleY: 0.8,
                    savedTextValue: newValue,
                    textAlign: attributes.textAlignment,
                    radius: attributes.radius,
                    placement: attributes.placement,
                    spacing: 2,
                    sepName: attributes.sepName
                });
            } else {
                textObject = new window.fabric.ShapedText(newValue, {
                    fontFamily: fontFamily,
                    fontSize: 10,
                    fontWeight: attributes.fontWeight,
                    fontStyle: attributes.fontStyle,
                    underline: attributes.underline,
                    fill: attributes.fill || '#000',
                    scaleX: 0.8,
                    scaleY: 0.8,
                    textAlign: attributes.textAlignment,
                    savedTextValue: newValue,
                    warpAmount: attributes.warpAmount,
                    tracking: attributes.tracking,
                    warpMode: attributes.warpMode,
                    sepName: attributes.sepName
                });
            }
            this.setDeselectHandler(textObject);

            // Position the object centered in bounds.
            var referenceBounds = canvasPanelController.getProductBounds();

            // Add the text object to canvas.
            canvas.putBeforeOverlay(textObject);

            // Set default text font size at 15% of long side of the template.
            var productBounds = canvasPanelController.getProductBounds();
            var longSide = (productBounds.width < productBounds.height) ? productBounds.height : productBounds.width;
            var lineHeight = longSide * 0.15, // 15% of long side
                lineHeightFactor = 1.25; // line-height to font-size ratio

            if (lineHeight > referenceBounds.height) {
                lineHeight = referenceBounds.height;
            }

            var reqFontSize = lineHeight / lineHeightFactor;
            var scale = reqFontSize / (textObject.fontSize * textObject.scaleX),
                scaleX = textObject.scaleX * scale,
                scaleY = textObject.scaleY * scale;

            textObject.set({
                'scaleX': scaleX,
                'scaleY': scaleY,
                'startScaleX': scaleX,
                'startScaleY': scaleY
            });
            textObject.setCoords();

            canvasPanelController.alignObjectWithinBounds(textObject, referenceBounds, attributes.textAlignment, true);
            textObject.setCoords();

            // Remember the previous width and height.
            let prevSize = textObject._getTransformedDimensions();

            // Adjust the object position as per the alignment.
            this.adjustTextObjectPosition(textObject, prevSize.x, prevSize.y);

            window.global.Ext.undoRedoContoller.unmute();

            // Set as active.
            canvas.setActiveObject(textObject);
        } else if (textObjects.length === 1) {
            textObject = textObjects[0];

            // Remember the previous width and height.
            let prevSize = textObject._getTransformedDimensions();

            // Update the text.
            if (textObject.get('type') === 'curved-text') {
                textObject.setText(newValue);
            }
            textObject.set('text', newValue);

            // Adjust the object position as per the alignment.
            this.adjustTextObjectPosition(textObject, prevSize.x, prevSize.y);

            
        }

        canvas.calcOffset();
        canvas.renderAll();

        // Don't register every char input as an undo step. Do it for a block of chars.
        if (textObjects.length && (newValue.length % window.global.Designer.app.constants.UNDO_TEXT_BLOCK_LENGTH) === 0) {
            canvas.fire('object:modified', { target: textObjects });
        } else {
            canvasPanelController.isTextDirty = true;
            canvas.fire('text:modified', { target: textObjects });
        }
    },
    setDeselectHandler: function (textObject) {
        var me = this;
        textObject.on({
            'deselected': function () {
                me.syncText(this);
            }
        });
    },
    adjustTextObjectPosition: function (textObject, prevWidth, prevHeight) {
        // No need to position, manage by origin center, center.
    },
    syncText: function (textObject) {
        var text = textObject.get('text');
        var savedTextValue = textObject.get('savedTextValue');

        if (text !== savedTextValue) {
            textObject.set('savedTextValue', text);

            var canvas = window.global.Designer.AppData.getCanvas();
            canvas.fire('object:modified', { target: textObject });
        }
    },
    getFontStyles: function (font) {
        var styles = font.styles;
        return {
            bold: styles.indexOf('bold') !== -1,
            italic: styles.indexOf('italic') !== -1,
            boldItalic: styles.indexOf('boldItalic') !== -1
        };
    },
    updateActiveGroup: function () {
        var canvas = window.global.Designer.AppData.getCanvas();

        var activeObject = canvas.getActiveObject();

        if (activeObject && activeObject.get('type') === 'activeSelection') {
            activeObject.addWithUpdate(); // Recalculate the group's dimensions and position
            activeObject.setCoords();
        }
    },
    getFontStyleButtonsState: function(fontWeight, fontStyle, underline, font) {
        let styles = font?.styles || [],
            fontStyleAttributes = {
                bold: styles.indexOf('bold') !== -1,
                italic: styles.indexOf('italic') !== -1,
                boldItalic: styles.indexOf('boldItalic') !== -1
            },
            disable = this.isSelectionLocked();
        if (disable === undefined) {
            disable = true;
        }
        let bDisable = disable,
            iDisable = disable;
        if (!disable) {
            bDisable = fontStyle === 'italic' ? !fontStyleAttributes.boldItalic : 
                        (!fontStyleAttributes.bold && !fontStyleAttributes.boldItalic);
            iDisable = fontWeight === 'bold' ? !fontStyleAttributes.boldItalic : 
                        (!fontStyleAttributes.italic && !fontStyleAttributes.boldItalic);
        }
        return {
            bold: {
                pressed: fontWeight === 'bold',
                disabled: bDisable
            },
            italic: {
                pressed: fontStyle === 'italic',
                disabled: iDisable
            },
            underline: {
                pressed: underline === true,
                disabled: disable
            }
        };
    },
    applyTextAttribute: function (attrName, attrValue, suppressNotify, suppressRender) {
        var textObjects = this.getSelectedTextObjects();
        if (textObjects.length === 0) {
            return;
        }

        for (var i = 0; i < textObjects.length; i++) {
            textObjects[i].set(attrName, attrValue);
            textObjects[i].initDimensions();
        }

        this.updateActiveGroup();

        if (!suppressRender) {
            var canvas = window.global.Designer.AppData.getCanvas();
            canvas.renderAll();
            !suppressNotify && canvas.fire('object:modified', {target: textObjects});
        }
    },
    onFontStyleBoldButtonClick: function (pressed) {
        var fontWeight = pressed ? 'bold' : 'normal';
        var textObjects = this.getSelectedTextObjects();
        if (textObjects.length !== 0) {
            textObjects.forEach(function (object) {
                // Update the font weight and allowed styling based on font weight.
                object.set('fontWeight', fontWeight);
                object.set('origFontWeight', fontWeight);

                object.initDimensions();
            });

            this.updateActiveGroup();

            var canvas = window.global.Designer.AppData.getCanvas();
            canvas.renderAll();
            setTimeout(() => canvas.fire('object:modified', {target: textObjects}), 1000);
        }
    },
    onFontStyleItalicButtonClick: function (pressed) {
        var fontStyle = pressed ? 'italic' : 'normal';
        var textObjects = this.getSelectedTextObjects();
        if (textObjects.length !== 0) {
            textObjects.forEach(function (object) {
                // Update the font style and allowed styling based on font style.
                object.set('fontStyle', fontStyle);
                object.set('origFontStyle', fontStyle);
                object.initDimensions();
            });

            this.updateActiveGroup();

            var canvas = window.global.Designer.AppData.getCanvas();
            canvas.renderAll();
            setTimeout(() => canvas.fire('object:modified', {target: textObjects}), 1000);
        }
    },
    updateTracking: function (amount) {
        var textObjects = this.getSelectedTextObjects();
        if (textObjects.length === 0) {
            return;
        }
        for (var i = 0; i < textObjects.length; i++) {
            let textObject = textObjects[i];
            if (textObject.type === 'shaped-text' || textObject.type === 'curved-text') {
                let prevSize = textObject._getTransformedDimensions();
                textObject.set('tracking', amount);
                textObject.initDimensions();
                this.adjustTextObjectPosition(textObject, prevSize.x, prevSize.y);
            }
        }
        var canvas = window.global.Designer.AppData.getCanvas();
        canvas.renderAll();
    },
    notifyUndoRedo: function (target) {
        if (!target) {
            target = action.getSelectedTextObjects();
        }
        window.global.Designer?.AppData?.getCanvas()?.fire('object:modified', target && {target});
    },
    updateStrokeWidth: function (val) {
        var textObjects = this.getSelectedTextObjects();
        if (textObjects.length === 0) {
            return;
        }
        for (var i = 0; i < textObjects.length; i++) {
            textObjects[i].set('strokeWidth', val * 0.01);
            textObjects[i].dirty = true;
        }
        window.global.Designer.AppData.getCanvas().renderAll();
    },
    onTextTypeChange: function (newValue, radius) {
        var textObjects = this.getSelectedTextObjects();
        if (textObjects.length === 0) {
            return;
        }

        window.global.Ext.undoRedoContoller.mute(); // Mute undo/redo handling. We notify once at end.

        var canvas = window.global.Designer.AppData.getCanvas();

        var temp = canvas.renderOnAddRemove;
        canvas.renderOnAddRemove = false;

        // Discard the active selection.
        canvas.discardActiveObject();

        var newObjects = [];

        for (var i = 0; i < textObjects.length; i++) {
            var newTextObject;
            let options = this.getTextProperties(textObjects[i]);
            if (newValue) {
                if (textObjects[i].type === 'curved-text') {
                    newObjects.push(textObjects[i]);
                    continue;
                }
                // Add the curved text attributes.
                options.radius = radius;

                newTextObject = new window.fabric.CurvedText(textObjects[i].text, options);
            } else {
                if (textObjects[i].type === 'shaped-text') {
                    newObjects.push(textObjects[i]);
                    continue;
                }

                // Remove the curved text attributes.
                delete options.radius;
                delete options.spacing;
                delete options.placement;

                newTextObject = new window.fabric.ShapedText(textObjects[i].text, options);
            }

            newObjects.push(newTextObject);

            // Add to canvas at the same position.
            var index = canvas.getObjects().indexOf(textObjects[i]);
            canvas.insertAt(newTextObject, index, true);
        }

        var newObject;
        if (newObjects.length === 1) {
            // Set the object as active.
            canvas.setActiveObject(newObjects[0]);
            newObject = newObjects[0];
        } else {
            // Create a selection group for the objects.
            var selectionGroup = new window.fabric.ActiveSelection(newObjects, {
                canvas: canvas
            });
            canvas.setActiveObject(selectionGroup);
            newObject = selectionGroup;
        }

        canvas.renderOnAddRemove = temp;

        window.global.Ext.undoRedoContoller.unmute();

        canvas.calcOffset();
        canvas.renderAll();
        canvas.fire('object:modified', {target: [newObject]});
    },
    getTextProperties: function (textObject) {
        var propertiesToInclude = [
            'savedTextValue',
            'skipDrawing',
            'startScaleX',
            'startScaleY',
            'warpMode',
            'warpAmount',
            'tracking'
        ];

        // Set clipTo to null since it does not round-trip well through JSON.
        // We will set it later if needed.
        textObject.clipTo = null;

        var options = textObject.toObject(propertiesToInclude);

        // Remove the type and text properties.
        delete options.type;
        delete options.text;

        return options;
    },
    updateShape: function (shape, amount) {
        var textObjects = this.getSelectedTextObjects(),
            isChanged = false;
        if (textObjects.length === 0) {
            return;
        }
        for (var i = 0; i < textObjects.length; i++)
            if (textObjects[i].type === 'shaped-text') {
                textObjects[i].set('warpMode', shape);
                textObjects[i].set('warpAmount', amount);
                textObjects[i].initDimensions();
                isChanged = true;
            }
        if (isChanged) {
            let canvas = textObjects[0].canvas;
            canvas.renderAll();
            canvas.fire('object:modified', {target: textObjects});
        }
    },
    alignTextObjects: function (textObjects) {
        var canvasPanelController = window.global.Ext.canvasController;
        var productBounds = canvasPanelController.getProductBounds();
        if (!textObjects) {
            textObjects = this.getSelectedTextObjects();
        }
        if (textObjects.length === 0) {
            return;
        }
        textObjects.forEach(function (textObject) {
            var referenceBounds = productBounds;
            var alignment = textObject.textAlign;
            textObject.setCoords();
            canvasPanelController.alignObjectWithinBounds(textObject, referenceBounds, alignment);
            textObject.setCoords();
        });
        this.updateActiveGroup();
        var canvas = window.global.Designer.AppData.getCanvas();
        canvas.renderAll();
        canvas.fire('object:modified', {target: textObjects});
    }
}
export default action;