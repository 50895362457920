import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import PrimaryButton from './components/Buttons/PrimaryButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Header from './components/Header/Header';
import "./styles.css";
import "./resources/ws-all.css";
import SignUp from "./components/SignUp/SignUp";
import Loading from '../App/components/Loading/Loading';
import SignIn from "./components/SignIn/SignIn";
import Utils from "./Utils";
import MessageDialog from "./components/MessageDialog/MessageDialog";
import Dashboard from "./Dashbord";
import Consts from "./Consts";

const resourceLoader = {
  map: {},
  loadScript: function (src) {
    if (!this.map[src]) {
      this.map[src] = new Promise(function (resolve, reject) {
        var s = document.createElement('script');
        s.src = src;
        s.type = "text/javascript";
        s.onload = resolve;
        s.onerror = reject;
        document.getElementsByTagName('head')[0].appendChild(s);
      });
    }
    return this.map[src];
  },
  loadCss: function (src) {
    if (!this.map[src]) {
      this.map[src] = new Promise(function (resolve, reject) {
        var s = document.createElement('link');
        s.rel = 'stylesheet';
        s.type = 'text/css';
        s.href = src;
        s.media = 'all';
        s.onload = resolve;
        s.onerror = reject;
        document.getElementsByTagName('head')[0].appendChild(s);
      });
    }
    return this.map[src];
  },
  loadResources: async function () {
    let path = process.env.PUBLIC_URL;
    for (let i = 0, len = arguments.length; i < len; ++i) {
      let arg = arguments[i],
        method;
      if (!arg || !arg.src)
        continue;
      switch (arg.type) {
        case 'css':
          method = 'loadCss';
          break;
        default:
        case 'js':
          method = 'loadScript';
          break;
      }
      if (arg.src instanceof Array) {
        let arr = [];
        arg.src.forEach(function (e) {
          arr.push(resourceLoader[method](path + '/' + e));
        });
        await Promise.all(arr);
      } else if (typeof arg.src === 'string') {
        await this[method](path + '/' + arg.src);
      }
    }
  }
};

function getBreakedText(text) {
  if (!text)
    return;
  let t = text.split('\n'),
    arr = [];
  arr.push(t[0]);
  for (let i = 1; i < t.length; ++i) {
    arr.push((<br />));
    arr.push(t[i]);
  }
  return arr;
}

export default function App() {
  const [initializing, setInitializing] = useState(true);
  const [alert, setAlert] = useState({ open: false, title: 'Alert' });
  const [confirm, setConfirm] = useState({ open: false, title: 'Confirm' });
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    window.error = setShowError;
    return () => delete window.error;
  }, []);


  useEffect(() => {
    let version = Consts.APP_VERSION;
    resourceLoader.loadResources(
      { src: ['js/ext.js?' + version, 'js/fabricOverrides.js?' + version], type: 'js' },
      { src: 'js/ws.js?' + version, type: 'js' },
      { src: 'resources/fonts-css/user.css', type: 'css' }
    ).then(async function () {
      await window.global.Designer.app.init();
      setInitializing(false);
      let lozadObserver = window.global.lozad('.lozad', {
        load: function (el) {
          console.log('lozad loading el');
        }
      });
      window.global.lozadObserver = lozadObserver;
      lozadObserver.observe();
      var Msg = window.global.Ext.Msg;
      Msg.alert = function (a, b) {
        setAlert({
          open: true,
          title: a,
          msg: b
        });
      };
      Msg.confirm = function (title, msg, fn, scope) {
        setConfirm({
          open: true,
          title: title,
          msg: msg,
          callback: fn.bind(scope),
        });
      }
    });
  }, []);

  const dialogContent = {
    title: "Error",
    message: "Request failed, Please try again"
  }

  if (initializing)
    return <div>Loading</div>;
  return <Fragment>
    <Header />
    <Loading />
    {
      showError && <MessageDialog {...dialogContent}
        onCancel={() => setShowError(false)}
        isCancel closeText="Close"
        width={Consts.MESSAAGE_DIALOG_WIDTH} height={Consts.MESSAAGE_DIALOG_WIDTH}
      />
    }
    <Switch>
      <Route exact path="/sepstudio-ui/sign-up" component={SignUp} />
      <Route exact path="/sepstudio-ui/sign-in" render={(routeProps) =>
        Utils.isUserLoggedIn() ? <Redirect to="/sepstudio-ui" /> : <SignIn {...routeProps} />
      } />
      <Route exact path="/sepstudio-ui/forgot-password" render={e => <SignUp isForgotPassword={true} />} />
      <Route path="/sepstudio-ui" render={(routeProps) =>
        Utils.isUserLoggedIn() ? <Dashboard {...routeProps} /> : <Redirect to={{
          pathname: "/sepstudio-ui/sign-in",
          state: {
            from: routeProps.location.pathname
          }
        }} />
      } />
    </Switch>
    {alert.open &&
      <Dialog
        open={true} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
          <span>{getBreakedText(alert.title)}</span>
        </DialogTitle>
        <DialogContent>
          <span className="editor-text">
            {getBreakedText(alert.msg)}
          </span>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={() => {
            setAlert({ open: false, title: 'Alert' });
          }} autoFocus>OK</PrimaryButton>
        </DialogActions>
      </Dialog>
    }
    {confirm.open &&
      <Dialog
        open={true} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
          <span>{getBreakedText(confirm.title)}</span>
        </DialogTitle>
        <DialogContent>
          <span className="editor-text">
            {getBreakedText(confirm.msg)}
          </span>
        </DialogContent>
        <DialogActions>
          <PrimaryButton negative onClick={() => {
            var con = confirm;
            con.callback.call(con.scope, "cancel");
            setConfirm({ open: false });
          }}>Cancel</PrimaryButton>
          <PrimaryButton onClick={() => {
            var con = confirm;
            con.callback.call(con.scope, "ok");
            setConfirm({ open: false });
          }} positive>OK</PrimaryButton>
        </DialogActions>
      </Dialog>
    }
  </Fragment>;
}