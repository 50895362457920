import React from 'react';
import classes from "./NeedHelp.module.css";

const NeedHelp = () => {
    return (
        <a className={classes.a}
            href="https://solutionsforscreenprinters.com/freehand-fanatical-support/"
            target="_blank"
        >
            Need help?
        </a>
    )
}

export default NeedHelp