import React, { useEffect, useState } from "react";
import NoColor from '../../../resources/images/NoColor.png';
import lockIcon from '../../../resources/images/locked.svg';
import Mesh from '../../../resources/images/mesh.svg';
import Utils from "../../../Utils";
import classes from './ColorGrid.module.css';


const metaKeyPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
const selectKey = metaKeyPlatforms.indexOf(window.navigator.platform) !== -1 ? 'metaKey' : 'ctrlKey'

export function Tile({ color, draggable, onDragStart, selected, onDrop, onDragEnd, index }) {
	const [render, rerender] = useState(false);
	useEffect(() => {
		color.onColorChange = e => rerender(a => !a);
	}, [color])

	function getBackground(color) {
		let colorVal = color.getColor();
		if (color.fabricColor?._source[3] === 0) {
			return `url(${NoColor}) 0% 0% / cover`;
		}
		return colorVal;
	}

	function getTileStyle(color) {
		let value = getBackground(color);
		if (color.getBaseType() === 'stroke' && (color.target.type === 'shaped-text' || color.target.type === 'curved-text')) {
			return {
				border: '8px solid ' + value,
				boxSizing: 'border-box'
			};
		}
		return {
			background: value
		};
	}

	return <span className={Utils.getClasses("color-item-thumb-wrap", selected && "x-item-selected")}>
		<span draggable={draggable} onDragOver={e => e.preventDefault()}
			onDragStart={(e) => {
				onDragStart?.(e, color, index);
			}} onDrop={(e) => {
				onDrop?.(e, color, index);
				e.preventDefault();
			}} onDragEnd={(e) => {
				e.preventDefault();
				onDragEnd?.(e, color, index);
			}}
			style={getTileStyle && getTileStyle(color)} title={color.getTooltip()}>
		</span>
		{color.locked && <img src={lockIcon} className="locked" alt="Locked" />}
		{color.background && <img src={Mesh} className="mesh" alt="mesh" />}
	</span>
}

export default function ColorGrid({ onClick, style, itemDraggable, multiSelect, className, onSelectionChange, onItemDragStart, onItemDrop, onItemDropComplete, disableTileSelection, colorlist, selectIndex, list }) {
	const [selection, setSelection] = useState([]);
	useEffect(() => {
		setSelection(selectIndex || []);
	}, [selectIndex]);

	return (
		<div style={{ padding: "5px 10px", ...style }} className={className}>
			{
				colorlist?.map((color, idx) => <div key={idx} className={Utils.getClasses(list ? classes.listView : classes.gridView, list && selection.includes(idx) && classes.listSelected)}
					onClick={e => {
						if (color.onClick) {
							color.onClick(color);
						}
						let curSel = selection;
						if (multiSelect && e[selectKey]) {
							curSel = selection.concat(idx);
						} else {
							curSel = [idx];
						}
						onClick && onClick(color, color?.getColor?.(), curSel);
						setSelection(curSel);
						onSelectionChange && onSelectionChange(curSel, colorlist, e);
					}}>
					<Tile color={color} draggable={itemDraggable ?? !color.locked}
						selected={!disableTileSelection && !color.disableSelection && !list && selection.includes(idx)}
						onDragStart={onItemDragStart}
						index={idx}
						onDrop={onItemDrop}
						onDragEnd={onItemDropComplete}

					/>{list && <>
						<div style={{ color: selection.includes(idx) && 'black' }} className={classes.colorName}>{color.name}</div>
					</>
					}
				</div>
				)
			}
		</div >
	)
};