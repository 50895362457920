import React from 'react';
import Utils from '../../Utils';
import classes from './SearchBar.module.css';
import { useState } from 'react';

function SearchBar({ value, onChange }) {
    const [enabled, setEnabled] = useState(false);

    function searchBarHandler() {
        setEnabled(s => !s);
        onChange('');
    }

    return (
        <div className={Utils.getClasses(classes.searchContainer, enabled && classes.searchContainerEnabled)}>
            {enabled &&
                <input type='text' value={value} onChange={(e) => onChange(e.target.value)} className={classes.input} autoFocus placeholder="Search" />
            }
            <div onClick={searchBarHandler} title='Search'>
                <span className={Utils.getClasses('fa fa-search', classes.searchIcon)} />
            </div>
        </div>
    )
}

export default SearchBar;
