import React, { useEffect, useState } from "react";
import PanelButton from "../../../components/Buttons/PanelButton";
import { useFabricCanvasHandler } from "../FabricCanvas.js";
import classes from "./CopyDeletePanel.module.css";

let Designer,
    Ext,
    fabric,
    undoRedoActions;
const miscAction = {
    events: {
        'selection:created': function () {
            miscAction.updatePanel();
        },
        'selection:updated': function () {
            miscAction.updatePanel();
        },
        'selection:cleared': function () {
            miscAction.updatePanel();
        }
    },
    copyObject: function (object, index, copiedObjects) {
        // fabric object cloning is async.
        var canvasPanelController = Ext.canvasController;

        // Make a copy of the object.
        // We set clipTo to null (and restore later) since it does not round-trip
        // well through JSON - the mechanism used by fabric object.clone
        object.clipTo = null;

        return new Promise(function (resolve) {
            object.clone(function (copy) {
                // Check if the new object can be placed at an offset of 5,5
                var allow = canvasPanelController.allowObjectMove(copy, object.left + 5, object.top + 5);
                if (allow) {
                    copy.set('top', object.top + 5);
                    copy.set('left', object.left + 5);
                }

                copiedObjects[index] = copy;

                resolve(copy);
            }, [
                // Additionally including these properties.
                'id',
                'selectable',
                'perPixelTargetFind',
                'targetFindTolerance',
                'objectCaching',
                'sourceType',
                'skipDrawing',
                'origWidth',
                'origHeight',
                'uniColor',
                'startScaleX',
                'startScaleY'
            ]);
        });
    },

    updatePanel: function () {
        var allowCopyDelete = {
            canCopy: false,
            canDelete: false
        };

        allowCopyDelete = Ext.canvasController.allowCopyDelete();

        // Enable/disable the buttons.
        this.updateState({
            copy: {
                disabled: !allowCopyDelete.canCopy
            },
            remove: {
                disabled: !allowCopyDelete.canDelete
            }
        });
    },

    onDelete: function () {
        Ext.canvasController.deleteActiveObjects();
    },

    onCopyButtonClick: function (button, e, eOpts) {
        var canvas = Designer.AppData.getCanvas();
        var copyObjects = canvas.getActiveObjects();
        if (copyObjects.length === 0) {
            // Nothing to copy.
            return;
        }

        // Since fabric.js object cloning is async, we need to use promises here.
        var copyPromises = [];
        var copiedObjects;

        undoRedoActions.mute(); // Mute undo/redo handling. We notify once at end.

        canvas.discardActiveObject();
        copiedObjects = new Array(copyObjects.length);

        var me = this;
        copyObjects.forEach(function (object, index) {
            var promise = me.copyObject(object, index, copiedObjects); // Initiate copy
            copyPromises.push(promise.then(copiedObject => {
                if (!copiedObject) {
                    return;
                }
                let parent = object.getParent();
                if (canvas === parent) {
                    parent.insertAt(copiedObject, canvas._objects.indexOf(object) + 1);
                } else {
                    parent.add(copiedObject);
                }
            }));
        });

        Promise.all(copyPromises).then(function () {
            var numObjects = copiedObjects.length;

            var newObject;
            if (numObjects === 1) {
                // Set the copied object as active.
                canvas.setActiveObject(copiedObjects[0]);
                newObject = copiedObjects[0];
            } else {
                // Create a selection group for the copied objects.
                var selectionGroup = new fabric.ActiveSelection(copiedObjects, {
                    canvas: canvas
                });
                canvas.setActiveObject(selectionGroup);
                newObject = selectionGroup;
            }

            undoRedoActions.unmute();
            canvas.fire('object:added', { target: newObject });

            canvas.renderAll();
        },
            function () {
                undoRedoActions.unmute();
            });
    },
    onDeleteButtonClick: function (button, e, eOpts) {
        this.onDelete();
    },
    init: function (stateInterface) {
        this.updateState = stateInterface.updateState;
    }
};

export default function CopyDeletePanel() {
    const [canvas] = useFabricCanvasHandler(miscAction.events);
    const [copyState, setCopyState] = useState({});
    const [removeState, setRemoveState] = useState({});

    useEffect(() => {
        Designer = window.global.Designer;
        Ext = window.global.Ext;
        undoRedoActions = Ext.undoRedoContoller;
        fabric = window.fabric;
        miscAction.init({
            updateState: function (obj) {
                if (obj.copy)
                    setCopyState(obj.copy);
                if (obj.remove)
                    setRemoveState(obj.remove);
            }
        });
    }, []);

    useEffect(() => {
        canvas && miscAction.updatePanel();
    }, [canvas])

    return (<div className={classes.panelButtonWrapper}>
        <PanelButton state={copyState} onClick={() => {
            miscAction.onCopyButtonClick();
        }} className="fa fa-clone" title="Duplicate" />
        <PanelButton state={removeState} onClick={() => {
            miscAction.onDeleteButtonClick();
        }} className="fa fa-trash" title="Delete" />
    </div>);
}