import React, { useState, useEffect, useRef } from "react";
import FloatMenu from "../../../../../components/FloatMenu/FloatMenu.js";
import ColorGrid from "../../ColorGrid.js";
import styles from '../TextView.module.css';
import Utils from "../../../../../Utils.js";
import plusIcon from '../../../../../assets/plus-icon.svg';


export default function ColorSelector({ color, sepName, separationColors, openSepDialog, onColorChange, className, style, showMenu = true, open }) {

    const colorRef = useRef();

    useEffect(() => {
        !showMenu && setMenuPosition(false);
    }, [showMenu])
    const [menuPosition, setMenuPosition] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (menuPosition && colorRef.current && !colorRef.current.contains(e.target)) {
                setMenuPosition(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [menuPosition]);

    const getMenuHeight = () => {
        // There can be only 8 colors in one row of floating menu.
        if (separationColors.length <= 8) {
            // When there are less than 9 colors presents, only 1 row will exists.
            return 62;
        } else if (separationColors.length <= 16) {
            // When there are more than 8 and less than 17 colors presents, 2 rows will exists.
            return 104;
        } else {
            // When there are more than 16 colors presents, 2 rows and scrollbar will exists.
            return 108;
        }
    }

    return <div className={Utils.getClasses(styles.colorSelector, className)} style={{ ...style, background: color }} onClick={e => {
        if (menuPosition) {
            setMenuPosition(false);
        } else {
            // We are positioning floating menu at bottom center of color selector but
            // when floating menu height increases window height we are 
            // positioning floating menu at top center of color selector.
            let rect = e.currentTarget.getBoundingClientRect();
            const left = rect.x + (rect.width / 2);
            let top = rect.y + rect.height;
            const menuHeight = getMenuHeight();
            const windowHeight = window.innerHeight;
            if ((top + menuHeight) > windowHeight) {
                top = rect.y - menuHeight;
            };
            setMenuPosition({
                left,
                top
            })
        }
    }}>
        {
            showMenu && menuPosition && <FloatMenu className={styles.menu} position={menuPosition}
                style={{ display: "flex", alignItems: "center" }} menuRef={colorRef}
            >
                <ColorGrid
                    selectIndex={[separationColors.findIndex(c => {
                        return c.name === sepName;
                    })]}
                    colorlist={separationColors}
                    onSelectionChange={(idx, list, e) => {
                        onColorChange?.(list[idx]);
                        e.stopPropagation();
                    }}
                    style={{
                        maxWidth: '22rem',
                        maxHeight: '5.5rem',
                        overflowY: 'auto',
                        padding: 0
                    }}
                    itemDraggable={false}
                />
                <span className="color-item-thumb-wrap" style={{ marginLeft: '12px' }} onClick={() => openSepDialog(true)}>
                    <span title="Add Ink to Palette" style={{
                        backgroundImage: `url(${plusIcon})`,
                        backgroundSize: '50%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        borderColor: 'var(--text-light-color)'
                    }}></span>
                </span>
            </FloatMenu>
        }
    </div>
}