import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

const StyledTextField = styled(TextField)`
  /* default */
  .MuiInput-underline:before {
    border-bottom: 2px solid rgb(205, 206, 208);
  }
  /* hover (double-ampersand needed for specificity reasons. */
  && .MuiInput-underline:hover:before {
    border-bottom: 2px solid rgb(205, 206, 208);
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: 2px solid rgb(70, 158, 246);
  }
  .MuiInput-input {
    color: rgb(205, 206, 208);
  }
  .MuiInputLabel-root {
    color: rgb(205, 206, 208);
  }
`;

export default StyledTextField;