import axios from '../../../axios';
import { notificationsActions } from './NotificationsSlice';

export function getAllNotifications(dispatch) {
    return axios({
        url: `/sepstudio/notification`,
        method: "get",
        responseType: "json",
    }).then((response) => {
        if (response?.data?.success) {
            const notifications = response.data.notifications;
            dispatch(notificationsActions.fetchNotifications({ notifications: notifications || [] }));
        }
    });
}

export function removeNotification(dispatch, id) {
    const data = { id };
    return axios({
        url: `/sepstudio/notification`,
        method: "delete",
        responseType: "json",
        data: data
    }).then((response) => {
        if (response?.data?.success) {
            dispatch(notificationsActions.removeNotification({ id: id || "" }));
        }
    });
}

export function removeAllNotifications(dispatch) {
    return axios({
        url: `/sepstudio/notification`,
        method: "delete",
        responseType: "json",
    }).then((response) => {
        if (response?.data?.success) {
            dispatch(notificationsActions.removeAllNotifications());
        }
    });
}