import React from 'react';

function NumberIcon({ style, translate, onClick, fill = "blue", number, color = "white" }) {
  return <svg width={"25px"} height={"25px"}
    style={{
      ...style,
      ...(translate && { transform: 'translate(-13px, -13px)' })
    }}
    onClick={onClick}
  >
    <g>
      <circle style={{ fill: fill }} cx={"13px"} cy={"13px"} r={"11px"}>
      </circle>
      <text x={"50%"} y={"65%"} textAnchor={"middle"} fill={color} fontSize={"70%"} strokeWidth={"1px"}>{number}</text>
    </g>
  </svg>
}

export default NumberIcon;