const Utils = {

    getLocalTimezoneOffset: () => {
        // Get local timezone offset from UTC.
        return new Date().getTimezoneOffset();
    },

    getDateString: (date, fullDateString) => {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getYear() + 1900;
        let hh = date.getHours();
        let min = date.getMinutes();
        let ss = date.getSeconds();

        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        if (hh < 10) {
            hh = "0" + hh;
        }
        if (min < 10) {
            min = "0" + min;
        }
        if (ss < 10) {
            ss = "0" + ss;
        }
        if (fullDateString) {
            return dd + "_" + mm + "_" + yyyy + "_" + hh + "_" + min + "_" + ss;
        }

        return dd + "-" + mm + "-" + yyyy + " " + hh + ":" + min;
    },

    getLocalDate: (serverDate) => {
        // Get the server date as local formatted date.
        let localDate = new Date(serverDate?.["$date"] || serverDate);
        return Utils.getDateString(localDate);
    },

    timeWithOffset: (timeInMinutes, offset) => {
        timeInMinutes = parseInt(timeInMinutes);
        offset = parseInt(offset);
        timeInMinutes += offset;
        if (timeInMinutes < 0) {
            timeInMinutes += 1440;
        }
        timeInMinutes %= 1440;

        return timeInMinutes;
    },

    convertTimeInMinutesToHHMM: (timeInMinutes) => {
        if (!timeInMinutes) return "00:00";

        let hours = parseInt(timeInMinutes / 60);
        let minutes = parseInt(timeInMinutes % 60);

        if (hours < 10) {
            hours = "0" + hours;
        }

        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        const timeInHHMM = hours + ":" + minutes;

        return timeInHHMM;
    },

    convertTimeInHHMMToMinutes: (timeInHHMM) => {
        if (!timeInHHMM || !timeInHHMM.includes(':')) {
            return "";
        }
        const array = timeInHHMM.split(':');
        let hours = array[0];
        let minutes = array[1];
        hours = parseInt(hours);
        minutes = parseInt(minutes);

        const timeInMinutes = (hours * 60) + minutes;
        return timeInMinutes;
    },

    getLocalTime: (timeInMinutes) => {
        let offset = Utils.getLocalTimezoneOffset();
        const time = Utils.timeWithOffset(timeInMinutes, -offset);

        return Utils.convertTimeInMinutesToHHMM(time);
    },

    getUTCTime: (timeInHHMM) => {
        let timeInMinutes = Utils.convertTimeInHHMMToMinutes(timeInHHMM);
        let offset = Utils.getLocalTimezoneOffset();
        return Utils.timeWithOffset(timeInMinutes, offset);
    },

    validateEmail: (email) => {
        let obj = {
            valid: true,
            message: ""
        };

        let validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email.match(validEmail)) {
            obj.valid = false;
            obj.message = "Your email is not valid";
            return obj;
        }
        return obj;
    },

    timeSince: (date) => {

        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " mins";
        }
        return "Just now";
        // return Math.floor(seconds) + " seconds";
    },

    getTimeSince: (date) => {
        let ans = Utils.timeSince(date);
        if (ans.includes("now")) {
            return ans;
        }
        let array = ans.split(' ');
        if (Number(array[0]) === 1) {
            ans = ans.substring(0, ans.length - 1);
        }
        return ans + ' ago';
    },

    getClasses: (...cls) => {
        return cls.filter(i => i).join(' ');
    },

    dataURItoBlob: (dataURI) => {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    },

    isUserLoggedIn: () => {
        const token = localStorage.getItem("isLoggedIn");
        if (!token || Utils.getUserRole() === 'admin') {
            return false;
        }
        return true;
    },

    getUserId: (user) => {
        if (!user) {
            user = Utils.getCurrentUser();
        }
        if (!user) {
            return "";
        }
        return user.userId || "";
    },

    getUserFullName: (user) => {
        if (!user) {
            user = Utils.getCurrentUser();
        }
        if (!user) {
            return "";
        }
        const firstName = user.firstName ? user.firstName : "";
        const lastName = user.lastName ? user.lastName : "";
        const fullName = firstName + " " + lastName;
        return fullName.trim();
    },

    getUserRole: (user) => {
        if (!user || !(user = Utils.getCurrentUser())) {
            return "";
        }
        return user.role || "";
    },

    getFullNameByUser: (user) => {
        const firstName = user.firstName ? user.firstName : "";
        const lastName = user.lastName ? user.lastName : "";
        let fullName = firstName + " " + lastName;
        fullName = fullName.trim();
        if (!fullName) {
            fullName = user.userId || user;
        }
        return fullName;
    },

    getCurrentUser: () => {
        return Utils.currentUser || {};
    },

    setCurrentUser: (user) => {
        Utils.currentUser = user;
    },

    isCurrentUser: (user) => {
        user = user?.userId || user;
        return user === Utils.getUserId();
    },

    isUserActive: () => {
        const user = Utils.getCurrentUser();
        if (user?.isActive) {
            return user.isActive;
        }
        return false;
    },

    replaceColorInFabricObjects: (canvas, oldColorWrapper, newColorWrapper) => {
        let objs = [];
        canvas.enumerateAllObjects(o => {
            if (o.type === 'group') {
                o.dirty = true;
                return true;
            }
            let isChanged = false;
            if (o.sepName === oldColorWrapper.name) {
                o.fill = newColorWrapper.getColor();
                o.sepName = newColorWrapper.name;
                o.dirty = true;
                isChanged = true;
            }
            let extraStrokes = o.extraStrokes;
            if (extraStrokes) {
                extraStrokes.forEach(s => {
                    if (s.sepName === oldColorWrapper.name) {
                        s.stroke = newColorWrapper.getColor();
                        s.sepName = newColorWrapper.name;
                        o.dirty = true;
                        isChanged = true;
                    }
                });
            }
            isChanged && objs.push(o);
            return true;
        }, true, true);
        canvas.renderAll();
        return objs;
    },

    convertUnitsToMM: (value, unit) => {
        if (unit === "mm") {
            return value;
        } else {
            return value * 25.4;
        }
    },

    convertMMToUnits: (value, unit) => {
        if (unit === "mm") {
            return value;
        } else {
            return value / 25.4;
        }
    },
    readAsText: function(file) {
        return new Promise(function(resolve, reject) {
            let reader = new FileReader();
            reader.onload = function() {
                resolve(reader.result);
            };
            reader.onerror = function(e) {
                reject('failed to load file');
            };
            reader.readAsText(file);
        });
    },
    getBase64: function(file) {
        return new Promise(function(resolve, reject) {
            let reader = new FileReader();
            reader.onload = function() {
                resolve(reader.result);
            };
            reader.onerror = function(e) {
                reject('failed to load file');
            };
            reader.readAsDataURL(file);
        });
    }
};

export default Utils;