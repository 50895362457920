import React, { useState } from 'react';
import NumberIcon from '../../components/NumberIcon/NumberIcon';
import ToggleIcon from '../../components/ToggleIcon/ToggleIcon';
import pinDown from '../../assets/pin-down.svg';
import pinned from '../../assets/pinned.svg';
import classes from './AnnotationData.module.css';
import Utils from '../../Utils';
import { addLocation } from '../AnnotationsActions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

function AnnotationData(props) {

    const annotation = props.annotation;
    const pinObject = props.pinObject;
    const dispatch = useDispatch();
    const [enabled, setEnabled] = useState(annotation.closed);

    useEffect(() => {
        setEnabled(annotation.closed);
    }, [annotation]);

    function addPinHandler(e) {
        e.stopPropagation();
        props.addPinHandler(props.id);
    }

    function onThreadClose(e) {
        e.stopPropagation();
        addLocation(dispatch, {
            id: annotation.id,
            closed: !enabled
        }).then(res => {
            if (res.data.success) {
                setEnabled(state => !state);
            }
        })
    }

    function getIcon() {
        if (Number.isFinite(props.pinNumber)) {
            return <NumberIcon number={props.pinNumber + 1} fill={annotation.closed ? "green" : undefined} />
        } else if (Utils.isCurrentUser(annotation.user) && !annotation.closed && !props.isReadOnly) {
            const src = isActivePinned() ? pinned : pinDown;
            return <img src={src} width={20} alt="PinIcon"
                onClick={addPinHandler} draggable={!props.isReadOnly}
                onDragStart={e => e.dataTransfer.setData("id", annotation.id)}
            />
        } else {
            return '';
        }
    }

    function isActivePinned() {
        if (pinObject.existingThread.isPinned === true && props.id === props.activeChatId) {
            return true;
        }
        return false;
    }

    function getComment() {
        if (annotation && Object.keys(annotation).length && annotation.hasOwnProperty('comments') &&
            annotation.comments.length) {
            return annotation.comments[0].text;
        }
        return '';
    }
    const comments = annotation.comments
    const time = comments[comments.length - 1].time;
    let localDate = new Date(time?.["$date"] || time);

    return (<>
        <div className={classes.main}>
            <div className={classes.header}
                onClick={props.onClick}
            >
                <div className={classes.headerLeft}>
                    {getIcon()}
                </div>
                <div className={classes.headerMiddle}>
                    <p> {Utils.getFullNameByUser(annotation.user)} </p>
                    <p className={classes.firstComment}> {getComment()} </p>
                </div>
                <div className={classes.headerRight}>
                    <span> {Utils.getTimeSince(localDate)} </span>
                    {
                        !props.isReadOnly && !annotation.system &&
                        <ToggleIcon image="fa fa-flag" enabled={enabled} style={{ fontSize: '1rem', color: 'grey' }} onClick={onThreadClose} tooltip={{ normal: 'Mark as complete', down: 'Reopen' }} colorNormal={'#ef6c00'} />
                    }
                </div>
            </div>
        </div>
    </>
    )
}

export default AnnotationData;