import React, { useEffect, useState } from "react";
import { CategorySelect } from './CategoryDialog';
import ManageArts from './ManageArts';
import { useSelector, useDispatch } from 'react-redux';
import { /*addPatternLibrary, removePatternLibrary,*/ updatePattern } from "../EditorAction.js";
import { useFabricCanvasHandler } from "../FabricCanvas";
import { Slider, BiEndSlider } from '../../../components/Sliders/Sliders'
import Utils from "../../../Utils";
import LinkButton from '../../../components/Buttons/LinkButton';
import classes from './PatternView.module.css';


let Designer,
	Ext,
	fabric;

const action = {
	getSelectedColorableObjects: function () {
		let canvas = Designer?.AppData?.getCanvas(),
			selectedObjects = [],
			me = this;
		canvas?.getObjects().forEach(function (o) {
			if (me.isColorableObject(o))
				selectedObjects.push(o);
		});

		return selectedObjects;
	},
	isColorableObject: function (object) {
		return object.get('id') === 'overlay';
	},
	canApplyPattern: function () {
		let objects = this.getSelectedColorableObjects(),
			canApply = (objects.length === 1);

		return canApply;
	},
	getNumber: function (num) {
		num = Number(num);
		return Number(num.toFixed(2));
	},
	onItemClick: function (item) {
		let canvas = Designer.AppData.getCanvas(),
			obj = this.getSelectedColorableObjects()[0];
		if (obj?.filePath === item.filePath) {
			return;
		}
		if (obj) {
			Ext.undoRedoContoller.mute();
			canvas.remove(obj);
			Ext.undoRedoContoller.unmute();
		}
		let pColor = Designer?.AppData?.productModelData?.backgroundColor;
		var canvasPanelController = Ext.canvasController,
			me = this;
		window.loading(true);
		fabric.Overlay.fromURL(item.hiresThumbPath, function (img) {
			var bounds = canvasPanelController.getProductBounds();

			var position = {
				x: bounds.left,
				y: bounds.top
			};

			var scale;
			var scaleX = bounds.width / img.width;
			var scaleY = bounds.height / img.height;

			// Compute the image size preserving the aspect ratio.
			if (scaleX > scaleY) {
				scale = scaleX;
				position.y += (bounds.height - scale * img.height) / 2;
			} else {
				scale = scaleY;
				position.x += (bounds.width - scale * img.width) / 2;
			}

			img.set({
				id: 'overlay',
				left: position.x,
				top: position.y,
				selectable: false,
				evented: false,
				filePath: item.filePath,
				thumbPath: item.thumbPath
			}).scale(scale);

			img.setColor(pColor);

			delete me.fillScaling;

			canvas.add(img);
			canvas.renderAll();
			window.loading(false);
		});
	},
	setPatternAttribute: function (attrs) {
		let obj = this.getSelectedColorableObjects()[0];
		if (!obj)
			return;
		let scaling = this.getFillScaling(obj),
			opt = {},
			canvas = Designer.AppData.getCanvas(),
			data = Ext.canvasController.productSizeData;
		if (attrs.scaleX !== undefined) {
			opt.scaleX = attrs.scaleX * scaling;
			opt.scaleY = opt.scaleX;
			opt.left = (data.widthMM - obj.width * opt.scaleX) / 2 + obj.trX;
			opt.top = (data.heightMM - obj.height * opt.scaleY) / 2 + obj.trY;
		}
		if (attrs.translateX !== undefined) {
			obj.trX = attrs.translateX;
			opt.left = (data.widthMM - obj.width * obj.scaleX) / 2 + obj.trX;
		}
		if (attrs.translateY !== undefined) {
			obj.trY = attrs.translateY;
			opt.top = (data.heightMM - obj.height * obj.scaleY) / 2 + obj.trY;
		}
		obj.set(opt);
		canvas.renderAll();
	},
	init: function (controller) {
		this.updateState = controller?.updateState;
	},
	updatePanel: function () {
		let obj = this.getSelectedColorableObjects()[0],
			state = {
				offsetX: { value: 0, disabled: true },
				offsetY: { value: 0, disabled: true },
				scale: { value: 1, disabled: true },
			};
		if (obj) {
			let scaling = this.getFillScaling(obj);
			state.offsetX.value = this.getNumber(obj.trX);
			state.offsetX.disabled = false;
			state.offsetY.value = this.getNumber(obj.trY);
			state.offsetY.disabled = false;
			state.scale.value = this.getNumber(obj.scaleX / scaling);
			state.scale.disabled = false;
		}
		this.updateState(state);
	},
	events: {
		'object:added': function () {
			action.updatePanel();
		},
		'object:removed': function () {
			action.updatePanel();
		},
		'background:changed': function (color) {
			let obj = action.getSelectedColorableObjects()[0];
			if (obj?.setColor) {
				obj?.setColor(color);
				obj.dirty = true;
			}
		},
		'product-size:changed': function (size) {
			var colorObject = action.getSelectedColorableObjects()[0];
			if (colorObject) {
				let rx = size.widthMM / colorObject.width,
					ry = size.heightMM / colorObject.height,
					left = size.leftMarginMM,
					top = size.topMarginMM,
					rr;
				if (rx > ry) {
					rr = rx;
					top += (size.heightMM - rr * colorObject.height) / 2;
				} else {
					rr = ry;
					left += (size.widthMM - rr * colorObject.width) / 2;
				}

				colorObject.set({
					scaleX: rr,
					scaleY: rr,
					left: left,
					top: top
				});
			}
		}
	},
	getFillScaling: function (img) {
		if (this.fillScaling)
			return this.fillScaling;
		var canvasPanelController = Ext.canvasController,
			bounds = canvasPanelController.getProductBounds();
		var scaleX = bounds.width / img.width,
			scaleY = bounds.height / img.height,
			scale = (scaleX > scaleY) ? scaleX : scaleY;
		this.fillScaling = scale;
		return scale;
	},
	clearTexture: function () {
		let obj = this.getSelectedColorableObjects()[0];
		if (obj) {
			obj.canvas.remove(obj);
		}
	}
}

const uploadAction = {
	getFabricImage: function (file) {
		return new Promise(function (resolve, reject) {
			Utils.getBase64(file).then(function (url) {
				fabric.Image.fromURL(url, function (img, isError) {
					if (isError)
						reject("");
					resolve(img);
				})
			});
		});
	},
	validate: async function (files) {
		let validFiles = [],
			filter = new fabric.Image.filters.PatternColor({
				color: "#000",
				convert: true
			});
		for (let i = 0; i < files.length; i++) {
			try {
				let file = files[i],
					img = await this.getFabricImage(file);
				img.filters.push(filter);
				img.applyFilters();
				let blob = await (await fetch(img.getElement().toDataURL())).blob();
				validFiles.push(blob);
			} catch (e) {
			}
		}
		return validFiles;
	}
};

function NumberInput(props) {
	const { Component, label } = props;
	return <>
		<label className="editor-text" style={{ marginBottom: 0 }}>{label}</label>
		<div style={{ margin: '0 10px' }}>
			<Component {...props} />
		</div>
	</>
}

function getPatternLocation(pattern, data) {
	if (!pattern || !data?.libraries) {
		return 'None';
	}
	if (pattern.location) {
		return pattern.location;
	}

	for (let lib of data.libraries) {
		for (let item of lib.items) {
			if (item.filePath === pattern.filePath) {
				pattern.location = `${lib.name} > ${item.title}`;
				return pattern.location;
			}
		}
	}
	pattern.location = 'Not found';
	return pattern.location;
}

export default function PatternWidget({ visible }) {
	const patternData = useSelector(st => st.patterns);
	const labelStyle = {
		margin: '0.5rem 10px',
		height: '1rem'
	};
	const [manageDialog, setManageDialog] = useState({});
	const [selectedValue, setSelectedValue] = useState(0);
	const [scale, setScale] = useState({ value: 1, disabled: true });
	const [offsetX, setOffsetX] = useState({ value: 0, disabled: true });
	const [offsetY, setOffsetY] = useState({ value: 0, disabled: true });

	const [canvas] = useFabricCanvasHandler(action.events);

	const dispatch = useDispatch();
	const categories = patternData?.libraries.map((i, idx) => ({
		...i,
		label: i.name,
		value: idx
	})) || [];

	useEffect(() => {
		Designer = window.global.Designer;
		Ext = window.global.Ext;
		fabric = window.global.fabric;

		action.init({
			updateState: function (obj = {}) {
				setScale(a => ({ ...a, ...obj.scale }));
				setOffsetX(a => ({ ...a, ...obj.offsetX }));
				setOffsetY(a => ({ ...a, ...obj.offsetY }));
			}
		});
		Ext.patternPanelController = action;
	}, [])

	const selectedOption = categories[selectedValue] || [];
	const productSizeData = Ext?.canvasController.productSizeData || {};
	const patterObj = action.getSelectedColorableObjects()[0];
	const textureLocation = getPatternLocation(patterObj, patternData);

	return <div style={{ display: visible ? 'block' : 'none', height: "100%" }}>
		<CategorySelect categories={categories} value={selectedValue}
			style={{ padding: "0.6rem" }}
			/*addLibrary={name => {
				addPatternLibrary(dispatch, name, patternData).then(e => {
					setSelectedValue(categories.length);
				})
			}}*/
			categoryKey="name"
			/*removeLibrary={val => {
				removePatternLibrary(dispatch, categories[val], patternData).then(e => {
					let size = categories.length - 1;
					if (val >= size) {
						setSelectedValue(0);
					}
				});
			}}*/
			onChange={(e, idx) => setSelectedValue(idx)}
		/>
		{
			selectedOption && !selectedOption.system &&
			<div className="editor-text" style={labelStyle}>
				<LinkButton onClick={() => setManageDialog({ open: true })}
					style={{ marginLeft: '0.75rem', fontSize: '14px' }} label="Manage Textures" />

			</div>
		}
		<div style={{ height: `calc(100% - ${selectedOption.system ? "21" : "23"}rem)`, marginBottom: 10 }} className="scroll-vertical">
			<div>
				{
					selectedOption?.items?.map((item) => {
						return <div key={item.name} title={item.title} className={Utils.getClasses('image-item-thumb-wrap texture', patterObj?.filePath === item.filePath && 'x-item-selected')} style={{ margin: 10 }} onClick={() => action.onItemClick(item)}>
							<img className="image-item-thumb lozad" src={item.thumbPath} alt="Thumb"></img>
						</div>;
					})
				}
			</div>
		</div>
		<div className={classes.textureContainer}>
			<span>Texture:</span>
			<span className={classes.textureName} title={textureLocation}>
				{textureLocation}
			</span>
			<LinkButton style={{ marginLeft: 10, fontSize: '14px' }} onClick={e => action.clearTexture()} label="Clear" disabled={!patterObj} /> </div>
		<div className="scroll-vertical" style={{ marginTop: 5 }}>
			<div style={{ padding: "0.6rem" }}>
				<div className={scale.disabled ? 'x-item-disabled' : ''} style={{ marginBottom: 5 }}>
					<NumberInput min={1} max={5} step={0.01}
						label="Scaling"
						onChange={(ev, val) => {
							setScale(a => ({ ...a, value: val }));
							action.setPatternAttribute({ scaleX: val, scaleY: val });
						}}
						onChangeCommitted={e => {
							canvas.fire('object:modified', { target: action.getSelectedColorableObjects() });
						}}
						value={scale.value || 1}
						Component={Slider}
					/>
				</div>
				<div className={offsetX.disabled ? 'x-item-disabled' : ''} style={{ marginBottom: 5 }}>
					<NumberInput
						min={-100}
						max={100}
						label="Offset X"
						onChange={(e, ev) => {
							ev = productSizeData.widthMM * ev / 100;
							setOffsetX(a => ({ ...a, value: ev }));
							action.setPatternAttribute({ translateX: ev });
						}}
						onChangeCommitted={e => {
							canvas.fire('object:modified', { target: action.getSelectedColorableObjects() });
						}}
						value={Math.round(offsetX.value / productSizeData.widthMM * 100) || 0}
						Component={BiEndSlider}
					/>
				</div>
				<div className={offsetY.disabled ? 'x-item-disabled' : ''} style={{ marginBottom: 5 }}>
					<NumberInput
						min={-100}
						max={100}
						label="Offset Y"
						onChange={(e, ev) => {
							ev = productSizeData.heightMM * ev / 100;
							setOffsetY(a => ({ ...a, value: ev }));
							action.setPatternAttribute({ translateY: ev });
						}}
						onChangeCommitted={e => {
							canvas.fire('object:modified', { target: action.getSelectedColorableObjects() });
						}}
						value={Math.round(offsetY.value / productSizeData.heightMM * 100) || 0}
						Component={BiEndSlider}
					/>
				</div>
			</div>
		</div>
		{manageDialog.open ?
			<ManageArts data={selectedOption}
				title="Manage Textures"
				accept="image/png"
				validate={uploadAction.validate.bind(uploadAction)}
				open={true} onClose={() => {
					setManageDialog({ open: false });
				}}
				onSave={(data, files, toDelete) => {
					updatePattern(dispatch, data, files, toDelete, patternData).then(() =>
						setManageDialog({ open: false })
					);
				}}
			/> : ''
		}
	</div>;
};