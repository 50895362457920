import { createSlice } from "@reduxjs/toolkit";
import Consts from "../../Consts";

const initialState = {
  project: {},
  projects: []
};

const projectsSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    fetchAllProjects: (state, action) => {
      state.projects = action.payload.projects;
    },
    fetchProject: (state, action) => {
      state.project = action.payload.project;
    },
    addProject: (state, action) => {
      let data = action.payload.data;
      const index = state.projects.findIndex(i => i.id === data.id);
      if (index < 0) {
        state.projects.unshift(data);
      } else {
        state.projects[index] = data;
      }
      if (state.project?.id === data.id) {
        state.project = data;
      }
    },
    updateProject: (state, action) => {
      const data = action.payload.data;
      const index = state.projects.findIndex((project) => project.id === data.id);
      if (index > -1) {
        state.projects[index] = data;
      }
      if (state.project?.id === data.id) {
        state.project = data;
      }
    },
    deleteProject: (state, action) => {
      const index = state.projects.findIndex((project) => project.id === action.payload.id);
      state.projects.splice(index, 1);
    },
    sendForReview: (state, action) => {
      const index = state.projects.findIndex((project) => project.id === action.payload.projectId);
      const status = action.payload.reviewers.length ? Consts.SENT_FOR_REVEIW : Consts.IN_PROGRESS;
      state.projects[index].status = status;
      state.projects[index].reviewers = action.payload.reviewers;
    },
    updateProjectStatus: (state, action) => {
      const index = state.projects.findIndex((project) => project.id === action.payload.projectId);
      if (index > -1) {
        const currProject = state.projects[index];
        const status = action.payload.status;
        currProject.status = status;
        if (currProject.id === state.project?.id) {
          state.project.status = status;
        }
      }
    }
  },
});

export const projectsActions = projectsSlice.actions;

export default projectsSlice.reducer;