import React from "react";
import "./components.css";

export function ActionPanel({className, style, title, children}) {
	if (!className)
		className = "action-panel";
	else
		className += " action-panel";
	return (
		<div className={className} style={style}>
			<div>
				<div className="x-panel-header-title-default">{title}</div>
				{children}
			</div>
		</div>
	);
}
