import React, { useState, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import StyledTextField from "../../../components/StyledTextField/StyledTextField";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Select from 'react-select';

export default function CategoryDialog({ open, categories, onClose, onSubmit, title, emptyError, alreadyExistError, categoryKey }) {
	categories = categories || [];
	const [errorText, setError] = useState("");
	const [name, setName] = useState("");
	categoryKey = categoryKey || 'name';
	return <Dialog
		open={open} aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description">
		<DialogTitle>
			<span>{title || "Library Name"}</span>
		</DialogTitle>
		<DialogContent>
			<StyledTextField error={errorText ? true : false} helperText={errorText} style={{ width: '25rem' }} label="Name" onChange={(el) => {
				setName(el.target.value);
				if (errorText) {
					setError("");
				}
			}} />
		</DialogContent>
		<DialogActions>
			<PrimaryButton negative onClick={onClose}>
				Cancel
			</PrimaryButton>
			<PrimaryButton disabled={errorText ? true : false} onClick={() => {
				let isEmpty = !name ? true : false;
				let isExists = categories.find(cat => cat[categoryKey].toLowerCase() === name.toLowerCase());
				let newErrorText;
				if (isEmpty) {
					newErrorText = emptyError || "Library name cannot be empty.";
				} else if (isExists) {
					newErrorText = alreadyExistError || "Library name already exists.";
				}
				if (isEmpty || isExists) {
					setError(newErrorText);
				} else {
					onSubmit({
						name: name
					});
					onClose();
				}
			}} autoFocus>OK</PrimaryButton>
		</DialogActions>
	</Dialog>;
}

export function CategorySelect({ onChange, value, categories, addLibrary, removeLibrary, categoryKey = 'categoryName', style, label = 'Library' }) {
	const [open, setOpenCategoryDialog] = useState(false);
	const [selected, setSelected] = useState(value);

	useEffect(() => {
		setSelected(value);
	}, [value]);

	let currentCategory = (categories && (selected || selected === 0) && categories[selected]) || {};
	return <div style={style}>
		<div>
			<label className="editor-text" style={{ fontSize: "17px" }}>{label}</label>
			<div style={{ float: "right" }}>
				{
					addLibrary &&
					<span className="fa fa-plus clipart-category-btn" style={{ marginRight: "5px" }}
						onClick={() => setOpenCategoryDialog(true)} title="Add Library"
					/>
				}
				{
					removeLibrary &&
					<span title="Delete Library" className={`fa fa-trash clipart-category-btn${currentCategory.system ? ' x-item-disabled' : ''}`}
						onClick={e => {
							window.Ext.Msg.confirm("Delete Library", `Delete "${currentCategory.name}" color library? \n Proceed?`, function (answer) {
								if (answer === "ok")
									removeLibrary && removeLibrary(selected);
							});
						}}
					/>
				}
			</div>
		</div>
		<div style={{ color: 'black' }}>
			<Select
				options={categories}
				isOptionDisabled={(option) => option.disabled}
				value={currentCategory}
				onChange={e => {
					setSelected(e.value);
					onChange?.(e, e.value);
				}}
				clearable={true}
				styles={{
					control: styles => ({
						...styles,
						borderRadius: "0px",
						backgroundColor: "rgb(230, 231, 232)"
					})
				}}
			/>
		</div>
		{open ?
			<CategoryDialog open={open} categories={categories}
				onClose={() => setOpenCategoryDialog(false)}
				onSubmit={out => addLibrary & addLibrary(out.name)}
				categoryKey={categoryKey}
			/> : ''
		}
	</div>;
}