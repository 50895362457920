const warpShapes = [];
for (let i = 0; i < 32; ++i) {
    warpShapes.push({
        value: i,
        src: `../resources/textshapesvg/text-shape-${i}.svg`
    });
}
export default function TextShapes({ value, onChange }) {
    return <div className="scroll-vertical" style={{ height: '23rem' }}>
        {
            warpShapes.map(s =>
                <div key={s.value} className={`image-item-thumb-wrap shapeItem${value === s.value ? " x-item-selected" : ''}`}
                    style={{
                        backgroundImage: `url(${s.src})`
                    }}
                    onClick={() => onChange?.(s.value)}
                />
            )
        }
    </div>;
}