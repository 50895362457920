import { configureStore } from '@reduxjs/toolkit';
import annotationsReducer from "./App/modules/AnnotationsSlice";
import projectsReducer from "./App/modules/Projects/ProjectsSlice";
import { foregroundColorsReducer, patternsReducer, backgroundColorsReducer, fontsReducer, clipartReducer } from './App/modules/Editor/EditorSlice';
import notificationsReducer from './App/modules/Notifications/NotificationsSlice';
import usersReducer from './App/modules/Users/UsersSlice';

// Configure our store combining all the state reducers.
export const store = configureStore({
  reducer: {
    annotations: annotationsReducer,
    projects: projectsReducer,
    foregroundColors: foregroundColorsReducer,
    backgroundColors: backgroundColorsReducer,
    patterns: patternsReducer,
    fonts: fontsReducer,
    cliparts: clipartReducer,
    notifications: notificationsReducer,
    user: usersReducer
  }
});
