import React, { useEffect, useState } from "react";
import ArrangeTable from "./ArrangeTable";
import { useFabricCanvas, useFabricCanvasHandler } from "../FabricCanvas";
import Utils from "../../../Utils";
import styles from './LayerPanel.module.css';

function isSelected(obj, canvas) {
    return canvas.getActiveObjects().indexOf(obj) !== -1;
}

function isClipartOrText(item) {
    if (item.name === 'Text' || item.name === 'Clipart') {
        return true;
    }
    return false;
}

var fColor;
function isBlackText(item) {
    if (!fColor) fColor = new window.fabric.Color();
    if (isClipartOrText(item) && item.fill) {
        try {
            fColor._tryParsingColor(item.fill);
            let source = fColor._source;
            source = source[0] * 0.3 + source[1] * 0.59 + source[2] * 0.11;
            return source < 50;
        } catch (e) { }
    }
}
function ObjectEntry({ itemWrapper: { item } }) {
    item = item || {};
    const canvas = useFabricCanvas();
    let itemSelected = isSelected(item, canvas),
        isVisible = Boolean(item.visible),
        isLocked = Boolean(item.isLocked);
    const [render, reRender] = useState(false);

    useEffect(() => {
        item.notifyUpdate = () => {
            reRender(a => !a)
        };
        return () => {
            delete item.notifyUpdate;
        }
    }, [item]);

    let url = "",
        target;
    if (target = item._cacheCanvas) {
        url = target ? target.toDataURL() : ''
    } else if (target = item._element) {
        url = target.src;
    }

    return <React.Fragment>
        <td title={`${isLocked ? 'Click to unlock' : 'Click to lock'}`} className={`fa ${isLocked ? 'fa-lock' : 'fa-unlock'}`}
            onClick={() => {
                item.isLocked = !isLocked;
                item.selectable = isLocked;
                item.evented = isLocked;
                if (itemSelected) {
                    canvas.discardActiveObject();
                }
                canvas.renderAll();
                canvas.fire('object:modified', { target: item });
            }}
        />
        <td title={`${isVisible ? 'Click to hide' : 'Click to show'}`} className={`fa ${isVisible ? 'fa-eye' : 'fa-eye-slash'}`}
            onClick={() => {
                item.visible = !isVisible;
                if (itemSelected) {
                    canvas.discardActiveObject();
                }
                let layer = item.getParentLayer();
                if (layer) {
                    layer.dirty = true;
                }
                canvas.renderAll();
                canvas.fire('object:modified', { target: item });
            }}
        />
        <td>
            <div className={Utils.getClasses(styles['fabric-obj-view'], isBlackText(item) && styles['black'])} style={{
                backgroundImage: `url(${url})`
            }} />
        </td>
        <td style={{ width: 5 }} />
        <td className={styles["fabric-obj-name"]}>
            {(item.name || item.type).toPascalCase()}
        </td>
        <td style={{ position: 'relative' }}>
            <div title="Click to select" className={Utils.getClasses('align-center', styles['selection-indicator'], itemSelected && styles['selected'], (isLocked || !isVisible) && styles['disabled'])}
                onClick={e => {
                    if (canvas._shouldGroup(e, item)) {
                        canvas._handleGrouping(e, item);
                    } else {
                        canvas.setActiveObject(item);
                    }
                    canvas.renderAll();
                }} />
        </td>
    </React.Fragment>;
}

function notify(items) {
    items && items.forEach(i => i && i.notifyUpdate && i.notifyUpdate());
}

function getLayerPanelHandler(canvas, setObjects) {
    let reloadObjects = () => setObjects(canvas.getLayeringTargets().slice());
    return {
        'layerTarget:changed': reloadObjects,
        'object:added': () => setTimeout(reloadObjects, 20),
        'object:removed': reloadObjects,
        'object:modified': e => notify([].concat(e.target)),
        'selection:created': e => notify(e.selected),
        'selection:cleared': e => notify(e.deselected),
        'selection:updated': e => {
            notify(e.selected);
            notify(e.deselected);
        }
    }
}

function LayerPanel() {
    const [objects, setObjects] = useState([]);
    const [canvas, handler] = useFabricCanvasHandler(fc => getLayerPanelHandler(fc, setObjects));

    useEffect(() => {
        if (canvas && handler) {
            handler['layerTarget:changed']?.();
        }
    }, [handler, canvas]);

    return (
        <div className="scroll-vertical" style={{
            height: '24rem',
            width: '18rem',
            fontSize: '1.5rem'
        }}>
            <ArrangeTable data={objects}
                skipItem={(i) => ['background', 'margin', 'overlay'].some(v => v === i.id)}
                className={styles["layer-sequence"]}
                helperClass={styles["object-drag"]}
                component={ObjectEntry}
                handlerComponent={() => <div title="Drag to reorder" className="fa fa-arrows-alt-v" />}
                onSortEnd={(items) => {
                    if (!canvas)
                        return;
                    let objs = canvas.getLayeringTargets();
                    if (canvas.layeringTarget) {
                        canvas.layeringTarget.dirty = true;
                    }
                    objs.splice.apply(objs, [0, objs.length].concat(items));
                    setObjects(objs.slice());
                    canvas.fire('order:changed', objs);
                    canvas.renderAll();
                }}
            />
        </div>
    );
}
export default LayerPanel;