import { useState, useEffect } from "react";
import MiSlider from '@material-ui/core/Slider';

function getValuetext(value) {
    return value;
}

export function Slider({ min, max, className, onChange, onChangeCommitted, value, valuetext, style, step }) {
    const [val, setValue] = useState(value);
    if (!valuetext) {
        valuetext = getValuetext;
    }
    useEffect(() => {
        setValue(value);
    }, [value]);
    return <MiSlider
        min={min}
        max={max}
        value={val}
        style={style}
        className={className}
        onChange={(ev, val) => {
            setValue(val);
            onChange && onChange(ev, val);
        }}
        step={step}
        onChangeCommitted={onChangeCommitted}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
    />
}

export function BiEndSlider({ min, max, className, onChange, onChangeCommitted, value, valuetext, fixedEnd = 0, step }) {
    const [val, setValue] = useState(value);
    if (!valuetext) {
        valuetext = getValuetext;
    }
    useEffect(() => {
        setValue(value);
    }, [value]);
    return <MiSlider
        min={min}
        max={max}
        step={step}
        value={val > fixedEnd ? [fixedEnd, val] : [val, fixedEnd]}
        className={`${className} bi-end-Slider ${fixedEnd < val ? 'first' : 'second'}`}
        onChange={(ev, val) => {
            let v = fixedEnd === val[0] ? val[1] : val[0];
            setValue(v);
            onChange?.(ev, v);
        }}
        onChangeCommitted={(ev, val) => {
            let v = fixedEnd === val[0] ? val[1] : val[0];
            onChangeCommitted?.(ev, v)
        }}
        valueLabelDisplay="auto"
        getAriaValueText={() => valuetext(val)}
    />
}