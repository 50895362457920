import React, { useEffect, useState } from "react";
import MenuButton from "../../../components/Buttons/MenuButton";
import { useFabricCanvasHandler } from "../FabricCanvas.js";

let Designer;
const flipRotateAction = {
    events: {
        'selection:created': function () {
            flipRotateAction.updatePanel();
        },
        'selection:updated': function () {
            flipRotateAction.updatePanel();
        },
        'selection:cleared': function () {
            flipRotateAction.updatePanel();
        }
    },
    flip: function (fx, fy) {
        var canvas = Designer.AppData.getCanvas();

        var activeObject = canvas.getActiveObject();

        if (activeObject && !activeObject.isLocked) {
            if (fx) {
                activeObject.flipX = !activeObject.flipX;
            }
            if (fy) {
                activeObject.flipY = !activeObject.flipY;
            }
            activeObject.setCoords();

            canvas.renderAll();
            canvas.calcOffset();

            canvas.fire('object:modified', { target: activeObject });
        }
    },

    rotate: function (byAngle) {
        var canvas = Designer.AppData.getCanvas();

        var activeObject = canvas.getActiveObject();

        if (activeObject && !activeObject.isLocked) {
            var angle = this.adjustRotationAngle(activeObject.getAngle(), byAngle);
            activeObject.rotate(angle);
            activeObject.setCoords();

            canvas.renderAll();
            canvas.calcOffset();

            canvas.fire('object:modified', { target: activeObject });
        }
    },

    parentShown: function () {
        // Update the panel.
        this.updatePanel();
    },

    updatePanel: function () {
        var canFlip = false;

        var canvas = Designer.AppData.getCanvas();
        var activeObject = canvas.getActiveObject();

        canFlip = (activeObject && !activeObject.layer &&
            !activeObject.isLocked) ? true : false;

        // Enable/disable the buttons.
        this.updateState({
            flipHorizontal: {
                disabled: !canFlip
            },
            flipVertical: {
                disabled: !canFlip
            },
            rotateClockwise: {
                disabled: !canFlip
            },
            rotateAntiClockwise: {
                disabled: !canFlip
            }
        });
    },

    adjustRotationAngle: function (currAngle, rotateByAngle) {
        if (rotateByAngle === 0) {
            return currAngle;
        }

        // The new angle needs to be a multiple of rotation angle.
        // If the current angle is already a multiple of rotation angle, we
        // just add the two and return.
        if ((currAngle % rotateByAngle) === 0) {
            return currAngle + rotateByAngle;
        }

        // Otherwise we adjust the rotation angle
        var absRotateByAngle = Math.abs(rotateByAngle);

        if (rotateByAngle > 0) {
            return Math.ceil(currAngle / absRotateByAngle) * absRotateByAngle;
        } else {
            return Math.floor(currAngle / absRotateByAngle) * absRotateByAngle;
        }
    },

    onFlipHorizontalButtonClick: function (button, e, eOpts) {
        this.flip(true, false); // Flip horizontally
    },

    onFlipVerticalButtonClick: function (button, e, eOpts) {
        this.flip(false, true); // Flip vertically
    },

    onRotateClockwiseButtonClick: function (button, e, eOpts) {
        this.rotate(Designer.app.constants.OBJECT_ROTATION_DEGREES); // Rotate right by specified degrees.
    },

    onRotateCounterClockwiseButtonClick: function (button, e, eOpts) {
        this.rotate(-Designer.app.constants.OBJECT_ROTATION_DEGREES); // Rotate left by specified degrees.
    },
    init: function (stateInterface) {
        this.updateState = stateInterface.updateState;
    }
};

export default function FlipRotatePanel({flip, rotate}) {
    const [canvas] = useFabricCanvasHandler(flipRotateAction.events);
    const [flipHorizontal, setFlipHorizontalState] = useState({});
    const [flipVertical, setFlipVerticalState] = useState({});
    const [rotateClockwise, setRotateClockwiseState] = useState({});
    const [rotateAntiClockwise, setRotateAntiClockwiseState] = useState({});

    useEffect(() => {
        Designer = window.global.Designer;
        flipRotateAction.init({
            updateState: function (obj) {
                if (obj.flipHorizontal)
                    setFlipHorizontalState(obj.flipHorizontal);
                if (obj.flipVertical)
                    setFlipVerticalState(obj.flipVertical);
                if (obj.rotateClockwise)
                    setRotateClockwiseState(obj.rotateClockwise);
                if (obj.rotateAntiClockwise)
                    setRotateAntiClockwiseState(obj.rotateAntiClockwise);
            }
        });
    }, []);

    useEffect(() => {
        canvas && flipRotateAction.updatePanel();
    }, [canvas])

    return (
        <table>
            <tbody>
                {
                    flip &&
                    <tr>
                        <td>
                            <MenuButton state={flipHorizontal}
                                onClick={() => { flipRotateAction.onFlipHorizontalButtonClick(); }}
                                className="icomoon icon-flip-horizontal" title="Flip Horizontal" />
                        </td>
                        <td>
                            <MenuButton state={flipVertical}
                                onClick={() => { flipRotateAction.onFlipVerticalButtonClick(); }}
                                className="icomoon icon-flip-vertical" title="Flip Vertical" />
                        </td>
                    </tr>
                }
                {
                    rotate &&
                    <tr>
                        <td>
                            <MenuButton state={rotateClockwise}
                                onClick={() => { flipRotateAction.onRotateClockwiseButtonClick(); }}
                                className="fa fa-rotate-right font-22" title="Rotate Right" />
                        </td>
                        <td>
                            <MenuButton state={rotateAntiClockwise}
                                onClick={() => { flipRotateAction.onRotateCounterClockwiseButtonClick(); }}
                                className="fa fa-rotate-left font-22" title="Rotate Left" />
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    );
}