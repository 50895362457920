import React from "react";
import classes from './ReferencePoint.module.css';

const valArr = [
    ["TOP_LEFT", "TOP_CENTER", "TOP_RIGHT"],
    ["MIDDLE_LEFT", "MIDDLE_CENTER", "MIDDLE_RIGHT"],
    ["BOTTOM_LEFT", "BOTTOM_CENTER", "BOTTOM_RIGHT"]
];

function ReferencePoint({placement, onChange, disabled, style}) {
    return (
        <div className={classes.main} style={style}>
            <div className={classes.grid}>
                {valArr.map((subArr, idx1) => 
                    subArr.map((value, idx2) => 
                        <div key={`${idx1}_${idx2}`} className={classes.point}>
                            <input type="checkbox" className={classes.gridElements} value={value} onChange={e => {
                                onChange?.(value, idx2, idx1);
                            }} checked={value === placement} disabled={disabled} />
                            <label onClick={e => e.currentTarget.parentNode.firstElementChild.click()}/>
                        </div>
                    )
                )}
            </div>
        </div >
    );
}

export default ReferencePoint;