import React, { useState, Suspense, useEffect } from "react";
import { useHistory } from 'react-router';
import PrimaryButton from "../Buttons/PrimaryButton";
import Password from "../Password/Password";
import Utils from "../../Utils";
import classes from "./SignUp.module.css";
import Card from "../Card/Card";
import { createUser, verifyUser, resendCode, forgotPassword, logout } from '../../modules/Users/UsersActions'
import MessageDialog from "../MessageDialog/MessageDialog";
import Verification from "../Verification/Verification";
import Input from "../Input/Input";
import Consts from "../../Consts";
import queryString from 'query-string';
import LinkButton from "../Buttons/LinkButton";
import NeedHelp from "../NeedHelp/NeedHelp";
import ModalDialog from "../Dialog/Dialog";
import eulaContent from "../../assets/EULA.htm";

const PasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'));
function SignUp(props) {
    const history = useHistory();
    const params = queryString.parse(history.location.search);

    const [userDetails, setUserDetails] = useState(props.userDetails || {});
    const [passwordScore, setPasswordScore] = useState(0);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [showVerifyError, setShowVerifyError] = useState(false);
    const [isCodeResent, setIsCodeResent] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: "", message: "" });
    const [eulaDialog, setEulaDialog] = useState(false);

    const [acceptEULA, setAcceptEULA] = useState(props.isForgotPassword);

    useEffect(() => {
        if (Utils.isUserLoggedIn()) {
            logout();
        }
    }, []);

    useEffect(() => {
        setAcceptEULA(props.isForgotPassword);
    }, [props.isForgotPassword]);

    function createHandler() {
        if (Utils.validateEmail(userDetails.userId).valid) {
            if (passwordScore >= 1) {
                const data = { ...userDetails };
                let method;
                if (props.isForgotPassword) {
                    method = forgotPassword;
                } else {
                    data.role = params.role === 'editor' ? 'editor' : 'reviewer';
                    method = createUser;
                }

                method(data).then((response) => {
                    if (response && response.data && response.data.success) {
                        const obj = {
                            title: "Verify Email",
                            message: `Verification code sent to <b>${userDetails.userId} </b><br/>Not seeing it? Check junk folder or click Resend Code.`
                        };
                        setDialogContent(() => {
                            return obj;
                        });
                        setShowVerification(true);
                    } else if (response.data?.errorCode === "already_exist") {
                        setDialogContent(() => {
                            const obj = {
                                title: "Failed",
                                message: "User already exists."
                            };
                            return obj;
                        });
                        setShowMessageDialog(true);
                    } else if (response.data?.errorCode === "wrong_password") {
                        setDialogContent(() => {
                            const obj = {
                                title: "Failed",
                                message: "Incorrect password."
                            };
                            return obj;
                        });
                        setShowMessageDialog(true);
                    } else if (response.data?.errorCode === "unauthorized") {
                        setDialogContent(() => {
                            const obj = {
                                title: "Failed",
                                message: "Unauthorized user."
                            };
                            return obj;
                        });
                    }
                });
            }
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }

    function verificationHandler(verificationCode) {
        const data = { ...userDetails };
        data.otp = verificationCode;
        setIsCodeResent(false);
        verifyUser(data).then((response) => {
            if (response && response?.data?.success) {
                setShowVerification(false);
                if (props.isForgotPassword) {
                    history.push('/sepstudio-ui');
                } else {
                    history.push({
                        pathname: '/sepstudio-ui/profile',
                        state: {
                            from: history.location.pathname
                        }
                    });
                }
            } else {
                setShowVerifyError(true);
            }
        })
    }

    function dialogCloseHandler() {
        setShowMessageDialog(false);
    }

    function resendCodeHandler() {
        setIsCodeResent(false);
        setShowVerifyError(false);
        const data = { ...userDetails };
        resendCode(data).then((response) => {
            if (response && response?.data?.success) {
                setIsCodeResent(true);
            }
        });
    }

    function closeHandler() {
        history.goBack();
    }

    function goToSignUP() {
        history.push({
            pathname: '/sepstudio-ui/sign-in'
        });
    }

    return (
        <div className={classes.mainBox}>
            {showMessageDialog && <MessageDialog {...dialogContent} onCancel={dialogCloseHandler} isCancel closeText="Close" width={Consts.MESSAAGE_DIALOG_WIDTH} />}
            {showVerification && <Verification {...dialogContent} isCodeResent={isCodeResent} onVerify={verificationHandler} onResend={resendCodeHandler} showVerifyError={showVerifyError} onCancel={() => { setShowVerification(false); setShowVerifyError(false) }} />}
            <Card >
                <div className={classes.main}>
                    <div className={classes.heading}>
                        <h2>{props.isForgotPassword ? "Update Password" : "Create Account"}</h2>
                    </div>
                    <div className={classes.container}>
                        <label htmlFor="userId">Email</label>
                        <Input type="text"
                            id="userId"
                            autoFocus={true}
                            onChange={(e) => setUserDetails((prevState) => {
                                return {
                                    ...prevState,
                                    userId: e.target.value.trim()
                                }
                            })}
                            value={userDetails.userId}
                        />
                        {!isEmailValid && <p style={{ color: "red", marginTop: "1rem", marginBottom: "0" }}>Email is not valid </p>}
                        <label htmlFor="password">Password</label>
                        <Password id="password" togglePasswordVisibility={true}
                            width="17rem" height="2rem"
                            onChange={(e) =>
                                setUserDetails((prevState) => {
                                    return {
                                        ...prevState,
                                        password: e.target.value
                                    }
                                })
                            } autoComplete="off" onCommit={props.isForgotPassword && createHandler} />
                        <div className={classes.passwordStrengthBarContainer}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <PasswordStrengthBar password={userDetails.password} scoreWords={["weak", "weak", "okay", "good", "strong"]} scoreWordStyle={{ color: "rgb(208 , 208, 208)", fontSize: "0.75rem" }} minLength={4} shortScoreWord='too short'
                                    onChangeScore={(score) => setPasswordScore(score)} />
                            </Suspense>
                        </div>
                        {
                            !props.isForgotPassword && <div className={classes.eula} >
                                <input type="checkbox" checked={acceptEULA}
                                    className={classes.checkbox}
                                    onChange={e => setAcceptEULA(e.currentTarget.checked)}
                                />
                                <span style={{ margin: '0 3px' }}
                                    onClick={e => {
                                        e.currentTarget.parentNode.querySelector('input').click();
                                    }}
                                >I agree to NXT Moves</span>
                                <LinkButton label="Terms of Use" onClick={e => setEulaDialog(open => !open)} />
                            </div>
                        }
                        {
                            eulaDialog && <ModalDialog
                                contentClassName={classes.eulaContainer}
                                className={classes.modelDialog}
                                title={'Terms of Use'}
                                content={
                                    <iframe src={eulaContent} frameBorder="0" scrolling="no" onLoad={e => {
                                        const obj = e.target;
                                        obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
                                    }} />
                                }
                                footer={
                                    <PrimaryButton onClick={e => setEulaDialog(false)} negative>Close</PrimaryButton>
                                }
                            />
                        }
                        <div className={classes.actions} style={{ justifyContent: !props.isForgotPassword && 'center' }}>
                            {props.isForgotPassword &&
                                <>
                                    <div className={classes.needHelp} style={{ textAlign: 'start' }}>
                                        <NeedHelp />
                                    </div>

                                    <PrimaryButton onClick={closeHandler} negative>
                                        Cancel
                                    </PrimaryButton>
                                </>
                            }
                            <PrimaryButton onClick={createHandler}
                                style={{ width: !props.isForgotPassword && '40%' }}
                                disabled={!acceptEULA}
                            >
                                {props.isForgotPassword ? "Update" : "Create"}
                            </PrimaryButton>
                        </div>
                        {!props.isForgotPassword &&
                            <>
                                <div className={classes.signIn}>
                                    <span>Already have an account? </span>
                                    <LinkButton onClick={goToSignUP} label="Sign-in" />
                                </div>
                                <div className={classes.needHelp}>
                                    <NeedHelp />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default SignUp;