import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { closeProject, getAllProjects, sendForReview } from '../../modules/Projects/ProjectsActions';
import Utils from "../../Utils";
import classes from './Projects.module.css';
import ProjectItem from './ProjectItem';
import MessageDialog from '../../components/MessageDialog/MessageDialog';
import Consts from '../../Consts';
import Dialog from '../../components/Dialog/Dialog';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import Input from '../../components/Input/Input';
import ActivateUser from '../../components/Header/ActivateUser';
import gettingStarted from '../../assets/getting-started.svg'

const SortBy = {
    project: 'name',
    status: 'status',
    lastUpdated: 'updatedTime',
    lastComment: 'commentedTime'
}

function getSortingParams() {
    let sortingParams;
    const json = localStorage.getItem('sortingParams');
    if (json) {
        sortingParams = JSON.parse(json);
    } else {
        sortingParams = { order: 'asc', column: SortBy.lastUpdated };
    }
    return sortingParams;
}

function Projects() {
    const projects = useSelector(state => state.projects.projects) || [];
    const userDetails = useSelector(state => state.user);
    const sortedProjects = [...projects];
    const [projectList, setProjectList] = useState(sortedProjects);
    const [sortingParams, setSortingParams] = useState(getSortingParams());
    const history = useHistory();
    const dispatch = useDispatch();
    const [projectId, setProjectId] = useState("");
    const [project, setProject] = useState({});
    const [disabledUpdate, setDisabledUpdate] = useState(true);
    const [showAddReviewer, setShowAddReviewer] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [reviewerEmail, setReviewerEmail] = useState("");
    const [reviewerEmailList, setReviewerEmailList] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [focusedIdx, setFocusedIdx] = useState(0);
    const listRef = useRef();
    const [dialogContent, setDialogContent] = useState({
        title: "",
        message: ""
    });
    const [showMessageDialog, setShowMessageDialog] = useState(false);
    const reviwersList = projects.reduce((prev, project) => {
        (project.reviewers || []).forEach(prev.add.bind(prev));
        return prev;
    }, new Set())
    const userData = userDetails.user || {};
    const userRole = Utils.getUserRole(userData);

    let suggestionList = Array.from(reviwersList).filter((reviwer) => reviwer.toLowerCase().includes(reviewerEmail.toLowerCase()))
    if (Utils.validateEmail(reviewerEmail).valid) {
        suggestionList = suggestionList.filter(reviewer => reviewer !== reviewerEmail);
        suggestionList.unshift(reviewerEmail);
    }

    const filteredProjects = projectList.filter(filterHandler);

    useEffect(() => {
        closeProject(dispatch);
        getAllProjects(dispatch);
    }, []);

    useEffect(() => {
        setProjectList(sortedProjects);
        sortProject(sortingParams.column, sortingParams.order);
    }, [projects])

    useEffect(() => {
        const existingReviewers = project.reviewers || [];

        if ([Consts.IN_PROGRESS, Consts.APPROVE].includes(project?.status)) {
            setDisabledUpdate(!reviewerEmailList.length);
            return;
        }

        if (reviewerEmailList.length !== existingReviewers.length) {
            setDisabledUpdate(false);
            return;
        }

        for (let i = 0; i < reviewerEmailList.length; i++) {
            if (reviewerEmailList[i] !== existingReviewers[i]) {
                setDisabledUpdate(false);
                return;
            }
        }
        setDisabledUpdate(true);
    }, [reviewerEmailList, project.reviewers, project?.status]);

    useEffect(() => {
        setFocusedIdx(0);
    }, [reviewerEmail]);

    useEffect(() => {
        const parent = listRef.current;
        if (parent) {
            const child = parent.children.item(focusedIdx);
            if (child) {
                parent.scroll({
                    top: child.offsetTop - ((parent.offsetHeight - child.offsetHeight) / 2),
                    behavior: 'smooth'
                });
            }
        }
    }, [focusedIdx]);

    function filterHandler(project) {
        const filterkey = searchKey.toLowerCase();
        if (filterkey.length > 2) {
            const name = project.name.toLowerCase();
            const reviewers = project.reviewers?.map((reviewer) => reviewer.toLowerCase());
            if (name.includes(filterkey) || (reviewers && reviewers.some((reviewer) => reviewer.includes(filterkey)))) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    function getProjectStatusById(projectId) {
        const index = projects.findIndex((project) => project.id === projectId);
        if (index >= 0) {
            return projects[index].status;
        }
    }

    function onEditHandler(projectId) {
        if (!Utils.isUserActive()) {
            setShowDialog(true);
            return
        }
        let warning;
        switch (getProjectStatusById(projectId)) {
            case Consts.SENT_FOR_REVEIW:
                warning = "Project already sent for review. Do you wish to continue with project editing?";
                break;
            case Consts.APPROVE:
                warning = "Project already approved. Do you wish to continue with project editing?";
                break;
            default:
                break;
        }
        if (warning) {
            setDialogContent(() => {
                return {
                    title: "Warning",
                    message: warning,
                    type: 'edit',
                    isCancel: true
                }
            });
            setShowMessageDialog(true);
            setProjectId(projectId)
        } else {
            history.push(`/sepstudio-ui/edit/${projectId}`);
        }
    }

    function onSendHandler(id) {
        if (getProjectStatusById(id) === Consts.APPROVE) {
            setDialogContent({
                title: "Warning",
                message: "Project already approved. Do you wish to resend the project for review?",
                type: 'sendForReview',
                isCancel: true,
                id
            });
            setShowMessageDialog(true);
        } else {
            sendProjectForReview(id);
        }
    }

    function onReviewHandler(projectId) {
        history.push(`/sepstudio-ui/review/${projectId}`);
    }

    function sendForReviewHandler(projectId, reviewers) {
        const existingReviewers = project.reviewers || [];
        sendForReview(dispatch, projectId, reviewers).then(data => {
            if (data?.success) {
                setDialogContent(() => {
                    if ((!existingReviewers.length && reviewerEmailList.length) || project?.status !== Consts.SENT_FOR_REVEIW) {
                        return {
                            title: "Success",
                            message: "Project sent for review successfully."
                        }
                    } else if (!reviewerEmailList.length) {
                        return {
                            title: "Success",
                            message: "Reviewer(s) removed successfully."
                        }
                    } else {
                        return {
                            title: "Success",
                            message: "Reviewer(s) updated successfully."
                        }
                    }
                });
            } else {
                setDialogContent(() => {
                    if (existingReviewers.length) {
                        return {
                            title: "Failed",
                            message: "Failed to update reviewers."
                        }
                    } else {
                        return {
                            title: "Failed",
                            message: "Failed to send the project for review."
                        }
                    }
                });
            }
            setShowMessageDialog(true);
            setDisabledUpdate(true);
        })
    }

    function sendProjectForReview(id) {
        setReviewerEmail('');
        if (!Utils.isUserActive()) {
            setShowDialog(true);
            return
        }
        setProjectId(id);
        const project = projects.find(ele => ele.id === id);
        const existingReviewers = project.reviewers || [];
        setProject(project);
        setReviewerEmailList(existingReviewers);
        setShowAddReviewer(true);
    }

    function addReviewerHandler() {
        sendForReviewHandler(projectId, reviewerEmailList);
        setShowAddReviewer(false);
    }

    function addReviewerEmail() {
        if (!reviewerEmailList.some((ele) => ele === suggestionList[focusedIdx])) {
            setReviewerEmailList([...reviewerEmailList, suggestionList[focusedIdx].toLowerCase()]);
        }
        if (suggestionList[focusedIdx]) {
            setReviewerEmail("");
        }
    }

    function addFocusedReviwer(clickedReviwer) {
        if (!reviewerEmailList.some((ele) => ele === clickedReviwer)) {
            setReviewerEmailList([...reviewerEmailList, clickedReviwer]);
        }
        setReviewerEmail("");
    }

    function checkKey(e) {
        switch (e.keyCode) {
            case 13:
                addReviewerEmail()
                break;
            case 40:
                if (focusedIdx < suggestionList.length - 1) {
                    setFocusedIdx(focusedIdx + 1)
                }
                break;
            case 38:
                if (focusedIdx) {
                    setFocusedIdx(focusedIdx - 1)
                }
                break;
            default:
                break;
        }
    }

    function removeReviewerEmail(email) {
        let newReviewerEmailList = reviewerEmailList.filter((ele) => ele !== email)
        setReviewerEmailList(newReviewerEmailList);
    }

    function closeDialog() {
        setShowAddReviewer(false);
    }

    function wishlistHandler() {
        window.open(`https://solutionsforscreenprinters.com/wishlist/?email=${Utils.getUserId(userData)}&software=NXT Moves`, '_blank');
    }

    function sortProject(sortBy, direction) {
        const order = direction || (sortingParams.order === 'asc' ? 'desc' : 'asc');
        switch (sortBy) {
            case SortBy.lastUpdated:
            case SortBy.lastComment:
                sortedProjects.sort((pro1, pro2) => {
                    let timeA = pro1[sortBy],
                        timeB = pro2[sortBy];
                    if (timeA === undefined) {
                        return 1;
                    }
                    if (timeB === undefined) {
                        return -1;
                    }
                    timeA = String(timeA['$date'] || timeA);
                    timeB = String(timeB['$date'] || timeB);
                    if (timeA < timeB) {
                        return order === 'asc' ? 1 : -1;
                    } else if (timeA > timeB) {
                        return order === 'asc' ? -1 : 1;
                    }
                    return 0;
                });
                break;
            default:
                sortedProjects.sort((pro1, pro2) => {
                    const proA = pro1[sortBy]?.toLowerCase();
                    const proB = pro2[sortBy]?.toLowerCase();
                    if (proA < proB) {
                        return order === 'asc' ? -1 : 1;
                    } else if (proA > proB) {
                        return order === 'asc' ? 1 : -1;
                    }
                    return 0;
                });
                break;
        }
        setProjectList(sortedProjects);
        const params = { order, column: sortBy };
        setSortingParams(params);
        localStorage.setItem('sortingParams', JSON.stringify(params));
    };

    return (
        <>
            {showDialog && <ActivateUser setShowDialog={setShowDialog} />}
            {
                showMessageDialog &&
                <MessageDialog {...dialogContent}
                    onAccept={e => {
                        setShowMessageDialog(false);
                        if (dialogContent.type === 'edit') {
                            history.push(`/sepstudio-ui/edit/${projectId}`);
                        } else if (dialogContent.type === 'sendForReview') {
                            sendProjectForReview(dialogContent.id);
                        }
                    }}
                    width={Consts.MESSAAGE_DIALOG_WIDTH}
                    onCancel={e => setShowMessageDialog(false)}
                />
            }
            {showAddReviewer &&
                <Dialog
                    title="Send for Review"
                    width="300px"
                    content={
                        <div className={classes.addReviewerContent}>
                            <label htmlFor='input'>Enter Email</label>
                            <div className={classes.addReviewerInput}>
                                <input id="input" type="email" value={reviewerEmail} autoComplete="off" onChange={(e) => setReviewerEmail(e.target.value)} onKeyDown={checkKey}
                                />
                                {reviewerEmail &&
                                    <div className={classes.suggestionContainer} ref={listRef}>
                                        {suggestionList.map((suggestion, index) => (
                                            <div key={suggestion} className={Utils.getClasses(classes.suggestion, focusedIdx === index && classes.active)} onClick={() => addFocusedReviwer(suggestion)}>{suggestion}</div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className={classes.reviewers}>
                                <div>Reviewers</div>
                                <div className={classes.reviewersEmailListWrapper}>
                                    {reviewerEmailList.map((ele) => (
                                        <div key={ele} className={classes.reviewerEmailList}>
                                            <div className={classes.reviewerEmails} title={ele}>{ele}</div>
                                            <span className={`fa fa-trash clipart-category-btn d-flex align-items-center justify-content-end cursor-pointer`}
                                                onClick={() => removeReviewerEmail(ele)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                    footer={
                        <div className={classes.addReviewerActions}>
                            <PrimaryButton onClick={closeDialog} negative>
                                Cancel
                            </PrimaryButton>
                            <PrimaryButton onClick={addReviewerHandler} disabled={disabledUpdate}>
                                {project?.status === Consts.SENT_FOR_REVEIW ? 'Update' : 'Send'}
                            </PrimaryButton>
                        </div>}
                />
            }
            <div className={classes.main}>
                <div className={classes.header}>
                    <h2>My Projects</h2>
                    <div className={classes.searchBar}>
                        <div className={classes.refresh}>
                            <span className="fa fa-rotate-right cursor-pointer"
                                onClick={() => {
                                    getAllProjects(dispatch);
                                }}
                                title="Refresh Projects"
                            />
                        </div>
                        <div className={classes.searchBox}>
                            <span className='fa fa-search search-icon' style={{ color: 'var(--panel-dark-background)' }} />
                            <Input id='filter' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                        </div>
                        <div className={classes.wishlist}>
                            <PrimaryButton onClick={wishlistHandler}>
                                <span>Wish List</span>
                                <i className="fa fa-heart"></i>
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
                <div className={classes.tableWrapper}>
                    <table className={classes.table}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th onClick={() => sortProject(SortBy.project)} className='cursor-pointer'>Project
                                    <i className={Utils.getClasses('fa fa-arrow-down', sortingParams.order === 'asc' && classes.down)} style={{ opacity: sortingParams.column === SortBy.project && 1 }} />
                                </th>
                                <th onClick={() => sortProject(SortBy.status)} className='cursor-pointer'>Status
                                    <i className={Utils.getClasses('fa fa-arrow-down', sortingParams.order === 'asc' && classes.down)} style={{ opacity: sortingParams.column === SortBy.status && 1 }} />
                                </th>
                                <th>Reviewer(s)</th>
                                <th onClick={() => sortProject(SortBy.lastUpdated)} className='cursor-pointer'>Last Updated
                                    <i className={Utils.getClasses('fa fa-arrow-down', sortingParams.order === 'asc' && classes.down)} style={{ opacity: sortingParams.column === SortBy.lastUpdated && 1 }} />
                                </th>
                                <th onClick={() => sortProject(SortBy.lastComment)} className='cursor-pointer'>Last Comment
                                    <i className={Utils.getClasses('fa fa-arrow-down', sortingParams.order === 'asc' && classes.down)} style={{ opacity: sortingParams.column === SortBy.lastComment && 1 }} />
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProjects.map((project, index) =>
                                <ProjectItem key={project.id} index={index}
                                    project={project} onEdit={onEditHandler} onReview={onReviewHandler} onSend={onSendHandler} />)
                            }
                        </tbody>
                    </table>
                    {!filteredProjects.length && userRole == 'editor' &&
                        <div className={classes.noProjects}>
                            <img src={gettingStarted} alt="Getting Started" width={150} />
                            <div>Launch your creativity!</div>
                            <a href="https://solutionsforscreenprinters.com/nxt-moves-guide/getting-started/"
                                target="_blank"
                            >
                                Get Started
                            </a>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Projects;