import React, { useEffect, useState } from 'react';
import ImageViewer from './ImageViewer';
import classes from './ImagePreview.module.css';
import Annotations from './Annotations';
import { useSelector } from 'react-redux';
import { addAnnotation, addLocation, getAnnotations, updateAnnotation, updateProjectStatus, setAnnotationId } from '../AnnotationsActions';
import { useDispatch } from 'react-redux';
import { getProject, closeProject } from '../Projects/ProjectsActions';
import Utils from '../../Utils';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import Dialog from '../../components/Dialog/Dialog';
import Consts from '../../Consts';
import { useHistory } from 'react-router';

function ImagePreview(props) {

    const projectId = props.match.params.projectId;
    const dispatch = useDispatch();
    const history = useHistory();
    const initialPinObject = {
        newThread: {
            isPinned: false,
            location: {},
        },
        existingThread: {
            isPinned: false,
            location: {}
        }
    }
    const annotations = useSelector((state) => state.annotations.annotations || []);
    const project = useSelector((state) => state.projects.project || {});
    const projects = useSelector((state) => state.projects.projects);
    const [showChat, setShowChat] = useState(false);
    const [showApprove, setShowApprove] = useState(false);
    const [activeChatId, setActiveChatId] = useState("");
    const [pinObject, setPinObject] = useState(initialPinObject);
    const annotationId = useSelector((state) => state.annotations.activeAnnotation || '');

    useEffect(() => {
        getProject(dispatch, projectId, history, projects).then((response) => {
            if (response?.data?.success) {
                console.log("Project Fetched");
            }
        });
        let filter = {
            projectId: projectId
        }
        filter = JSON.stringify(filter);
        filter = encodeURIComponent(filter);
        getAnnotations(dispatch, filter);
    }, [projectId])

    useEffect(() => {
        if (annotations.length && annotations.some(i => annotationId === i.id) && annotationId) {
            setActiveChatId(annotationId);
            setShowChat(true);
            setAnnotationId(dispatch, '');
        }
    }, [annotations]);

    function changeShowChatHandler(value) {
        setShowChat(true);
    }

    function addAnnotationHandler(value) {
        const annotationData = {
            projectId: projectId,
            type: "comment",
            user: Utils.getUserId(),
            comments: [{
                user: Utils.getUserId(),
                text: value
            }],
        }

        if (Object.keys(pinObject.newThread.location).length > 0) {
            annotationData.location = { ...pinObject.newThread.location };
            annotationData.pinNumber = pinnedComments();
        }

        addAnnotation(dispatch, annotationData).then((response) => {
            if (response && response.data && response.data.success) {
                setPinObject(initialPinObject);
                setShowChat(false);
            }
        });

    }

    function changeActiveChat(id) {
        setActiveChatId(id);
        setShowChat(true);
        setPinObject(initialPinObject);
    }

    function closeChatHandler() {
        setShowChat(false);
        setActiveChatId("");
    }

    function addCommentHandler(data) {
        const commentData = {
            ...data,
            user: Utils.getUserId(),
            id: activeChatId
        }
        updateAnnotation(dispatch, commentData);
    }

    function addPinHandler(id) {
        setActiveChatId(id);

        const newPinObject = { ...initialPinObject };
        if (id === '') {
            if (pinObject.newThread.isPinned === false) {
                newPinObject.newThread.isPinned = true;
            }
        } else {
            if (pinObject.existingThread.isPinned === false) {
                newPinObject.existingThread.isPinned = true;
            }
        }

        setPinObject(newPinObject);
        setShowChat(false);
    }

    function setLocationHandler(value) {
        if (value.id) {
            // This is the cases when we are,
            // 1. Dragging and dropping an annotations pin on the image (pinning an annotation), or,
            // 2. Moving an annotations pin on the image.
            const annotation = annotations.find(i => i.id === value.id);
            addLocationHandler({
                location: { ...value, id: undefined },
                id: value.id,
                pinNumber: annotation.pinNumber ?? pinnedComments()
            });
        } else if (activeChatId !== '') {
            // When we click on an annotations pin.
            addLocationHandler({
                location: value,
                pinNumber: pinnedComments(),
                id: activeChatId
            });
            setActiveChatId("");
        } else {
            // This case happens when we are dragging and dropping pin from comment box.
            // No active comment at this time.
            const newPinObject = initialPinObject;
            newPinObject.newThread.isPinned = false;
            newPinObject.newThread.location = { ...value };
            setPinObject(newPinObject);
        }
    }

    function cancelHandler() {
        setPinObject(initialPinObject);
        setActiveChatId("")
    }

    function pinnedComments() {
        let count = 0;
        annotations.forEach((annotation) => {
            if (Number.isFinite(annotation.pinNumber)) {
                count++;
            }
        })
        return count;
    }

    function addLocationHandler(annotationData) {
        addLocation(dispatch, annotationData).then((response) => {
            if (response && response.data && response.data.success) {
                setPinObject(initialPinObject);
            }
        });
    }

    const isReadOnly = project?.status !== Consts.SENT_FOR_REVEIW;
    return <>
        {showApprove &&
            <Dialog
                maxWidth="md"
                title="Project Approval"
                width="600px"
                content={
                    <div className={classes.approveDialogContainer}>
                        <ol>
                            <li> <div> To approve project preview "as is" click OK.</div>
                                <ul>
                                    <li> project will be marked "approved by client"</li>
                                    <li>"as is" refers to the current state of the project preview</li>
                                    <li>edits not shown in this preview will not be included in production</li>
                                </ul>
                            </li>
                            <li>Not ready to approve? Incomplete comments or pins? Click Cancel.</li>
                        </ol>
                    </div>
                }
                footer={
                    <div className={classes.approveDialogFooter} >
                        <div className={classes.approveDialogNote}>Need help? Contact your screen printer directly outside this system.</div>
                        <div>
                            <PrimaryButton onClick={() => setShowApprove(false)} negative>
                                Cancel
                            </PrimaryButton>
                            <PrimaryButton onClick={() => {
                                addAnnotation(dispatch, {
                                    projectId,
                                    type: "comment",
                                    user: Utils.getUserId(),
                                    comments: [{
                                        text: "Approved",
                                        user: Utils.getUserId()
                                    }],
                                    system: true,
                                    closed: true
                                }).then(() => {
                                    updateProjectStatus(dispatch, projectId, Consts.APPROVE).then(() => {
                                        setShowApprove(false);
                                    });
                                });
                            }}>
                                OK
                            </PrimaryButton>
                        </div>
                    </div>}
            />
        }
        <div className={classes.main}>
            <div className={classes.leftContainer}>
                {!!Object.keys(project).length &&
                    <ImageViewer project={project}
                        annotations={annotations}
                        changeShowChat={changeShowChatHandler}
                        setLocation={setLocationHandler}
                        changeActiveCommentId={changeActiveChat}
                        pinObject={pinObject}
                        pinnedComments={pinnedComments()}
                        onCancel={cancelHandler}
                        isReadOnly={isReadOnly}
                    />
                }
            </div>
            <div className={classes.rightContainer}>
                <Annotations showChat={showChat}
                    activeChatId={activeChatId}
                    changeActiveChat={changeActiveChat}
                    closeChatHandler={closeChatHandler}
                    addAnnotation={addAnnotationHandler}
                    addComment={addCommentHandler}
                    addPinHandler={addPinHandler}
                    onCancel={cancelHandler}
                    changeActiveCommentId={changeActiveChat}
                    pinObject={pinObject}
                    isReadOnly={isReadOnly}
                />
                <div className={classes.actionContainer}>
                    {!showChat &&
                        <>
                            {
                                !isReadOnly && project?.reviewers?.some(i => i === Utils.getUserId()) ?
                                    <>
                                        <PrimaryButton
                                            onClick={() => setShowApprove(true)} positive>Approve</PrimaryButton>
                                    </> : ''
                            }
                            <PrimaryButton negative style={{ marginLeft: '12px' }} onClick={() => {
                                closeProject(dispatch);
                                history.replace('/sepstudio-ui');
                            }}>Close</PrimaryButton>
                        </>
                    }
                </div>
            </div>
        </div>
    </>;

}

export default ImagePreview;