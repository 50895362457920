import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Utils from '../../Utils';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import DropDownItem from '../DropDownMenu/DropDownItem';
import classes from './Header.module.css';
import { useHistory, useLocation } from 'react-router';
import { logout } from '../../modules/Users/UsersActions';
import PrimaryButton from '../Buttons/PrimaryButton';
import warningOutlineImage from '../../assets/warning-outline.svg';
import IconButton from '../Buttons/IconButton';
import { removeAllNotifications, removeNotification } from '../../modules/Notifications/NotificationsActions';
import { fillAnnotationUsers } from '../../modules/AnnotationsActions';
import closeOutlineImage from '../../assets/add-circle-outline.svg'
import logoImage from "../../assets/sepstudio-logo.png";
import keyIcon from "../../assets/change-password.svg";
import personIcon from "../../assets/person-outline.svg";
import logoutIcon from '../../assets/logout.svg';
import bellIcon from '../../assets/bell-icon.svg';
import ActivateUser from './ActivateUser';
import { setAnnotationId } from '../../modules/AnnotationsActions';
import { useRef } from 'react';

function getNotificationType(notificationType) {
    switch (notificationType) {
        case 'comment':
            return 'commented on '
        case 'approve':
            return 'approved '
        case 'disapprove':
            return 'rejected '
        default:
            return ""
    }
}

function getTime(time) {
    return Number(time['$numberLong'] || time);
}

function getLicenseExpiryMessage(days, isTrial) {
    let prefix = "", suffix = "";

    if (isTrial) {
        prefix = "Trial mode expires";
    } else {
        prefix = "License activation expires";
    }

    if (days === 1) {
        suffix = "in 1 day"
    } else {
        suffix = `in ${days} days`;
    }

    return `${prefix} ${suffix}.`;
}

function getSubscriptionData({ user }) {
    if (!user) {
        return;
    }

    const subscriptionData = {
        message: '',
        buttonText: 'Purchase License',
        messageColor: 'var(--text-light-color)',
    };

    const { isTrial, expiryTime, isActive } = user;
    const now = Date.now();

    if (!isActive) {
        subscriptionData.message = isTrial ? 'Trial license expired.' : 'License activation expired.';
        subscriptionData.messageColor = 'red';
        subscriptionData.positive = true;
        if (!isTrial) {
            subscriptionData.buttonText = 'Activate License';
        }
    } else {
        let days = Math.max(Math.floor((getTime(expiryTime) - now) / 1000 / 3600 / 24), 0);
        subscriptionData.message = days < 30 ? getLicenseExpiryMessage(days, isTrial) : '';
        if (!isTrial) {
            subscriptionData.buttonText = 'Renew License';
        }
    }

    return subscriptionData;
}

function Header() {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications.notifications);
    const projects = useSelector((state) => state.projects.projects);
    const project = useSelector((state) => state.projects.project);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(Utils.isUserLoggedIn());
    const [showDialog, setShowDialog] = useState(false);
    const [myNotifications, setMyNotifications] = useState([]);
    const [alert, setAlert] = useState(false);
    const userDetails = useSelector(state => state.user);
    const preNotifications = useRef(0);

    const subscriptionData = getSubscriptionData(userDetails);
    const userData = userDetails.user || {};

    useEffect(() => {
        setIsUserLoggedIn(Utils.isUserLoggedIn())
    }, [location]);

    useEffect(() => {
        if (preNotifications.current < myNotifications.length) {
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 4000)
        }
        preNotifications.current = myNotifications.length;
    }, [myNotifications]);

    useEffect(() => {
        const newNotifications = notifications.map((notification) => {
            const obj = { ...notification };
            obj.user = notification.sender;
            return obj;
        }
        );
        fillAnnotationUsers(newNotifications).then((res) => {
            setMyNotifications(newNotifications);
        })
    }, [notifications]);

    function passwordChangeHandler() {
        history.push('/sepstudio-ui/change-password');
    }

    function warningClickHandler(e) {
        e.stopPropagation();
        history.push('/sepstudio-ui/profile');
    }

    function getUserId() {
        return <div className={classes.userDetails}>
            <div>
                <IconButton
                    onClick={warningClickHandler}
                    iconImage={warningOutlineImage}
                    tooltip="Complete Profile"
                    altText="Complete Profile" />
            </div>
            {Utils.getUserId(userData)}
        </div>
    }

    const userRole = Utils.getUserRole(userData);

    function clearAllNotifications() {
        removeAllNotifications(dispatch);
    }

    function clearNotification(e, id) {
        e.stopPropagation();
        removeNotification(dispatch, id);
    }

    function notificationClickHandler(data, id) {
        if (id) {
            removeNotification(dispatch, id);
        }
        if (data?.projectId) {
            setAnnotationId(dispatch, data.annotationId);
            history.push(`/sepstudio-ui/review/${data.projectId}`);
        }
    }

    function getNotificationText(notification) {
        const fullName = Utils.getFullNameByUser(notification.user) || notification.user?.userId;
        const projectIndex = projects.findIndex((project) => project.id === notification.data.projectId);
        let projectName = ""
        if (projectIndex > -1)
            projectName = projects[projectIndex]?.name;

        return `<b>${fullName}</b> has ${getNotificationType(notification.type)} <b>${projectName}</b>`
    }

    function UpdateProfile() {
        history.push('/sepstudio-ui/profile');
    }

    return <div className={classes.main}>
        {showDialog && <ActivateUser setShowDialog={setShowDialog} />}
        <div className={classes.logoContainer}>
            <img className={classes.logo} src={logoImage} alt="Logo" />
            <div>
                <p>NXT Moves™</p>
            </div>
        </div>
        <div>{project?.name}</div>
        {isUserLoggedIn && !location.pathname.includes("sign-up") && <div className={classes.logoutContainer}>
            {
                userRole === "editor" && subscriptionData.message && <>
                    <span className={classes.subscriptionMsg} style={{ color: subscriptionData.messageColor }}>{subscriptionData.message}</span>
                    <PrimaryButton className={classes.activateUser} onClick={() => setShowDialog(true)} {...subscriptionData}>
                        {subscriptionData.buttonText}
                    </PrimaryButton>
                </>
            }{(location.pathname === "/sepstudio-ui" || location.pathname === "/sepstudio-ui/") &&
                <div className={classes.notificationsContainer}>
                    <DropDownMenu imageIcon={bellIcon} count={myNotifications.length} alert={alert} style={{ width: "1.5rem", height: "1.5rem" }} fill="white" color="black">
                        {
                            Boolean(notifications.length) &&
                            <div className={classes.notificationWrapper}>
                                <div className={classes.notifications}>
                                    {myNotifications.map((notification) => {
                                        return <div className={classes.notification} key={notification.id} onClick={(e) => notificationClickHandler(notification.data, notification.id)}>
                                            <div dangerouslySetInnerHTML={{ __html: getNotificationText(notification) }} />
                                            <IconButton
                                                onClick={(e) => clearNotification(e, notification.id)}
                                                iconImage={closeOutlineImage}
                                                altText="Clear" />
                                        </div>
                                    })}
                                </div>
                                <div className={classes.notificationActions}>
                                    <PrimaryButton onClick={clearAllNotifications}>
                                        Clear All
                                    </PrimaryButton>
                                </div>
                            </div>
                        }
                    </DropDownMenu>
                </div>
            }
            <DropDownMenu title={Utils.getUserFullName(userData) || getUserId()}>
                <DropDownItem title="Update Profile" onClick={UpdateProfile} imageIcon={personIcon} />
                <DropDownItem title="Change Password" onClick={passwordChangeHandler} imageIcon={keyIcon} />
                <DropDownItem title="Logout" onClick={e => logout(history)} imageIcon={logoutIcon} />
            </DropDownMenu>
        </div>
        }
    </div>;
}

export default Header;