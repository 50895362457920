import axios from "../../../axios";
import { getAllProjects } from "../Projects/ProjectsActions";
import { usersActions } from "./UsersSlice";

export function createUser(data) {
    return axios({
        url: "/sepstudio/user/create",
        method: "post",
        data: data,
        responseType: "json",
    }).catch((error) => {
        return error;
    });
}

export function signInUser(data) {
    return axios({
        url: "/sepstudio/user/login",
        method: "post",
        data: data,
        responseType: "json",
    });
}


export function logout(history) {
    return axios({
        url: "/sepstudio/user/logout",
        method: "delete"
    }).then(e => {
        localStorage.removeItem("isLoggedIn");
        history?.push('/sepstudio-ui/sign-in');
    });
}

export function forgotPassword(data) {
    return axios({
        url: "/sepstudio/user/forgotpassword",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            return response;
        }).catch((error) => {
            return error;
        });
}

export function updateUser(data) {
    return axios({
        url: "/sepstudio/user/updateProfile",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            return response;
        }).catch((error) => {
            return error;
        });
}


export function verifyUser(data) {
    return axios({
        url: "/sepstudio/user/verify",
        method: "post",
        data: data,
        responseType: "json",
    }).then(res => {
        if (res.data.success) {
            localStorage.setItem("isLoggedIn", "true");
        }
        return res;
    });
}

export function resendCode(data) {
    return axios({
        url: "/sepstudio/user/resend",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                // let newdata = data;
                // newdata.id = response.data.user.id;
                // delete newdata.password;
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function updateProfile(data, dispatch) {
    return axios({
        url: "/sepstudio/user/updateProfile",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                fetchProfile(dispatch);
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function fetchProfile(dispatch) {
    return axios({
        url: "/sepstudio/user/profile"
    }).then((response) => {
        const data = response.data;
        if (data.success) {
            delete data.success;
            dispatch(usersActions.fetchProfile(data));
        }
        return response;
    });
}

export function activateUser(dispatch, data) {
    return axios({
        url: "/sepstudio/redeem",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                getAllProjects(dispatch);
                fetchProfile(dispatch);
            }
            return response;
        }).catch((error) => {
            return error;
        });
}
