import React from "react";
import classes from "./BackButton.module.css";
import backArrowIcon from "../../assets/back-arrow-icon.svg";

function BackButton(props) {
    return (<div className={classes.main}>
        <button type="button" onClick={props.onBack}>
            <div className={classes.btnWrapper}>
                <img src={backArrowIcon} width="24px" height="24px" alt="Back Arrow Icon" />
            </div>
        </button>
    </div>);

}

export default BackButton;