import React, { useEffect, useState } from "react";
import "../components.css";
import ColorGrid from "./ColorGrid";
import ManageColors, { getColors } from './ManageColors';
import { CategorySelect } from './CategoryDialog';
import { addBackgroundColorLibrary, removeBackgroundColorLibrary, addBackgroundColor } from "../EditorAction.js";
import { useSelector, useDispatch } from 'react-redux';
import { useFabricCanvasHandler } from "../FabricCanvas";
import LinkButton from '../../../components/Buttons/LinkButton';
import ToolPanelButton from '../../../components/Buttons/ToolPanelButton';
import SearchBar from "../../../components/SearchBar/SearchBar";


let Designer,
	Ext,
	fabric;

const action = {
	getBackgroundObject: function (type) {
		var canvas = Designer.AppData.getCanvas();
		var objects = canvas.getObjects();

		for (var i = 0, length = objects.length; i < length; ++i) {
			var object = objects[i];
			if (object.get('id') === 'background')
				return object;
		}
	},

	setBackgroundColor: function (color) {
		var canvas = Designer.AppData.getCanvas(),
			colorValue = color.getColor();
		var colorObject = this.getBackgroundObject('rect');
		if (colorValue === 'None') {
			colorValue = '';
		}

		// Suppress undo redo handling, as several operations may be involved, for example
		// those in the event listeners. We notify once everything is done.
		var undoRedoContoller = Ext.undoRedoContoller;
		undoRedoContoller.mute();

		if (!colorObject) {
			var canvasController = Ext.canvasController;
			var productSize = canvasController.getProductSizeData();
			colorObject = new fabric.Rect({
				id: 'background',
				left: 0,
				top: 0,
				width: productSize.widthMM,
				height: productSize.heightMM,
				fill: colorValue,
				stroke: false,
				strokeWidth: 0,
				skipDrawing: true,
				selectable: false,
				evented: false
			});
			canvas.insertAt(colorObject, canvasController.getBGColorObjectIndex());
		} else {
			colorObject.set('fill', colorValue);
		}

		// Set the background color in the product model.
		var productModelData = Designer.AppData.productModelData;
		productModelData.backgroundColor = colorValue;

		// Restore undo redo handling.
		undoRedoContoller.unmute();

		canvas.fire('background:changed', colorValue)

		canvas.renderAll();
	}
}

function getTextileView() {
	let textileView;
	const json = localStorage.getItem('textileView');
	if (json) {
		textileView = JSON.parse(json);
	} else {
		textileView = { list: false };
	}
	return textileView;
}

export default function BackgroundWidget({ visible, backColor }) {
	const [manageDialog, setManageDialog] = useState({});
	const [selectedValue, setSelectedValue] = useState(0);
	const [view, setView] = useState(getTextileView());
	const [searchKey, setSearchKey] = useState("");
	const colorData = useSelector(st => st.backgroundColors);
	const dispatch = useDispatch();

	useFabricCanvasHandler({
		'product-size:changed': function (size) {
			var colorObject = action.getBackgroundObject('rect');
			if (colorObject) {
				colorObject.set({
					width: size.widthMM,
					height: size.heightMM
				});
			}
		},
		'canvas:x-loaded': function () {
			let colorObject = action.getBackgroundObject('rect');
			if (colorObject) {
				Designer.AppData.productModelData.backgroundColor = colorObject.fill;
			}
			setManageDialog(e => ({ ...e }))
		}
	});

	useEffect(() => {
		Designer = window.global.Designer;
		Ext = window.global.Ext;
		fabric = window.fabric;
	}, [])

	const labelStyle = {
		height: '1.875rem',
		margin: '0.5rem 10px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	};

	const categories = colorData?.libraryItems.map((i, idx) => ({
		...i,
		label: i.name,
		value: idx
	})) || [];
	const selectedOption = categories[selectedValue] || {};

	const colorList = getColors(selectedOption);
	const filteredColorList = colorList.filter((color) => {
		return color.name.toLowerCase().includes(searchKey.toLowerCase())
	})
	const selectedIndex = filteredColorList.findIndex((color) => {
		return color.getValue() === backColor.getValue();
	});

	return <div style={{ display: visible ? 'block' : 'none', height: "100%" }}>
		<CategorySelect
			style={{ padding: "0.6rem" }}
			categories={categories}
			onChange={(e, idx) => setSelectedValue(idx)}
			value={selectedValue}
			addLibrary={name => {
				addBackgroundColorLibrary(dispatch, name, colorData).then(e => {
					setSelectedValue(categories.length);
				})
			}}
			categoryKey="name"
			removeLibrary={val => {
				removeBackgroundColorLibrary(dispatch, categories[val], colorData).then(e => {
					let size = categories.length - 1;
					if (val >= size) {
						setSelectedValue(0);
					}
				});
			}}
		/>
		<div style={{ height: 'calc(100% - 7rem)' }}>
			<div className="editor-text" style={labelStyle}>
				<span>
					<span>Colors:</span>
					{
						!selectedOption.system &&
						<LinkButton onClick={() => setManageDialog({ open: true })}
							style={{ marginLeft: '0.75rem', fontSize: '14px' }} label="Manage Colors" />
					}
				</span>
				<div style={{ display: 'flex' }}>
					<SearchBar value={searchKey} onChange={setSearchKey} />
					<ToolPanelButton title='Grid/List View' onClick={() => {
						localStorage.setItem('textileView', JSON.stringify({ list: !view.list }))
						setView({ list: !view.list });
					}} state={{ pressed: view.list }} style={{
						width: '38px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center'
					}}><i className="fa fa-list-ul" style={{ fontSize: '22px' }} ></i></ToolPanelButton>
				</div>
			</div>
			<div style={{ height: 'calc(100% - 1.875rem' }} className="scroll-vertical">
				<ColorGrid colorlist={filteredColorList} selectIndex={[selectedIndex]} list={view.list}
					onClick={color => action.setBackgroundColor(color)} itemDraggable={false}
				/>
			</div>
		</div>
		{
			manageDialog.open ?
				<ManageColors data={selectedOption}
					disableGradient={true}
					open={true} onClose={() => {
						setManageDialog({ open: false });
					}}
					onSave={(data) => {
						let updated = { ...selectedOption, colors: data };
						delete updated.value;
						delete updated.label;
						addBackgroundColor(dispatch, updated, colorData).then(e => setManageDialog({ open: false }));
					}}
				/> : ''
		}
	</div>;
};