import React, { useState, useEffect } from "react";
import PanelButton from "../../components/Buttons/PanelButton";
import { ActionPanel } from "./Panels";
import LayerPanel from "./ActionPanels/LayerPanel.js";
import UndoRedoPanel from "./ActionPanels/UndoRedoPanel.js";
import CopyDeletePanel from "./ActionPanels/CopyDeletePanel.js";
import GroupPanel from "./ActionPanels/GroupPanel.js";
import AlignPanel from "./ActionPanels/AlignPanel.js";
import FlipRotatePanel from "./ActionPanels/FlipRotatePanel.js";
import { useFabricCanvasHandler } from "./FabricCanvas";
import classes from './RightController.module.css';
import FloatMenu from "../../components/FloatMenu/FloatMenu";

function Controllers() {
    const [alignState, setAlignState] = useState({ disabled: true });
    const [flipState, setFlipState] = useState({ disabled: true });
    const [active, setActive] = useState(0);
    const [menuPosition, setMenuPosition] = useState({left: 0, top: 0});

    useFabricCanvasHandler(fc => {
        function updateState() {
            let activeObjects = fc.getActiveObjects(),
                disabled = false;
            for (var o of activeObjects) {
                if (o.id === 'proofPositive') {
                    disabled = true;
                    break;
                }
            }
            setAlignState({ disabled: false });
            setFlipState({ disabled: disabled });
        };
        return {
            'selection:updated': updateState,
            'selection:created': updateState,
            'selection:cleared': function () {
                setAlignState({ disabled: true });
                setFlipState({ disabled: true });
            }
        };
    });

    useEffect(() => {
        switch (active) {
            case 2:
                alignState.disabled && setActive(0);
                break;
            case 3:
            case 4:
                flipState.disabled && setActive(0);
                break;
            default:
                break;
        }
    }, [alignState.disabled, flipState.disabled, active])

    function setMenuData(e, val) {
        setActive(i => i === val ? 0 : val);
        let rect = e.currentTarget.getBoundingClientRect();
        setMenuPosition({
            left: rect.x,
            top: rect.y
        })
    }

    return (<React.Fragment>
        <div className={classes.panelButtonWrapper}>
            <PanelButton onClick={e => setMenuData(e, 1)} showMenu={active === 1}
                className="fa fa-layer-group" title="Layers">
                {
                    active === 1 && 
                    <FloatMenu position={menuPosition} style={{ transform: 'translate(-103%, -52%)' }} >
                        <LayerPanel/>
                    </FloatMenu>
                }
            </PanelButton>
            <PanelButton state={alignState} onClick={e => setMenuData(e, 2)}
                showMenu={active === 2} className="icomoon icon-align-vertical-center  text-60"
                title="Align">
                {
                    active === 2 &&
                    <FloatMenu position={menuPosition} style={{ transform: 'translate(-103%, -37%)' }} >
                        <AlignPanel/>
                    </FloatMenu>
                }
            </PanelButton>
            <PanelButton state={flipState} onClick={e => setMenuData(e, 3)}
                showMenu={active === 3} className="icomoon icon-flip-horizontal text-60"
                title="Flip">
                {
                    active === 3 &&
                    <FloatMenu position={menuPosition} style={{ transform: 'translate(-103%, -18%)' }} >
                        <FlipRotatePanel flip/>
                    </FloatMenu>
                }
            </PanelButton>
            <PanelButton state={flipState} onClick={e => setMenuData(e, 4)}
                showMenu={active === 4} className="fa fa-rotate-right font-22"
                title="Rotate">
                {
                    active === 4 &&
                    <FloatMenu position={menuPosition} style={{ transform: 'translate(-103%, -18%)' }} >
                        <FlipRotatePanel rotate/>
                    </FloatMenu>
                }
            </PanelButton>
        </div>
    </React.Fragment >);
}

export default function RightController(props) {
    return <div style={{ padding: '10px'}}>
        <ActionPanel style={{ paddingBottom: '10px' }}>
            <UndoRedoPanel {...props}/>
            <CopyDeletePanel/>
            <GroupPanel/>
        </ActionPanel>
        <ActionPanel className="separator-top" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
            <Controllers />
        </ActionPanel>
    </div>;
}