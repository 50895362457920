import React, { useEffect, useState } from 'react';
import SliderButton from '../../../components/Buttons/SliderButton';
import ReferencePoint from '../../../components/ReferencePoint/ReferencePoint';
import Utils from '../../../Utils';
import { useFabricCanvasHandler } from '../FabricCanvas';
import classes from './SizeWidget.module.css';

let cachedRefPoint;
function getReferencePoint() {
    try {
        if (!cachedRefPoint)
            cachedRefPoint = JSON.parse(localStorage.getItem('refPoint'));
    } catch (err) { }
    if (!cachedRefPoint) {
        cachedRefPoint = {
            placement: 'MIDDLE_CENTER',
            x: 0.5,
            y: 0.5
        };
    }
    return cachedRefPoint;
}


function getTwoDecimalValue(value, unit) {
    const newValue = Utils.convertMMToUnits(value, unit);
    let val;
    if (unit === "mm") {
        val = Math.round(newValue * 100) / 100;
    } else {
        val = Math.round(newValue * 10000) / 10000;
    }
    return Number(val);
}

function InputNumber({ label, value, onChange, step, unit }) {
    const minSizeMM = 1;
    const maxSizeMM = 1016; // 40 inch
    return <>
        <label className="editor-text">{label}</label>
        <input type="number"
            min={unit === 'inch' ? Utils.convertMMToUnits(minSizeMM).toFixed(2) : minSizeMM}
            max={unit === 'inch' ? Utils.convertMMToUnits(maxSizeMM).toFixed(2) : maxSizeMM}
            step={step} value={getTwoDecimalValue(value, unit)}
            onChange={onChange}
            onKeyDown={e => {
                if (['+', '-', 'e'].includes(e.key))
                    e.preventDefault();
            }}
        />
    </>
}

function SizeWidget({ visible }) {
    const canvasController = window.Ext?.canvasController;
    const [size, setSize] = useState(canvasController?.productSizeData || {
        widthMM: 50,
        heightMM: 50,
    });
    const [canvas] = useFabricCanvasHandler({
        'canvas:x-loaded': function(e) {
            setSize(canvasController.productSizeData);
        }
    });

    const [unit, setUnit] = useState(localStorage.getItem('prefUnit') || 'inch');
    const [refPoint, setRefPoint] = useState(getReferencePoint());

    useEffect(() => {
        let timer = setTimeout(() => {
            if (!canvas || canvasController.productSizeData === size) {
                return;
            }
            const prevSize = canvasController.productSizeData;
            let left = (size.widthMM - prevSize.widthMM) * refPoint.x,
                top = (size.heightMM - prevSize.heightMM) * refPoint.y;
            canvas.getObjects().forEach(o => {
                if (o.id === 'background' || o.id === 'overlay') {
                    return;
                }
                o.left += left;
                o.top += top;
            });
            canvasController.productSizeData = size;
            canvasController.transformCanvasViewport();
            canvas.fire('product-size:changed', size);
        }, 100)

        return () => clearTimeout(timer);
    }, [size, refPoint, canvas])

    function sizeHandler(property) {
        return e => {
            let value = Number(e.target.value);
            setSize((prevState) => {
                const newSize = { ...prevState };
                newSize[property] = Utils.convertUnitsToMM(value, unit);
                return newSize;
            });
        }
    }

    function unitHandler(checked) {
        const unit = checked ? 'inch' : 'mm';
        setUnit(unit);
        localStorage.setItem('prefUnit', unit);
    }

    const step = unit === "mm" ? 0.1 : 0.01;

    return (
        <div style={{ padding: "10px", display: visible ? 'block' : 'none' }}>
            <div className={classes.header}>
                <label>Artboard Size</label>
            </div>
            <div className={classes.content}>
                <InputNumber step={step} value={size.widthMM} unit={unit}
                    onChange={sizeHandler('widthMM')} label={`Width (${unit})`} />
                <InputNumber step={step} value={size.heightMM} unit={unit}
                    onChange={sizeHandler('heightMM')} label={`Height (${unit})`} />
            </div>
            <div className={classes.activeBtn}>
                <label className="editor-text">Units</label>
                <SliderButton onChange={unitHandler} checkedLabel="inch" uncheckedLabel="mm"
                    checked={unit !== 'mm'}
                />
            </div>
            <div style={{ marginTop: 10, display: 'none' }} >
                <label className="editor-text">Reference Point</label>
                <ReferencePoint placement={refPoint.placement} onChange={(p, i1, i2) => {
                    const point = {
                        placement: p,
                        x: i1 / 2,
                        y: i2 / 2
                    };
                    setRefPoint(point);
                    localStorage.setItem('refPoint', JSON.stringify(point));
                }} />
            </div>
        </div>
    )
}

export default SizeWidget;