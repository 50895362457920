import React, { useEffect, useState, useRef, useContext, createContext } from "react";
import { useContainerDimensions } from "../../custom-hooks/ContainerDimensions";
import "./components.css";

const FabricContext = createContext();
export const FabricContainer = FabricContext.Provider;

export function useFabricCanvas() {
	return useContext(FabricContext);
}

export function useFabricCanvasHandler(dataOrCallback, dependencies = []) {
	const canvas = useFabricCanvas();
	const [handler] = useState({});

	useEffect(() => {
		const data = typeof dataOrCallback === 'function' ? dataOrCallback(canvas) : dataOrCallback;
		if (canvas && data) {
			canvas.off(handler);
			Object.keys(handler).forEach(k => delete handler[k]);
			Object.assign(handler, data);
			canvas.on(handler);
		}
		return () => {
			if (canvas && handler) {
				canvas.off(handler);
			}
		};
	}, [canvas, handler, ...dependencies]);

	return [canvas, handler];
}

function onKeydown(e) {
	let left = 0, 
		top = 0, 
		step = 0.5,
		me = window.Ext.canvasController;
	switch(e.key) {
		case 'ArrowUp':
			top = -step;
			break;
		case 'ArrowLeft':
			left = -step;
			break;
		case 'ArrowDown':
			top = step;
			break;
		case 'ArrowRight':
			left = step;
			break;
		case 'Delete':
			me.deleteActiveObjects();
		default:
			return;
	}
	var canvas = me.canvas,
		activeObject = canvas.getActiveObject();
	if (activeObject) {
		activeObject.left += left;
		activeObject.top += top;
		canvas.renderAll();
	}
}

function onKeyup(e) {
	switch(e.key) {
		case 'ArrowUp':
		case 'ArrowLeft':
		case 'ArrowDown':
		case 'ArrowRight':
			var canvas = window.Ext.canvasController.canvas,
				activeObjects = canvas.getActiveObjects();
			if (activeObjects && activeObjects.length) {
				canvas.fire('object:modified', {target: activeObjects});
			}
	}
}


export default function FabricCanvas({onShow, style, project}){
	const wrapper = useRef();
	const mainCanvasWrapper = useRef();
	const panCanvasWrapper = useRef();
    const { width, height } = useContainerDimensions(wrapper);
	const [isEditing, setEditing] = useState(false);
	const [canvas] = useFabricCanvasHandler({
		'layerTarget:changed': function(e) {
			setEditing(e.editable);
		}
	});

	useEffect(() => {
		if (project) {
			let Ext = window.global.Ext;
			mainCanvasWrapper.current.innerHTML = `<canvas class="mainCanvas" />`;
			panCanvasWrapper.current.innerHTML = `<canvas class="panCanvas" />`;
			Ext.canvasController.initialize(project);
			Ext.canvasController.onCanvasPanelAfterRender(wrapper.current).then(e => {
				onShow?.(Ext.canvasController.canvas);
			})
		}
	}, [project, onShow]);

	useEffect(() => {
		if (wrapper.current) {
			var el = wrapper.current;
            el.addEventListener('keydown', onKeydown);
            el.addEventListener('keyup', onKeyup);
		}
		return () => {
			if (wrapper.current) {
				el.removeEventListener('keydown', onKeydown);
				el.removeEventListener('keyup', onKeyup);
			}
		}
	}, [wrapper.current])

	useEffect(() => {
		canvas && window.global.Ext.canvasController.onCanvasPanelAfterLayout();
	}, [width, height, canvas]);

	return <div className="semi-dark-background" tabIndex={0} 
		style={{ 
			height: '100%',
			width: '100%',
			outline: 'none',
			position: 'relative', 
			...style 
		}} ref={wrapper}
		>
			<div style={{ position: 'absolute' }}>
				<div ref={mainCanvasWrapper}>
					<canvas className="mainCanvas" />
				</div>
				<div className="previewCanvasContainer" style={{ position: "absolute", left: 0, top: 0 }}>
					<div style={{ position: 'absolute', top: 0, left: 0 }}>
						<canvas className="previewCanvas" />
					</div>
					<div style={{ position: 'absolute', top: 0, left: 0 }} ref={panCanvasWrapper}>
						<canvas className="panCanvas" />
					</div>
					<span className="previewCanvasLabel" style={{ position: 'absolute', top: 0, left: 0 }} />
				</div>
				<div className={`edit-svg ${isEditing ? '' : 'hidden'}`}>
					<span className="hyperlink" onClick={() => window.global.Ext.canvasController.closeSVGEditing()}>Close Editing</span>
				</div>
			</div>
		</div>;
};