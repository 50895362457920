import React, { useEffect, useState } from "react";
import PanelButton from "../../../components/Buttons/PanelButton";
import { useFabricCanvasHandler } from "../FabricCanvas.js";
import classes from './UndoRedoPanel.module.css';

let Designer,
    Ext;
const undoRedoActions = {
    objectifyColor: function (json) {
        if (json.fill && typeof json.fill === 'object')
            json.fill = JSON.parse(JSON.stringify(json.fill));
        if (json.stroke && typeof json.stroke === 'object')
            json.stroke = JSON.parse(JSON.stringify(json.stroke));
        let me = this;
        if (json.objects) {
            json.objects.forEach(function (o) {
                me.objectifyColor(o);
            });
        }
        return json;
    },
    getCanvasState: function () {
        var appData = Designer.AppData,
            canvas = appData.getCanvas();

        // Get JSON representation for all foreground objects and store.
        var fgObjects = [];

        var origIncludeDefaults = canvas.includeDefaultValues;
        canvas.includeDefaultValues = true;

        var propertiesToInclude = [
            'perPixelTargetFind',
            'targetFindTolerance',
            'savedTextValue'
        ];

        var objects = canvas.getObjects();
        for (let i = 0, length = objects.length; i < length; i++) {
            var object = objects[i];

            // We need to set clipTo to null since it does not round-trip
            // well through JSON - the mechanism used by undo/redo.
            object.clipTo = null;

            // If the object is part of the current selection group, it needs to
            // be transformed appropriately, i.e. it should be serialized as it would
            // appear if the selection group were to be destroyed.
            var originalProperties = canvas._realizeGroupTransformOnObject(object);

            var json = object.toObject(propertiesToInclude);
            fgObjects.push(this.objectifyColor(json));

            // Undo the damage we did by changing object properties.
            canvas._unwindGroupTransformOnObject(object, originalProperties);
        }
        canvas.includeDefaultValues = origIncludeDefaults;

        return {
            objects: fgObjects,
            viewPort: Ext.canvasController.productSizeData,
            colors: this.undoStack[this.currentStep]?.colors
        };
    },
    saveCurrentState: function (state) {
        if (!this.undoStack) {
            this.initializeUndoStack();
        }

        if (!state) {
            state = this.getCanvasState();
        }

        // Add the new state just after the current step.
        if (this.currentStep < this.undoStack.length - 1) {
            this.undoStack.splice(this.currentStep + 1);
        }

        this.undoStack.push(state);

        this.allStep++;
        this.currentStep++;

        if (this.undoStack.length > Designer.app.constants.MAX_UNDO_STEP_COUNT + 1) {
            this.undoStack.shift(); // Pop out the starting step
            this.currentStep--;
        }

        this.updatePanel();
    },

    updatePanel: function () {
        var canUndo = false,
            canRedo = false;

        if (this.undoStack && this.undoStack.length > 0) {
            canUndo = (this.currentStep > 0);
            canRedo = (this.currentStep < this.undoStack.length - 1);
        }

        // Enable/disable the buttons.
        this.updateState({
            undo: {
                disabled: !canUndo
            },
            redo: {
                disabled: !canRedo
            }
        });
    },

    loadCurrentState: function () {
        let promises = [];

        // Suppress object change event handling during this time.
        this.mute();
        if (this.currentStep >= 0 && this.currentStep < this.undoStack.length) {
            // Disable undo/redo until we finish the handling.
            this.updateState({
                undo: {
                    disabled: false
                },
                redo: {
                    disabled: false
                }
            });

            var canvas = Designer.AppData.getCanvas();
            var canvasPanelController = Ext.canvasController;
            canvas.renderOnAddRemove = false;
            canvas.loadingUndoRedoState = true;

            // Do away with the selection, as the current set of objects are going away.
            // New set of objects come in.
            canvas.discardActiveObject();
            let layeringTarget = canvas.layeringTarget;
            if (layeringTarget) {
                canvas.layeringTarget = null;
                canvas.fire('layerTarget:changed', { target: layeringTarget, editable: false });
            }

            // Load objects from current state.
            var state = this.undoStack[this.currentStep];
            var me = this;
            var transformViewPort = false;

            // Why do we serialize the state first?
            // In its Group.fromObject, fabric has this code to delete the group objects.
            // This results in objects being deleted from our undo stack state. We stringize
            // the state, so that fabric gets a copy of the state to work with.
            if (state.objects) {
                // Remove all foreground objects.
                canvas.removeStartingAt(0);
                promises.push(new Promise(resolve => {
                    canvas.loadFromJSON(
                        JSON.stringify(state),
                        function () { // callback
                            canvas.loadingUndoRedoState = false;

                            canvas.renderOnAddRemove = true;

                            if (transformViewPort) {
                                canvasPanelController.transformCanvasViewport();
                            }
                            resolve();
                        }
                    );
                }))
            }
            if (state.colors) {
                this.setSeparationColors(state.colors.slice());
            }

            if (state.viewPort) {
                Ext.canvasController.productSizeData = state.viewPort;
            }
        }
        return Promise.all(promises).then(e => {
            Ext.canvasController.transformCanvasViewport();
            me.unmute();
            me.updatePanel();

            canvas.renderAll();
            canvas.fire('canvas:x-loaded');
        });
    },

    onAction: function (seps, completeState) {
        if (this.suppressObjectChangeHandling) {
            return;
        }
        let state = completeState ? this.getCanvasState() : undefined;
        if (seps) {
            state = {
                ...state,
                colors: seps
            }
        }
        this.saveCurrentState(state);
    },

    enumerateObjects: function (filter, callback) {
        var stack = this.undoStack;

        // For all states.
        stack.forEach(function (state) {
            // For all objects in state.
            for (var i = 0, length = state.objects.length; i < length; i++) {
                var object = state.objects[i];

                var match = true;
                for (let prop in filter) {
                    if (Array.isArray(filter[prop])) {
                        if (filter[prop].indexOf(object[prop]) === -1) {
                            match = false;
                            break;
                        }
                    } else {
                        if (filter[prop] !== object[prop]) {
                            match = false;
                            break;
                        }
                    }
                }

                if (match) {
                    callback(object);
                }
            }
        });
    },

    initializeUndoStack: function (separationColors = []) {
        this.undoStack = [];
        this.currentStep = -1;
        this.allStep = -1;
        this.lastSavedState = 0;
        let state = this.getCanvasState();
        state.colors = separationColors;
        this.saveCurrentState(state);
    },

    isStackDirty: function () {
        return this.allStep !== this.lastSavedState;
    },

    setCurrentAsSaved: function () {
        this.lastSavedState = this.allStep;
    },

    mute: function () {
        this.suppressObjectChangeHandling = true;
    },

    unmute: function () {
        this.suppressObjectChangeHandling = false;
    },

    onUndoButtonClick: function () {
        if (this.currentStep === undefined || this.currentStep === 0) {
            return; // Can't undo
        }

        this.allStep--;
        this.currentStep--;
        window.loading(true);

        var me = this;
        setTimeout(function () {
            me.loadCurrentState().then(function () {
                window.loading(false);
            });
        }, 10);
    },

    onRedoButtonClick: function () {
        if (this.currentStep === undefined || this.currentStep === this.undoStack.length - 1) {
            return; // Can't redo
        }
        this.allStep++;
        this.currentStep++;
        window.loading(true);

        var me = this;
        setTimeout(function () {
            me.loadCurrentState().then(function () {
                window.loading(false);
            });
        }, 10);
    },

    init: function (stateInterface) {
        this.updateState = stateInterface.updateState;
    }
};
export default function UndoRedoPanel({ setSeparationColors }) {
    undoRedoActions.setSeparationColors = setSeparationColors;
    useFabricCanvasHandler({
        'object:added': function (e) {
            undoRedoActions.onAction();
        },
        'object:modified': function (e) {
            undoRedoActions.onAction();
        },
        'background:changed': function (e) {
            undoRedoActions.onAction();
        },
        'product-size:changed': function (e) {
            undoRedoActions.onAction();
        },
        'object:removed': function (e) {
            undoRedoActions.onAction();
        },
        'order:changed': function (e) {
            undoRedoActions.onAction();
        },
        'palette:created': function (e) {
            undoRedoActions.initializeUndoStack(e.target);
        },
        'palette:changed': function (e) {
            undoRedoActions.onAction(e.target, e.completeState);
        }
    });

    const [undoState, setUndoState] = useState({});
    const [redoState, setRedoState] = useState({});

    useEffect(() => {
        Designer = window.global.Designer;
        Ext = window.global.Ext;
        Ext.undoRedoContoller = undoRedoActions;
        undoRedoActions.init({
            updateState: function (obj) {
                if (obj.undo)
                    setUndoState(obj.undo);
                if (obj.redo)
                    setRedoState(obj.redo);
            }
        });
    }, []);

    return (<div className={classes.panelButtonWrapper}>
        <PanelButton state={undoState} onClick={() => {
            undoRedoActions.onUndoButtonClick();
        }} className="fa fa-mail-reply" title="Undo" />
        <PanelButton state={redoState} onClick={() => {
            undoRedoActions.onRedoButtonClick();
        }} className="fa fa-mail-forward" title="Redo" />
    </div>);
}
