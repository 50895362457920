import React from 'react';
import classes from './ToolbarButton.module.css';

function ToolbarButton(props) {
    const pressed = props.state?.pressed ? classes['x-btn-pressed'] : '';
    return (
        <div onClick={props.onClick} className={`${classes['toolbar-button']} ${pressed}`} style={{ 'backgroundImage': `url(${props.icon})`, 'backgroundRepeat': 'no-repeat', 'backgroundPosition': 'contain' }} />
    )
}

export default ToolbarButton;