import React, { useEffect, useRef, useState } from "react";
import ManageColors, { getColors } from "./ToolPanels/ManageColors.js";
import { CategorySelect } from "./ToolPanels/CategoryDialog.js";
import { addForegroundColorLibrary, removeForegroundColorLibrary, addForegroundColor } from "./EditorAction.js";
import ColorGrid from "./ToolPanels/ColorGrid.js";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import classes from './AddSwatch.module.css';
import LinkButton from "../../components/Buttons/LinkButton.js";
import ToolPanelButton from "../../components/Buttons/ToolPanelButton.js";
import { useSelector, useDispatch } from 'react-redux';
import { useFabricCanvas } from "./FabricCanvas.js";
import Utils from "../../Utils.js";
import SearchBar from "../../components/SearchBar/SearchBar.js";

export default function AddSwatch({ onClose, setSeparationColors, colors }) {
    const [showNotificaion, setShowNotificaion] = useState({ place: '', content: '', color: '' });
    const [colorList, setColorList] = useState([]);
    const clearMessage = useRef();
    const canvas = useFabricCanvas();

    function showNotifications(place, message, color) {
        clearTimeout(clearMessage.current);
        setShowNotificaion({ place: place, content: message, color: color });
        clearMessage.current = setTimeout(() => {
            setShowNotificaion({ place: '', content: '', color: '' })
        }, 3000)
    }

    useEffect(() => {
        return () => clearTimeout(clearMessage.current)
    }, [])

    return <Dialog
        open={true} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
            <span>Add Ink</span>
        </DialogTitle>
        <DialogContent>
            <ColorLibrary setColorList={setColorList} height="22rem" width="27rem" />
        </DialogContent>
        <DialogActions>
            {showNotificaion.place === 'separationColor' &&
                <span className="color-added-notification" style={{ color: showNotificaion.color }}>{showNotificaion.content}</span>
            }
            <PrimaryButton negative onClick={onClose}>
                Close
            </PrimaryButton>
            <PrimaryButton
                disabled={!colorList.length}
                onClick={() => {
                    let state = colors;
                    let added = false;
                    let updated = false;
                    let s = new Set(state.map(i => i.name.toLowerCase())),
                        arr = [];
                    colorList.forEach(color => {
                        let colorName = color.name.toLowerCase();
                        if (!s.has(colorName)) {
                            arr.push(color);
                            s.add(colorName);
                            added = true;
                        } else {
                            const idx = state.findIndex((ele) => ele.name.toLowerCase() === colorName);
                            const stateColor = state[idx];
                            if (stateColor && stateColor.getValue() !== color.getValue() && !stateColor.locked) {
                                const objs = Utils.replaceColorInFabricObjects(canvas, stateColor, color);
                                if (objs?.length) {
                                    canvas.fire('object:modified', { target: objs })
                                }
                                stateColor.color = color.getValue();
                                updated = true;
                            }
                        }
                    });
                    if (added) {
                        showNotifications('separationColor', 'Ink added to Palette.', 'var(--text-light-color)');
                    } else if (updated) {
                        showNotifications('separationColor', 'Ink updated.', 'var(--text-light-color)');
                    } else {
                        showNotifications('separationColor', 'Ink already exists.', 'red');
                    }
                    state = state.concat(arr);
                    setSeparationColors(state);
                    if (arr.length) {
                        canvas.fire('palette:changed', { target: state });
                    }
                }}>Add Ink</PrimaryButton>
        </DialogActions>
    </Dialog>
}

function getColorView() {
    let colorView;
    const json = localStorage.getItem('colorView');
    if (json) {
        colorView = JSON.parse(json);
    } else {
        colorView = { list: false };
    }
    return colorView;
}

function ColorLibrary({ setColorList, height, width }) {
    const [manageDialog, setManageDialog] = useState({});
    const [selectedValue, setSelectedValue] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const colorData = useSelector(st => st.foregroundColors);
    const [selection, setSelection] = useState([]);
    const [view, setView] = useState(getColorView());

    const dispatch = useDispatch();

    useEffect(() => {
        setSelection([]);
        setColorList([]);
    }, [selectedValue])

    const labelStyle = {
        height: '1.875rem',
        margin: '0.5rem 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'

    };

    const categories = colorData?.libraryItems.map((i, idx) => ({
        ...i,
        label: i.name,
        value: idx
    })) || [];
    const selectedOption = categories[selectedValue] || {};
    const colorList = getColors(selectedOption);
    const filteredColorList = colorList.filter((color) => {
        return color.name.toLowerCase().includes(searchKey.toLowerCase())
    })

    return <div style={{ height: height, width: width }}>
        <CategorySelect
            categories={categories}
            onChange={(e, idx) => setSelectedValue(idx)}
            value={selectedValue}
            addLibrary={name => {
                addForegroundColorLibrary(dispatch, name, colorData).then(e => {
                    setSelectedValue(categories.length);
                })
            }}
            categoryKey="name"
            removeLibrary={val => {
                removeForegroundColorLibrary(dispatch, categories[val], colorData).then(e => {
                    let size = categories.length - 1;
                    if (val >= size) {
                        setSelectedValue(0);
                    }
                });
            }}
        />
        <div style={{ height: 'calc(100% - 6rem)', marginTop: '1rem' }}>
            <div className="editor-text" style={labelStyle}>
                <span>
                    <span>Colors:</span>
                    {
                        !selectedOption.system &&
                        <LinkButton onClick={() => {
                            setManageDialog({ open: true });
                            setColorList([]);
                            setSelection([]);
                        }}
                            style={{ marginLeft: '0.75rem', fontSize: '14px' }} label="Manage Colors" />
                    }
                </span>
                <div className={classes.searchBar}>
                    <SearchBar value={searchKey} onChange={setSearchKey} />
                    <ToolPanelButton title='Grid/List View' onClick={() => {
                        localStorage.setItem('colorView', JSON.stringify({ list: !view.list }))
                        setView({ list: !view.list });
                    }} state={{ pressed: view.list }} style={{
                        width: '38px', height: '30px', display: 'flex', height: '30px', justifyContent: 'center', alignItems: 'center'
                    }}><i className="fa fa-list-ul" style={{ fontSize: '22px' }} ></i></ToolPanelButton>
                </div>
            </div>
            <div className={`scroll-vertical ${classes.grid}`}>
                <ColorGrid multiSelect itemDraggable={false} list={view.list}
                    colorlist={filteredColorList}
                    selectIndex={selection}
                    onSelectionChange={(idx, list) => {
                        setColorList(idx.map(i => list[i]));
                    }}
                />
            </div>
        </div>
        {
            manageDialog.open ?
                <ManageColors data={selectedOption}
                    disableGradient={true}
                    open={true} onClose={() => {
                        setManageDialog({ open: false });
                    }}
                    onSave={(data) => {
                        let updated = { ...selectedOption, colors: data };
                        delete updated.value;
                        delete updated.label;
                        addForegroundColor(dispatch, updated, colorData).then(e => setManageDialog({ open: false }));
                    }}
                /> : ''
        }
    </div>;
}
