import React, { useState } from "react";
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Shapes from './components/Shapes';
import Basic from './components/Basic';


export default function TextWidget({ visible, separationColors, openSepDialog, backColor }) {
    const [tabValue, setTabValue] = useState("basic");
    return <div className="scroll-vertical" style={{ display: visible ? 'block' : 'none', height: '100%' }} >
        <TabContext value={tabValue}>
            <TabList onChange={(event, value) => setTabValue(value)} variant="fullWidth">
                <Tab style={{ color: 'white', minWidth: '45%' }} label="Basic" value="basic" />
                <Tab style={{ color: 'white', minWidth: '55%' }} label="Shapes" value="text-shapes" />
            </TabList>
            <TabPanel value="basic" style={{ padding: '8px', paddingTop: '20px' }}>
                <Basic backColor={backColor} separationColors={separationColors} openSepDialog={openSepDialog} />
            </TabPanel>
            <TabPanel value="text-shapes" style={{ padding: '8px', paddingTop: '20px', height: "calc(100% - 3rem)" }} >
                <Shapes />
            </TabPanel>
        </TabContext>
    </div>;
}
