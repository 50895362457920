import React, { useEffect, useState } from "react";
import MenuButton from "../../../components/Buttons/MenuButton";
import { useFabricCanvasHandler } from "../FabricCanvas.js";

let Designer,
    Ext,
    fabric;

const alignAction = {
    events: {
        'selection:created': function () {
            alignAction.updatePanel();
        },
        'selection:updated': function () {
            alignAction.updatePanel();
        },
        'selection:cleared': function () {
            alignAction.updatePanel();
        }
    },
    createSelectionGroup: function (canvas, objects) {
        // Create a selection group for the objects.
        var selectionGroup = canvas.getLayeredSelection(objects);
        canvas.setActiveObject(selectionGroup);
        return selectionGroup;
    },

    parentShown: function () {
        // Update the panel.
        this.updatePanel();
    },

    updatePanel: function () {
        var canAlign = false,
            canAlignHorzVert = false;

        var canvas = Designer.AppData.getCanvas();
        var activeObject = canvas.getActiveObject();

        canAlign = (activeObject && !activeObject.isLocked && !activeObject.layer/*isEffectivelyLocked*/ &&
            activeObject.get('type') === 'activeSelection') ? true : false;

        canAlignHorzVert = (activeObject && !activeObject.layer/*isEffectivelyLocked*/ &&
            !activeObject.isLocked) ? true : false;

        // Enable/disable the buttons.
        this.updateState({
            alignMiddle: {
                disabled: !canAlign
            },
            alignCenter: {
                disabled: !canAlign
            },
            alignTop: {
                disabled: !canAlign
            },
            alignLeft: {
                disabled: !canAlign
            },
            alignBottom: {
                disabled: !canAlign
            },
            alignRight: {
                disabled: !canAlign
            },
            alignVerticalCenter: {
                disabled: !canAlignHorzVert
            },
            alignHorizontalCenter: {
                disabled: !canAlignHorzVert
            }
        });
    },

    alignHorzVertCenter: function (alongHorz) {
        var canvas = Designer.AppData.getCanvas();

        var objects = [];
        var activeObject = canvas.getActiveObject();

        if (!activeObject || activeObject.isLocked) {
            return;
        }

        if (activeObject.get('type') === 'activeSelection') {
            objects = activeObject.getObjects();
        } else {
            objects = [activeObject];
        }

        canvas.discardActiveObject();

        var canvasPanelController = Ext.canvasController,
            productBounds = canvasPanelController.getProductBounds();

        objects.forEach(function (object) {
            var diff = 0,
                referenceCenter, objectCenter;

            // Get the reference bounds - product or the edit area.
            var referenceBounds = productBounds;
            if (object.layer && object.layer.clipPath) {
                referenceBounds = object.layer.clipPath.getBoundingRect(true);
            }

            var objectBounds = object.getBoundingRect(true);

            // Now position the object.
            if (alongHorz) {
                referenceCenter = referenceBounds.left + referenceBounds.width / 2;
                objectCenter = objectBounds.left + objectBounds.width / 2;

                diff = referenceCenter - objectCenter;

                object.left = object.left + diff;
            } else {
                referenceCenter = referenceBounds.top + referenceBounds.height / 2;
                objectCenter = objectBounds.top + objectBounds.height / 2;

                diff = referenceCenter - objectCenter;

                object.top = object.top + diff;
            }
            object.setCoords();
        });

        if (objects.length === 1) {
            canvas.setActiveObject(objects[0]);
            activeObject = objects[0];
        } else {
            activeObject = this.createSelectionGroup(canvas, objects);
        }

        canvas.renderAll();
        canvas.calcOffset();

        this.notifyModified(canvas, activeObject);
    },

    notifyModified: function (canvas, object) {
        canvas.fire('object:modified', { target: object });
    },

    onAlignMiddleButtonClick: function (button, e, eOpts) {
        var canvas = Designer.AppData.getCanvas();
        var activeObject = canvas.getActiveObject();

        if (activeObject && !activeObject.isLocked && activeObject.get('type') === 'activeSelection') {
            var objectsInGroup = activeObject.getObjects();
            canvas.discardActiveObject();

            // Find the group middle.
            var groupTop,
                groupBottom;
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                if (groupTop === undefined || objectBounds.top < groupTop) {
                    groupTop = objectBounds.top;
                }
                var objectBottom = objectBounds.top + objectBounds.height;
                if (groupBottom === undefined || objectBottom > groupBottom) {
                    groupBottom = objectBottom;
                }
            });

            var groupMiddle = (groupTop + groupBottom) / 2;

            // Move the objects.
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                var dy = groupMiddle - (objectBounds.top + objectBounds.height / 2);
                o.top = o.top + dy;
                o.setCoords();
            });

            activeObject = this.createSelectionGroup(canvas, objectsInGroup);

            canvas.renderAll();
            canvas.calcOffset();

            this.notifyModified(canvas, activeObject);
        }
    },

    onAlignCenterButtonClick: function (button, e, eOpts) {
        var canvas = Designer.AppData.getCanvas();
        var activeObject = canvas.getActiveObject();

        if (activeObject && !activeObject.isLocked && activeObject.get('type') === 'activeSelection') {
            var objectsInGroup = activeObject.getObjects();
            canvas.discardActiveObject();

            // Find the group center.
            var groupLeft,
                groupRight;
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                if (groupLeft === undefined || objectBounds.left < groupLeft) {
                    groupLeft = objectBounds.left;
                }
                var objectRight = objectBounds.left + objectBounds.width;
                if (groupRight === undefined || objectRight > groupRight) {
                    groupRight = objectRight;
                }
            });

            var groupCenter = (groupLeft + groupRight) / 2;

            // Move the objects.
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                var dx = groupCenter - (objectBounds.left + objectBounds.width / 2);
                o.left = o.left + dx;
                o.setCoords();
            });

            activeObject = this.createSelectionGroup(canvas, objectsInGroup);

            canvas.renderAll();
            canvas.calcOffset();

            this.notifyModified(canvas, activeObject);
        }
    },

    onAlignTopButtonClick: function (button, e, eOpts) {
        var canvas = Designer.AppData.getCanvas();
        var activeObject = canvas.getActiveObject();

        if (activeObject && !activeObject.isLocked && activeObject.get('type') === 'activeSelection') {
            var objectsInGroup = activeObject.getObjects();
            canvas.discardActiveObject();

            // Find the group top.
            var groupTop;
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                if (groupTop === undefined || objectBounds.top < groupTop) {
                    groupTop = objectBounds.top;
                }
            });

            // Move the objects.
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                var dy = groupTop - objectBounds.top;
                o.top = o.top + dy;
                o.setCoords();
            });

            activeObject = this.createSelectionGroup(canvas, objectsInGroup);

            canvas.renderAll();
            canvas.calcOffset();

            this.notifyModified(canvas, activeObject);
        }
    },

    onAlignLeftButtonClick: function (button, e, eOpts) {
        var canvas = Designer.AppData.getCanvas();
        var activeObject = canvas.getActiveObject();

        if (activeObject && !activeObject.isLocked && activeObject.get('type') === 'activeSelection') {
            var objectsInGroup = activeObject.getObjects();
            canvas.discardActiveObject();

            // Find the group left.
            var groupLeft;
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                if (groupLeft === undefined || objectBounds.left < groupLeft) {
                    groupLeft = objectBounds.left;
                }
            });

            // Move the objects.
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                var dx = groupLeft - objectBounds.left;
                o.left = o.left + dx;
                o.setCoords();
            });

            activeObject = this.createSelectionGroup(canvas, objectsInGroup);

            canvas.renderAll();
            canvas.calcOffset();

            this.notifyModified(canvas, activeObject);
        }
    },

    onAlignBottomButtonClick: function (button, e, eOpts) {
        var canvas = Designer.AppData.getCanvas();
        var activeObject = canvas.getActiveObject();

        if (activeObject && !activeObject.isLocked && activeObject.get('type') === 'activeSelection') {
            var objectsInGroup = activeObject.getObjects();
            canvas.discardActiveObject();

            // Find the group bottom.
            var groupBottom;
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                var objectBottom = objectBounds.top + objectBounds.height;
                if (groupBottom === undefined || objectBottom > groupBottom) {
                    groupBottom = objectBottom;
                }
            });

            // Move the objects.
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                var dy = groupBottom - (objectBounds.top + objectBounds.height);
                o.top = o.top + dy;
                o.setCoords();
            });

            activeObject = this.createSelectionGroup(canvas, objectsInGroup);

            canvas.renderAll();
            canvas.calcOffset();

            this.notifyModified(canvas, activeObject);
        }
    },

    onAlignRightButtonClick: function (button, e, eOpts) {
        var canvas = Designer.AppData.getCanvas();
        var activeObject = canvas.getActiveObject();

        if (activeObject && !activeObject.isLocked && activeObject.get('type') === 'activeSelection') {
            var objectsInGroup = activeObject.getObjects();
            canvas.discardActiveObject();

            // Find the group right.
            var groupRight;
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                var objectRight = objectBounds.left + objectBounds.width;
                if (groupRight === undefined || objectRight > groupRight) {
                    groupRight = objectRight;
                }
            });

            // Move the objects.
            objectsInGroup.forEach(function (o) {
                var objectBounds = o.getBoundingRect(true);
                var dx = groupRight - (objectBounds.left + objectBounds.width);
                o.left = o.left + dx;
                o.setCoords();
            });

            activeObject = this.createSelectionGroup(canvas, objectsInGroup);

            canvas.renderAll();
            canvas.calcOffset();

            this.notifyModified(canvas, activeObject);
        }
    },

    onAlignVerticalCenterButtonClick: function (button, e, eOpts) {
        this.alignHorzVertCenter(false);
    },

    onAlignHorizontalCenterButtonClick: function (button, e, eOpts) {
        this.alignHorzVertCenter(true);
    },
    init: function (stateInterface) {
        this.updateState = stateInterface.updateState;
    }

};
export default function AlignPanel() {
    const [canvas] = useFabricCanvasHandler(alignAction.events);
    const [alignTop, setAlignTopState] = useState({});
    const [alignLeft, setAlignLeftState] = useState({});
    const [alignMiddle, setAlignMiddleState] = useState({});
    const [alignCenter, setAlignCenterState] = useState({});
    const [alignBottom, setAlignBottomState] = useState({});
    const [alignRight, setAlignRightState] = useState({});
    const [alignVerticalCenter, setAlignVerticalCenterState] = useState({});
    const [alignHorizontalCenter, setAlignHorizontalCenterState] = useState({});

    useEffect(() => {
        Designer = window.global.Designer;
        Ext = window.global.Ext;
        fabric = window.fabric;
        alignAction.init({
            updateState: function (obj) {
                if (obj.alignTop)
                    setAlignTopState(obj.alignTop);
                if (obj.alignLeft)
                    setAlignLeftState(obj.alignLeft);
                if (obj.alignMiddle)
                    setAlignMiddleState(obj.alignMiddle);
                if (obj.alignCenter)
                    setAlignCenterState(obj.alignCenter);
                if (obj.alignBottom)
                    setAlignBottomState(obj.alignBottom);
                if (obj.alignRight)
                    setAlignRightState(obj.alignRight);
                if (obj.alignVerticalCenter)
                    setAlignVerticalCenterState(obj.alignVerticalCenter);
                if (obj.alignHorizontalCenter)
                    setAlignHorizontalCenterState(obj.alignHorizontalCenter);
            }
        });
    }, []);

    useEffect(() => {
        canvas && alignAction.updatePanel();
    }, [canvas])

    return (
        <table>
            <tbody>
                <tr>
                    <td>
                        <MenuButton state={alignTop}
                            onClick={() => { alignAction.onAlignTopButtonClick(); }}
                            className="icomoon icon-align-top" title="Top" />
                    </td>
                    <td>
                        <MenuButton state={alignLeft}
                            onClick={() => { alignAction.onAlignLeftButtonClick(); }}
                            className="icomoon icon-align-left" title="Left" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <MenuButton state={alignMiddle}
                            onClick={() => { alignAction.onAlignMiddleButtonClick(); }}
                            className="icomoon icon-align-vertical-center" title="Middle" />
                    </td>
                    <td>
                        <MenuButton state={alignCenter}
                            onClick={() => { alignAction.onAlignCenterButtonClick(); }}
                            className="icomoon icon-align-horizontal-center" title="Center" />
                    </td>
                </tr>

                <tr>
                    <td>
                        <MenuButton state={alignBottom}
                            onClick={() => { alignAction.onAlignBottomButtonClick(); }}
                            className="icomoon icon-align-bottom" title="Bottom" />
                    </td>
                    <td>
                        <MenuButton state={alignRight}
                            onClick={() => { alignAction.onAlignRightButtonClick(); }}
                            className="icomoon icon-align-right" title="Right" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <MenuButton state={alignVerticalCenter}
                            onClick={() => { alignAction.onAlignVerticalCenterButtonClick(); }}
                            className="icomoon5 icon-align-icon-vertical font-28" title="Artboard Middle" />
                    </td>
                    <td>
                        <MenuButton state={alignHorizontalCenter}
                            onClick={() => { alignAction.onAlignHorizontalCenterButtonClick(); }}
                            className="icomoon5 icon-align-icon-horizontal font-28" title="Artboard Center" />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}