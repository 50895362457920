import React from 'react'
import Utils from '../../Utils';
import classes from './Chat.module.css';
export default function Chat(props) {

    const data = {
        msg: props.data.text,
        time: props.data.time,
        user: props.data.user
    }
    const currentUserID = Utils.getUserId();
    let myClass = (data.user === currentUserID || data.user.userId === currentUserID) ? 'chat-box-right' : 'chat-box-left';
    return (
        <div className={classes[myClass]}>
            <div className={classes['message-box']}>
                <div className={classes['information-user']}>
                    <span> {Utils.getFullNameByUser(data.user)} </span>
                </div>
                <div className={classes['text']}>
                    <p>
                        {data.msg}
                    </p>
                </div>
                <div className={classes['information-date-time']}>
                    <span>  {Utils.getLocalDate(data.time)} </span>
                </div>
            </div>
        </div>
    )
}
