import React, { useEffect, useState } from "react";
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FilePicker } from "../Picker.js";
import Utils from "../../../Utils";

function getClasses(obj, index, data, selectIndex) {
	let cl = ["manage-art-item"];
	if (selectIndex.some(i => i === index))
		cl.push("x-item-selected");
	return cl.join(' ');
}

const metaKeyPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
function ArtGrid({ data, needBackground, multiSelect, onClick, selection, field }) {
	const [selectIndex, setSelectIndex] = useState([]);

	let platform = window.navigator.platform,
		selectKey = metaKeyPlatforms.indexOf(platform) !== -1 ? 'metaKey' : 'ctrlKey'

	let style = { position: 'relative' };
	if (!needBackground) {
		style.background = "none";
	}
	if (!data)
		data = [];

	useEffect(() => {
		setSelectIndex(selection);
	}, [selection]);

	return data.map((clip, index) => (
		<div className={getClasses(clip, index, data, selectIndex)} style={style}>
			<div className="clipart-item-thumb-wrap" onClick={(e) => {
				let selection = [index];
				if (multiSelect && e[selectKey]) {
					selection = selection.concat(selectIndex);
				}
				onClick(clip, selection);
				setSelectIndex(selection);
			}}>
				<img className="lozad" src={clip[field]} alt="Clipart"></img>
			</div>
		</div>
	)
	);
}

export default function ManageArts({ open, data, onClose, validate, onSave, title, accept, field }) {
	const [items, setItems] = useState([]);
	const [selectIndex, setSelectIndex] = useState([]);
	useEffect(() => {
		setItems(data.items);
	}, [open, data]);

	field = field || 'filePath';
	return (
		<Dialog
			open={open} aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle>
				<span>{title || "Manage"}</span>
			</DialogTitle>
			<DialogContent>
				<div style={{ fontSize: '1.35rem', padding: 10, paddingTop: 0, textAlign: 'right', color: '#c0c0c0' }}>
					<FilePicker accept={accept} multiple validate={validate} onChange={files => {
						let newItems = [],
							proms = [];
						for (let i = 0; i < files.length; ++i) {
							let f = files[i];
							proms.push(
								Utils.getBase64(f).then((url) => {
									newItems.push({
										[field]: url,
										file: f,
										name: f.name
									});
								})
							);
						}
						Promise.all(proms).then(() => {
							if (newItems.length)
								setItems(items.concat(newItems));
						})
					}} />
					<span style={{ width: '2rem', marginLeft: 10 }} className={`fa fa-trash${selectIndex.length ? '' : ' x-item-disabled'}`} onClick={() => {
						if (selectIndex.length) {
							setItems(items.filter((val, index) => !selectIndex.some(i => i === index)));
							setSelectIndex([]);
						}
					}} />
				</div>
				<div style={{ height: '16rem', width: '29rem', marginBottom: '2rem' }} className="scroll-vertical">
					<ArtGrid data={items} needBackground={true} multiSelect={true} selection={selectIndex} field={field}
						onClick={(c, selection) => {
							setSelectIndex(selection);
						}}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<PrimaryButton onClick={onClose} negative={true}>Cancel</PrimaryButton>
				<PrimaryButton onClick={() => {
					if (onSave) {
						let newFiles = [],
							retained = [];
						items.forEach((item) => {
							if (item.file) {
								newFiles.push(item.file);
							} else {
								retained.push(item);
							}
						});
						onSave(data, newFiles, data.items.filter(i => !retained.some(x => x === i)));
					}
				}}>Save</PrimaryButton>
			</DialogActions>
		</Dialog>
	);
}