import axios from '../../../axios';
import { projectsActions } from "./ProjectsSlice";
import Utils from '../../Utils';
import Consts from '../../Consts';

export function getAllProjects(dispatch) {
  return axios({
    url: '/sepstudio/projects?relativePaths',
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        dispatch(projectsActions.fetchAllProjects({ projects: data.projects || [] }));
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function getProject(dispatch, projectId, history) {
  return axios({
    url: `/sepstudio/projects?projectId=${projectId}&relativePaths`,
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        if (data.projects.length) {
          dispatch(projectsActions.fetchProject({ project: data.projects[0] }));
        } else {
          history.replace('/sepstudio-ui')
        }
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function closeProject(dispatch) {
  dispatch(projectsActions.fetchProject({}));
}

export function updateProject(designJson, designSvg, designThumb, project) {

  const formData = new FormData();
  formData.append("projectData", JSON.stringify(project));
  formData.append("designJson", new Blob([JSON.stringify(designJson)]), "design.json");
  formData.append("designSvg", new Blob([designSvg]), "designSvg.svg");
  formData.append("designThumb", Utils.dataURItoBlob(designThumb), "designThumb.png");
  return axios({
    url: `/sepstudio/projects`,
    method: "put",
    headers: {
      'content-type': 'multipart/form-data'
    },
    data: formData,
    reponseType: "json",
  });
}


export function sendForReview(dispatch, projectId, reviewers) {
  return axios({
    url: `/sepstudio/projects/sendForReview`,
    method: "put",
    data: {
      id: projectId,
      reviewers: reviewers
    },
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        dispatch(projectsActions.sendForReview({ projectId: projectId, reviewers: reviewers }));
      }
      return data;
    });
}