import React from 'react'
import classes from "./ToggleIcon.module.css";

const ToggleIcon = ({ enabled, onClick, image, style, colorNormal = 'grey', colorDown = 'green', tooltip }) => {
    return (
        <div className={classes.mainContainer}>
            <span className={image} onClick={onClick} style={{ ...style, color: enabled ? colorDown : colorNormal }} title={enabled ? tooltip?.down : tooltip?.normal} />
        </div>
    )
}

export default ToggleIcon;