import axios from '../../../axios';
import Consts from '../../Consts';
import { foregroundColorsActions, patternsActions, backgroundColorsActions, fontsActions, clipartActions } from './EditorSlice';

const baseUrl = '/sepstudio';
export function fetchColorData(dispatch, json, action) {
    let version = Consts.APP_VERSION;
    let obj = { library: [], libraryItems: [] };
    return Promise.all([
        axios({
            url: `../resources/colors/${json}?${version}`,
            method: "get",
            responseType: "json"
        }),
        axios({
            url: `${baseUrl}/user-colors/${json}`,
            method: "get",
            responseType: "json"
        })
    ]).then(function (responses) {
        obj.library.push(...responses[0].data.library.map(i => {
            i.system = true;
            return i;
        }));
        obj.library.push(...responses[1].data.library);
        Promise.all(
            obj.library.map(lib => {
                return axios({
                    url: lib.system ? `../resources/colors/${lib.json}?${version}` : `${baseUrl}/user-colors/${lib.json}`,
                    method: "get",
                    responseType: "json"
                }).then(res => {
                    res.data.system = lib.system;
                    return res.data;
                })
            })
        ).then(libraryItems => {
            obj.libraryItems = libraryItems;
            dispatch(action.fetchColors(obj));
        })
    });
}
export function fetchForegroundColorData(dispatch) {
    return fetchColorData(dispatch, "colorlist.json", foregroundColorsActions);
}
export function fetchBackgroundColorData(dispatch) {
    return fetchColorData(dispatch, "backgroundcolorlist.json", backgroundColorsActions);
}

function addColorLibrary(dispatch, name, data, type, json, action) {
    let libs = data.library.slice();
    libs.push({
        json: `${type}/${name}.json`
    });
    return axios({
        url: `${baseUrl}/user-colors/${json}`,
        method: 'POST',
        data: {
            library: libs.filter(i => !i.system)
        }
    }).then(res => {
        axios({
            url: `${baseUrl}/user-colors/${type}/${name}.json`,
            method: 'POST',
            data: {
                name: name,
                colors: []
            }
        }).then(e =>
            dispatch(
                action.fetchColors({
                    library: libs,
                    libraryItems: data.libraryItems.concat([{
                        name: name,
                        colors: []
                    }])
                })
            )
        )
    });
}

export function addBackgroundColorLibrary(dispatch, name, data) {
    return addColorLibrary(dispatch, name, data, "background", "backgroundcolorlist.json", backgroundColorsActions);
}
export function addForegroundColorLibrary(dispatch, name, data) {
    return addColorLibrary(dispatch, name, data, "foreground", "colorlist.json", foregroundColorsActions);
}

function addColor(dispatch, target, data, type, action) {
    return axios({
        url: `${baseUrl}/user-colors/${type}/${target.name}.json`,
        method: 'POST',
        data: target
    }).then(e => {
        dispatch(action.fetchColors({
            library: data.library,
            libraryItems: data.libraryItems.reduce((prev, item) => {
                prev.push(item.name === target.name ? target : item);
                return prev;
            }, [])
        }));
    });
}

export function addBackgroundColor(dispatch, target, data) {
    return addColor(dispatch, target, data, "background", backgroundColorsActions);
}
export function addForegroundColor(dispatch, target, data) {
    return addColor(dispatch, target, data, "foreground", foregroundColorsActions);
}

function removeColorLibrary(dispatch, target, data, type, json, action) {
    const name = `${type}/${target.name}.json`;
    const libs = data.library.filter(i => i.json !== name);
    return axios({
        url: `${baseUrl}/user-colors/${json}`,
        method: 'POST',
        data: {
            library: libs.filter(i => !i.system)
        }
    }).then(e => {
        axios({
            url: `${baseUrl}/user-colors/${name}`,
            method: 'DELETE'
        });
        dispatch(action.fetchColors({
            library: libs,
            libraryItems: data.libraryItems.filter(i => i.name !== target.name)
        }));
    });
}


export function removeBackgroundColorLibrary(dispatch, target, data) {
    return removeColorLibrary(dispatch, target, data, "background", "backgroundcolorlist.json", backgroundColorsActions);
}
export function removeForegroundColorLibrary(dispatch, target, data) {
    return removeColorLibrary(dispatch, target, data, "foreground", "colorlist.json", foregroundColorsActions);
}

export function fetchPatternData(dispatch) {
    let obj = { libraries: [] };
    return Promise.all([
        axios({
            url: `../resources/patterns/patternlist.json?${Consts.APP_VERSION}`,
            method: "get",
            responseType: "json"
        }),
        axios({
            url: `${baseUrl}/pattern`,
            method: "get",
            responseType: "json"
        })
    ]).then(function (responses) {
        obj.libraries.push(...responses[0].data.libraries.map(i => {
            i.system = true;
            return i;
        }));
        obj.libraries.push(...responses[1].data.libraries);
        dispatch(patternsActions.fetchPatterns(obj));
    });
}

export function fetchClipartData(dispatch) {
    let obj = { libraries: [] };
    return axios({
        url: `../resources/cliparts/clipartlist.json?${Consts.APP_VERSION}`,
        method: "get",
        responseType: "json"
    }).then(function (response) {
        obj.libraries.push(...response.data.libraries.map(i => {
            i.system = true;
            return i;
        }));
        dispatch(clipartActions.fetchCliparts(obj));
    });
}

function addPattern(currentCategory, files) {
    const formData = new FormData();
    formData.append("library", currentCategory.name);
    files && files.forEach((file, i) => {
        formData.append("file-" + i, file, i + ".png");
    })
    return axios({
        url: `${baseUrl}/pattern`,
        method: 'POST',
        data: formData,
        responseType: 'json'
    });
}

function removePattern(currentCategory, items) {
    return axios({
        url: `${baseUrl}/pattern`,
        method: 'DELETE',
        data: {
            type: 'libraryItems',
            names: items.map(i => i.filePath.substring(i.filePath.lastIndexOf('/') + 1)),
            library: currentCategory.name
        },
        responseType: 'json'
    });
}

export function updatePattern(dispatch, data, files, toDelete, patternData) {
    const proms = [];
    let item,
        libraries = patternData?.libraries.reduce(function (prev, i) {
            if (i.name === data.name) {
                item = { ...i };
                prev.push(item);
            } else {
                prev.push(i);
            }
            return prev;
        }, []) || [];
    if (files.length) {
        proms.push(addPattern(data, files).then(res => {
            res = res.data;
            if (item && res?.success) {
                item.items = [...res.items, ...item.items];
            }
        }));
    }
    if (toDelete.length) {
        proms.push(removePattern(data, toDelete).then(res => {
            res = res.data;
            if (item && res?.success) {
                item.items = item.items.filter(val => !toDelete.some(i => i === val))
            }
        }));
    }
    return Promise.all(proms).then(res => {
        dispatch(patternsActions.fetchPatterns({ libraries }));
    });
}

export function addPatternLibrary(dispatch, name, data) {
    let formData = new FormData();
    formData.append("library", name);
    return axios({
        url: `${baseUrl}/pattern`,
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': null
        }
    }).then(e => {
        dispatch(patternsActions.fetchPatterns({
            libraries: data.libraries?.concat([{
                items: [],
                name: name,
                defaultSizeCanvas: 1
            }]) || []
        }));
    });
}

export function removePatternLibrary(dispatch, target, data) {
    return axios({
        url: `${baseUrl}/pattern`,
        method: 'DELETE',
        data: {
            type: 'entireLibrary',
            library: target.name
        },
        headers: {
            'Content-Type': null
        }
    }).then(e => {
        dispatch(patternsActions.fetchPatterns({
            libraries: data.libraries?.filter(i => i.name !== target.name) || []
        }));
    });
}
export function fetchFonts(dispatch) {
    return axios({
        url: `../resources/fonts/fontlist.json?${Consts.APP_VERSION}`,
        method: 'GET'
    }).then(e => {
        let data = e.data;
        data.libraries.push({
            name: "All",
            fonts: data.libraries.reduce((prev, curr) => prev.concat(curr.fonts), []).sort((a, b) => a.familyName.localeCompare(b.familyName))
        })
        dispatch(fontsActions.fetchFonts(data));
    });
}