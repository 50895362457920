import React, { useState } from "react";
import classes from './Password.module.css';
import eyeOutline from "../../assets/eye-outline.svg";
import eyeOffOutline from "../../assets/eye-off-outline.svg";

function Password(props) {
    const [passwordType, setPasswordType] = useState("password");

    const myStyle = {
        "position": "absolute",
        "right": "0.5rem",
        "cursor": "pointer",
        "width": "1rem",
        "height": "1rem"
    };

    function toggleHandler() {
        if (passwordType === "password") {
            setPasswordType("text");
        }
        else {
            setPasswordType("password");
        }
    }

    return (<div className={classes.main}>
        <input
            id={props.id}
            autoComplete={props.autoComplete}
            type={passwordType}
            placeholder={props.placeholder}
            className={classes["form-field"]}
            onChange={props.onChange}
            autoFocus={props.autoFocus}
            style={{ "width": props.width, "height": props.height }}
            onKeyDown={(e) => e.key === 'Enter' && props.onCommit?.()}
        />
        {props.togglePasswordVisibility && (
            passwordType === "text" ? <img src={eyeOutline} alt="Eye-outline" onClick={toggleHandler} style={myStyle} /> : <img src={eyeOffOutline} alt="Eye-Off-outline" onClick={toggleHandler} style={myStyle} />)
        }
    </div>)
}

export default Password;