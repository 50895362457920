import React, { useState } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import Input from '../Input/Input';
import ModalDialog from '../Dialog/Dialog';
import classes from './Verification.module.css';
import LinkButton from '../Buttons/LinkButton';
import NeedHelp from '../NeedHelp/NeedHelp';

function Verification(props) {
    const [verificationCode, setVerificationCode] = useState("");

    function verificationHandler() {
        props.onVerify(verificationCode);
    }

    function resendClickHandler() {
        setVerificationCode("");
        props.onResend();
    }

    return <ModalDialog
        title={props.title}
        width={props.width}
        content={
            <>
                <div className={classes.message} >
                    <p dangerouslySetInnerHTML={{ __html: props.message }}></p>
                </div>
                <div className={classes.inputBox}>
                    <Input type="text"
                        id="otp"
                        onChange={(e) => setVerificationCode(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && verificationHandler()}
                        value={verificationCode} />
                    <LinkButton onClick={resendClickHandler} label="Resend Code" className={classes.resend} />
                </div>
                {props.isCodeResent && <p>Code Resent</p>}
                {!props.isCodeResent && props.showVerifyError && <p style={{ color: "red" }}>Verification failed!</p>}
            </>
        }
        footer={
            <>
                <div className={classes.needHelp}>
                    <NeedHelp />
                </div>
                <PrimaryButton onClick={props.onCancel} negative>Cancel</PrimaryButton>
                <PrimaryButton onClick={verificationHandler} positive>
                    Verify
                </PrimaryButton>
            </>
        }
    />
}

export default Verification;