import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classes from './Dialog.module.css';

function ModalDialog({ title, onClose, content, footer, width, height, maxWidth, contentClassName, className }) {
    let style;
    if (width || height) {
        style = { width, height };
    }
    return <Dialog maxWidth={maxWidth}
        className={className}
        open={true} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
            <span>{title}</span>
            {
                onClose &&
                <IconButton style={{ float: 'right', padding: 0 }}
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
        </DialogTitle>
        <DialogContent className={contentClassName}><div style={style} className={classes.content}>{content}</div></DialogContent>
        <DialogActions>{footer}</DialogActions>
    </Dialog>
}

export default ModalDialog;