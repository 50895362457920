import React from 'react';
import { useSelector } from 'react-redux';
import AnnotationData from './AnnotationData';
import ChatRoom from '../ChatBox/ChatRoom';
import classes from "./Annotations.module.css";
import chatIcon from "../../assets/chat-icon.svg";
import TextBoxEditor from '../../components/TextBoxEditor/TextBoxEditor';

function Annotations(props) {

    const activeChatId = props.activeChatId;
    const annotations = useSelector((state) => state.annotations.annotations || []);
    const sortedAnnotations = [...annotations];
    sortedAnnotations.sort((a, b) => {
        const commentsA = a.comments;
        let timeA = (commentsA[commentsA.length - 1].time || a.time);
        timeA = String(timeA?.['$date'] || timeA);
        const commentsB = b.comments;
        let timeB = (commentsB[commentsB.length - 1].time || b.time);
        timeB = String(timeB?.['$date'] || timeB);
        return timeB.localeCompare(timeA);
    })

    function getAnnotation() {
        const annotationIndex = annotations.findIndex((annotation) => annotation.id === activeChatId);
        if (annotationIndex >= 0 && annotationIndex < annotations.length) {
            return annotations[annotationIndex];
        }
        return [];
    }

    function getIndex() {
        const annotationIndex = annotations.findIndex((annotation) => annotation.id === activeChatId);
        if (annotationIndex >= 0 && annotationIndex < annotations.length) {
            return annotationIndex;
        }
        return -1;
    }

    return (
        <div className={`${classes.main} scroll-vertical`}>
            {props.showChat ?
                <div className={classes.chat}>
                    <ChatRoom annotation={getAnnotation()} index={getIndex()} onBack={props.closeChatHandler} activeChatId={activeChatId}
                        addComment={props.addComment} isReadOnly={props.isReadOnly}
                    />
                </div>
                :
                <div className={classes.annotationsWrapper}>
                    {
                        !props.isReadOnly &&
                        <div className={classes.textBoxEditor}>
                            <TextBoxEditor onSave={props.addAnnotation}
                                onCancel={props.onCancel}
                                addPinHandler={props.addPinHandler}
                                activeChatId={activeChatId}
                                pinObject={props.pinObject}
                            />
                        </div>
                    }
                    <div className={annotations.length ? classes.mainWithComment : classes.mainWithNoComment} style={{ marginTop: props.isReadOnly ? '0' : '1rem' }}>
                        {annotations.length === 0 ?
                            <div className={classes.noComment}>
                                <img src={chatIcon} width={100} alt="ChatIcon" />
                                <p style={{ fontWeight: 500 }}>No Comments-Yet</p>
                                <p style={{ textAlign: "center", marginTop: "0.5rem" }}>Give feedback, ask questions, or start discussion in the comments</p>
                            </div> :
                            <div className={classes.comment}>
                                {sortedAnnotations.map((annotation, index) => {
                                    return <AnnotationData key={annotation.id}
                                        activeChatId={activeChatId}
                                        id={annotation.id}
                                        index={index}
                                        annotation={annotation}
                                        onClick={() => props.changeActiveChat(annotation.id)}
                                        pinNumber={annotation.pinNumber}
                                        addPinHandler={props.addPinHandler}
                                        pinObject={props.pinObject}
                                        isReadOnly={props.isReadOnly}
                                    />
                                })
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default Annotations;