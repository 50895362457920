import React from 'react';
import Projects from '../Projects/Projects';
import classes from './Home.module.css';

function Home() {
    return (
        <div className={classes.main}>
            <Projects />
        </div>
    )
}

export default Home;