import React, { useEffect, useRef } from 'react';
import { useContainerDimensions } from '../../custom-hooks/ContainerDimensions';
import NumberIcon from '../../components/NumberIcon/NumberIcon';
import classes from "./ImageViewer.module.css";
import Utils from '../../Utils';

function ImageViewer(props) {
    const prevRef = useRef();
    const overlayRef = useRef();
    const { width, height } = useContainerDimensions(prevRef);

    const size = props?.project?.files[0]?.size;
    const imgWidth = size.widthMM || size.width || 700;
    const imgHeight = size.heightMM || size.height || 750;
    let newLocation = props.pinObject.newThread.location;

    let scaling = 1.0;
    const rx = width / imgWidth;
    const ry = height / imgHeight;
    scaling = rx < ry ? rx : ry;
    if (scaling === 0) {
        scaling = 1.0;
    }
    let tx = (width - imgWidth * scaling) / 2;
    let ty = (height - imgHeight * scaling) / 2;

    function getPinLocation(e) {
        let bounds = prevRef.current.getBoundingClientRect();
        let x = Math.abs(e.pageX - bounds.left - tx - window.scrollX);
        let y = Math.abs(e.pageY - bounds.top - ty - window.scrollY);
        x = x / scaling;
        y = y / scaling;
        return { x, y };
    }

    function previewClickHandler(e) {
        let id = e.dataTransfer?.getData("id");
        const { x, y } = getPinLocation(e);
        props.setLocation({ x, y, id });
    }

    function previewDropHandler(e) {
        let id = e.dataTransfer?.getData("id");
        let droppable = e.dataTransfer?.getData("droppable");
        if (id || droppable) {
            const { x, y } = getPinLocation(e);
            props.setLocation({ x, y, id });
        }
    }

    useEffect(() => {
        function checkIfClickedOutside(e) {
            if ((props.pinObject.newThread.isPinned === true || props.pinObject.existingThread.isPinned === true)
                && overlayRef.current && !overlayRef.current.contains(e.target)) {
                props.onCancel();
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }

    }, [props]);

    let project = props.project;
    return <div className={classes.imagePreview} ref={prevRef} >
        <img
            style={{
                left: tx,
                top: ty,
                width: imgWidth * scaling,
                height: imgHeight * scaling,
                position: 'absolute',
                backgroundColor: props.project?.files[0]?.backgroundColor || 'black'
            }}
            onDrop={previewDropHandler} onDragOver={e => e.preventDefault()}
            draggable={false}
            src={project.designThumbPath}
            alt="PreviewThumb"
        />
        {(props.pinObject.newThread.isPinned === true || props.pinObject.existingThread.isPinned === true) &&
            <div className={classes.overlay}
                style={{
                    left: tx,
                    top: ty,
                    width: imgWidth * scaling,
                    height: imgHeight * scaling
                }}
                onClick={previewClickHandler}
                ref={overlayRef}
            >
                <span>{'Click on Image to place the pin'}</span>
            </div>
        }
        {props.annotations && props.annotations.map(annotation => {
            if (annotation.location && annotation.location.x && annotation.location.y) {
                let isEnabled = Utils.isCurrentUser(annotation.user) && !annotation.closed && !props.isReadOnly;
                return <div key={annotation.id}
                    draggable={isEnabled}
                    className={isEnabled ? classes.draggable : classes.disabled}
                    onDragStart={e => e.dataTransfer.setData("id", annotation.id)}
                >
                    <NumberIcon number={annotation.pinNumber + 1}
                        style={{
                            position: "absolute",
                            left: annotation.location.x * scaling + tx,
                            top: annotation.location.y * scaling + ty,
                            zIndex: 100
                        }}
                        fill={annotation.closed ? "green" : undefined}
                        translate={true}
                        onClick={() => props.changeActiveCommentId(annotation.id)}
                    />
                </div>
            }
            return null;
        })
        }
        {Object.keys(newLocation).length > 0 &&
            <div draggable className={classes.draggable} >
                <NumberIcon number={(props.pinnedComments || 0) + 1}
                    style={{
                        position: "absolute",
                        left: newLocation.x * scaling + tx,
                        top: newLocation.y * scaling + ty,
                        zIndex: 100
                    }}
                    translate={true}
                />
            </div>
        }
    </div>;
}

export default ImageViewer;