import React, { useState, useEffect } from "react";
import { useFabricCanvasHandler } from "../../../FabricCanvas";
import TextShapes from './TextShapes';
import { Slider } from "../../../../../components/Sliders/Sliders";
import ToolPanelButton from "../../../../../components/Buttons/ToolPanelButton";
import action from "./utils";
import SliderButton from "../../../../../components/Buttons/SliderButton";

export default function Shapes() {
    const [type, setType] = useState('empty');
    const [radius, setRadius] = useState('empty');
    const [placement, setPlacement] = useState('empty');
    const [warpAmount, setWarpAmount] = useState('empty');
    const [warpMode, setWarpMode] = useState('empty');

    function updatePanel() {
        let textObjects = action.getSelectedTextObjects();
        setType(action.getTextAttribute('type', textObjects));
        setRadius(action.getTextAttribute('radius', textObjects));
        setPlacement(action.getTextAttribute('placement', textObjects));
        setWarpAmount(action.getTextAttribute('warpAmount', textObjects) || 5);
        setWarpMode(action.getTextAttribute('warpMode', textObjects) || 0);
    }

    const [canvas] = useFabricCanvasHandler({
        'selection:created': updatePanel,
        'selection:updated': updatePanel,
        'selection:cleared': updatePanel
    });

    useEffect(() => {
        canvas && updatePanel();
    }, [canvas])

    const isCurved = type === 'curved-text';
    
    return <>
        <div className={['empty', 'mixed'].some(i => i === type) ? 'x-item-disabled' : ''} style={{ marginBottom: '1rem' }}>
            <label className="editor-text" style={{ fontSize: "17px" }}>Curved Text</label>
            <SliderButton size="small" checked={isCurved} style={{ marginLeft: '0.5rem' }}
                onChange={val => {
                    action.onTextTypeChange(val, Number(radius) || 30);
                    setType(val ? 'curved-text' : 'shaped-text');
                }}
                offColor="#FFF"
                onColor="#FFF"
                offHandleColor="#0E5FBC"
                onHandleColor="#0E5FBC"
                uncheckedLabel="Off"
                checkedLabel="On"
            />
        </div>
        {
            isCurved ?
                <>
                    <div className={isNaN(radius) ? 'x-item-disabled' : ''}>
                        <label className="editor-text" style={{ fontSize: "17px" }}>Radius</label>
                        <div style={{ margin: '0 10px' }}>
                            <Slider min={25} max={150}
                                value={Number(radius) || 30}
                                onChange={(ev, val) => action.applyTextAttribute('radius', val, true)}
                                onChangeCommitted={(ev, val) => {
                                    action.applyTextAttribute('radius', val);
                                    setRadius(val);
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <label className="editor-text" style={{ fontSize: "17px" }}>Position</label>
                        <div>
                            <ToolPanelButton state={{ pressed: placement === 'outside' }}
                                className="img-curved-text-outside text-view-btn-style2" onClick={e => {
                                    setPlacement('outside');
                                    action.applyTextAttribute('placement', 'outside');
                                }}
                            />
                            <ToolPanelButton state={{ pressed: placement === 'inside' }}
                                className="img-curved-text-inside text-view-btn-style2" onClick={e => {
                                    setPlacement('inside');
                                    action.applyTextAttribute('placement', 'inside');
                                }}
                                style={{ marginLeft: '10px' }}
                            />
                        </div>
                    </div>
                </>
                :
                <>
                    <div className={isNaN(warpMode) ? 'x-item-disabled' : ''}>
                        <label className="editor-text" style={{ fontSize: "17px" }}>Shapes</label>
                        <TextShapes value={warpMode} onChange={val => {
                            setWarpMode(val);
                            action.updateShape(val, warpAmount);
                        }} />
                    </div>
                    <div className={isNaN(warpAmount) ? 'x-item-disabled' : ''}>
                        <label className="editor-text" style={{ fontSize: "17px" }}>Shape Modifier</label>
                        <div style={{ margin: '0 10px' }}>
                            <Slider min={0} max={10}
                                value={isNaN(warpAmount) ? 5 : warpAmount}
                                onChange={(ev, val) => action.updateShape(warpMode, val)}
                                onChangeCommitted={(ev, val) => {
                                    setWarpAmount(val);
                                    action.notifyUndoRedo();
                                }}
                            />
                        </div>
                    </div>
                </>
        }
    </>
}