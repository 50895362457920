import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

const SortableCont = SortableContainer(({ children }) => <tbody>{children}</tbody>);
const DefaultHandler = () => 'Move';
const DefaultComponent = () => 'Component';
const RowHandler = SortableHandle(({ children }) => <td>{children}</td>);
const SortableItem = SortableElement(({ children }) => <tr>{children}</tr>);

function mapReverse(items, cb) {
    let a = [],
        len = items.length - 1;
    for (let i = len; i >= 0; --i)
        a.push(cb && cb(items[i], len - i));
    return a;
}

function ArrangeTable({ data, component, handlerComponent, helperClass, className, style, onSortEnd, skipItem }) {
    data = data || [];
    const [items, setItems] = useState([]);
    let HandlerComponent = handlerComponent || DefaultHandler,
        ExternalComponent = component || DefaultComponent;
    useEffect(() => {
        setItems(data.map((i, index) => {
            return {
                item: i,
                id: index
            }
        }));
    }, [data]);
    return (
        <table className={className} style={style}>
            <SortableCont
                onSortEnd={({ oldIndex, newIndex }) => {
                    if (oldIndex === newIndex) {
                        return;
                    }
                    let oldItems = items.slice(),
                        len = oldItems.length - 1;
                    oldIndex = len - oldIndex;
                    newIndex = len - newIndex;
                    oldItems.splice(
                        newIndex < 0 ? oldItems.length + newIndex : newIndex,
                        0,
                        oldItems.splice(oldIndex, 1)[0]
                    );
                    onSortEnd && onSortEnd(oldItems.map(i => i.item));
                    setItems(oldItems);
                }}
                axis="y"
                lockAxis="y"
                lockToContainerEdges={true}
                lockOffset={["30%", "50%"]}
                helperClass={helperClass}
                useDragHandle={true}
            >
                {mapReverse(items, (item, index) =>
                    skipItem && skipItem(item.item) ? '' :
                        <SortableItem index={index} key={item.id}>
                            <ExternalComponent
                                itemWrapper={item}
                                index={index}
                            />
                            <RowHandler>
                                <HandlerComponent />
                            </RowHandler>
                        </SortableItem>
                )}
            </SortableCont>
        </table>
    );
};

export default ArrangeTable;
