import axios from '../../axios';
import Utils from '../Utils';
import { annotationsActions } from "./AnnotationsSlice";
import { projectsActions } from './Projects/ProjectsSlice';

export function getAnnotations(dispatch, filter) {
  return axios({
    url: `/sepstudio/annotations?filter=${filter}`,
    method: "get",
    responseType: "json",
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      let annotations = data.annotations,
        comments = annotations.reduce((prev, curr) => {
          prev.push(...curr.comments);
          return prev;
        }, []);
      fillAnnotationUsers([...annotations, ...comments]).then(anns => {
        dispatch(annotationsActions.fetchAnnotations({ annotations: annotations || [] }));
      });
    }
  });
}

const userMap = {};
export function fillAnnotationUsers(objs) {
  let users = new Set();
  objs.forEach(ann => {
    if (userMap[ann.user]) {
      ann.user = userMap[ann.user];
    } else {
      users.add(ann.user);
    }
  });
  return new Promise((resolve, reject) => {
    if (users.size) {
      axios({
        url: `/sepstudio/user/users`,
        method: "post",
        data: {
          userId: { '$in': Array.from(users) }
        },
        responseType: "json",
      }).then(function (response) {
        let data = response.data;
        if (data.success) {
          data.users?.forEach(u => userMap[u.userId] = u);
          objs.forEach(ann => {
            let key = ann.user;
            if (typeof key === 'string' && userMap[key])
              ann.user = userMap[key];
          });
          resolve(objs);
        } else {
          reject();
        }
      });
    } else {
      resolve(objs);
    }
  });

}

export function addAnnotation(dispatch, data) {
  return axios({
    url: "/sepstudio/annotations",
    method: "post",
    data: data,
    responseType: "json"
  }).then((response) => {
    if (response.data.success) {
      const userData = Utils.getCurrentUser();
      const annotationData = {
        ...response.data,
        user: userData
      };
      dispatch(annotationsActions.addAnnotation({ data: annotationData }));
    }
    return response;
  }).catch(function (error) {
    return error;
  });
}

export function updateAnnotation(dispatch, data) {
  return axios({
    url: "/sepstudio/annotations/comment",
    method: "put",
    data: data,
    responseType: "json"
  }).then((response) => {
    if (response.data.success) {
      const userData = Utils.getCurrentUser();
      const annotationData = {
        ...data,
        user: userData,
        time: response.data.time
      };
      dispatch(annotationsActions.updateAnnotation({ data: annotationData }));
    }
    return response;
  }).catch(function (error) {
    return error;
  });
}

export function addLocation(dispatch, data) {
  return axios({
    url: "/sepstudio/annotations/location",
    method: "put",
    data: data,
    responseType: "json"
  }).then((response) => {
    if (response.data.success) {
      dispatch(annotationsActions.addLocation({ data: response.data.annotationDoc || data }));
    }
    return response;
  }).catch(function (error) {
    return error;
  });
}

export function updateProjectStatus(dispatch, projectId, status) {
  return axios({
    url: `/sepstudio/projects/updateProject`,
    method: "put",
    data: {
      id: projectId,
      status: status
    },
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        dispatch(projectsActions.updateProjectStatus({ projectId: projectId, status: status }));
      }
      return data;
    });
}

export function setAnnotationId(dispatch, id) {
  dispatch(annotationsActions.setActiveAnnotation({ id }));
}